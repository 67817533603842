<template>
    <v-container class="defined-outputs">
        <v-row>
            <v-col cols="12">
            <v-card class="pa-6">
                <v-row class="my-5">
                <v-col>
                    <h1 class="display-1 text-center text-primary">Defined Outputs</h1>
                </v-col>
                </v-row>
                <v-card-text>
                    <v-row>
                        <v-col class="text-right mb-4">
                            <v-btn color="primary" @click="showOutputForm = true">
                                <svg-icon type="mdi" color="primary" :path="createIcon"></svg-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="output in outputs" :key="output.id" class="text-primary" :title="output.name">
                            <v-expansion-panel-text>
                            <v-row class="mb-3">
                            <v-col cols="12" sm="6">
                                <v-card-text>
                                <v-card-subtitle class="text-h6 text-primary">Description</v-card-subtitle>
                                <v-spacer></v-spacer>
                                <v-card-text>{{ output.description }}</v-card-text>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-card-subtitle class="text-h6 text-primary">Fields</v-card-subtitle>
                                    </v-row>
                                    <v-row class="py-2 font-weight-bold">
                                        <v-col cols="3">Name</v-col>
                                        <v-col cols="3">Type</v-col>
                                        <v-col cols="6">Description</v-col>
                                    </v-row>
                                    <v-row v-for="field in output.fields" :key="field.id" class="py-1">
                                        <v-col cols="3">{{ field.name }}</v-col>
                                        <v-col cols="3">{{ field.type }}</v-col>
                                        <v-col cols="6">{{ field.description }}</v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-end">
                                <v-btn text color="primary" @click.stop="editOutput(output)">
                                    <svg-icon type="mdi" color="primary" :path="editIcon"></svg-icon>
                                    Edit
                                </v-btn>
                                <v-btn text color="primary" @click.stop="deleteOutput(output)">
                                    <svg-icon type="mdi" color="primary" :path="deleteIcon"></svg-icon>
                                    Delete
                                </v-btn>
                            </v-card-actions>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
        <OutputForm v-model="showOutputForm" :model="formModel" @submit="submitOutputForm" @close="closeOutputForm" />
        </v-container>
  </template>
  
  <script>
  import { getAuth } from "firebase/auth";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiPencilBoxOutline, mdiDeleteOutline, mdiPlusBoxOutline } from '@mdi/js';
  import {
    getDocs,
    collection,
    addDoc,
    doc,
    updateDoc,
    deleteDoc,
  } from "firebase/firestore";
  import OutputForm from "@/components/OutputForm.vue";
  
  export default {
    name: "OutputPage",
    components: {
      OutputForm,
      SvgIcon,
    },
    data() {
      return {
        createIcon: mdiPlusBoxOutline,
        editIcon: mdiPencilBoxOutline,
        deleteIcon: mdiDeleteOutline,
        headers: [
          { text: "Name", value: "name" },
          { text: "Description", value: "description" },
          { text: "Fields", value: "fieldsCount" },
          { text: "Actions", value: "actions" },
        ],
        outputs: [],
        search: "",
        pagination: {
          descending: false,
          sortBy: "name",
          rowsPerPage: 10,
        },
        itemsPerPage: 10,
        page: 1,
        loading: false,
        noDataText: "No outputs found",
        showOutputForm: false,
        formModel: {},
      };
    },
    async created() {
        const firestore = this.$db;

        if (this.user) {
            const outputsCollection = collection(firestore, "users", this.user.uid, "outputs");
            const outputsSnapshot = await getDocs(outputsCollection);

            this.outputs = outputsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                fieldsCount: Object.keys(doc.data().fields).length,
            }));

            console.log(this.outputs)
        } else {
            this.$router.push("/");
        }
    },
    computed: {
        user() {
            const auth = getAuth();
            return auth.currentUser;
        },
    },
    methods: {
        async createNewOutput() {
            this.showOutputForm = true;
            this.formModel = {}; // Initialize an empty object for creating a new output
        },
        async editOutput(output) {
            this.formModel = { ...output }; // Clone the output object to edit
            this.showOutputForm = true;
            console.log(this.formModel)
        },
        async submitOutputForm(outputData) {
            const firestore = this.$db;
            console.log(outputData);
            // Check if it's a new output or an existing one
            if (outputData.id) {
                // Update the existing output
                const editedOutput = {
                    ...outputData,
                    fields: outputData.fields.map((field) => {
                        // Remove empty validate object if it exists
                        if (field.validate && Object.keys(field.validate).length === 0) {
                            const { validate, ...otherProps } = field;
                            console.log(validate);
                            return otherProps;
                        }
                        return field;
                    }),
                };
                console.log("Update Output");
                await updateDoc(doc(firestore, "users", this.user.uid, "outputs", outputData.id), editedOutput);
            } else {
                // Create a new output and add it to the outputs subcollection
                const newOutput = {
                    ...outputData,
                    fields: outputData.fields.map((field) => {
                        // Remove empty validate object if it exists
                        if (field.validate && Object.keys(field.validate).length === 0) {
                            const { validate, ...otherProps } = field;
                            console.log(validate);
                            return otherProps;
                        }
                        return field;
                    }),
                };
                console.log("Adding Output");
                console.log(newOutput);
                const docRef = await addDoc(collection(firestore, "users", this.user.uid, "outputs"), newOutput);
                this.outputs = [...this.outputs, { id: docRef.id, ...newOutput }];
            }
            // Close the form dialog
            this.showOutputForm = false;
        },
        async deleteOutput(output) {
            // Delete the selected output from the outputs subcollection
            const firestore = this.$db;
            await deleteDoc(doc(firestore, "users", this.user.uid, "outputs", output.id));
            let outputs_filtered = this.outputs.filter(o => o.id !== output.id);
            this.outputs = outputs_filtered;
        },
        closeOutputForm() {
            // Close the form dialog without saving
            this.formModel = {};
            this.showOutputForm = false;
        },
    },
};
</script>

<style>
.defined-outputs {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    height: 100vh;
  }
</style>

  