<template>
  <v-card class="elevation-12 rounded-xl pa-4 pt-8 pa-md-8">
    <v-form ref="form" v-model="valid" @submit.prevent="createBot">
      <v-row>
        <div class="text-left text-h6 ml-4 mb-2">
          {{ $t("integrationsPage.add-spoke-to-telegram") }}
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="spokesNameOptions"
            variant="solo-filled"
            v-model="selectedSpoke.name"
            :disabled="loadingSpokes"
            :label="$t(`integrationsPage.select-spoke`)"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="botToken"
            label="Telegram Bot Token"
            required
          />
          <div class="text-center">
            <a href="https://core.telegram.org/bots/tutorial" target="_blank">{{
              $t("integrationsPage.how-telegram-bot-token")
            }}</a>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col>
            <v-btn
              type="submit"
              :disabled="
                !valid ||
                savingForm ||
                loadingSpokes ||
                selectedSpoke.name === `None` ||
                !botToken
              "
              :loading="savingForm || loadingSpokes"
              color="button"
              @click="saveAsTemplate = false"
              >{{ $t("integrationsPage.add-bot") }}</v-btn
            >
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <v-snackbar v-model="snackbarVisible" top color="success" timeout="2000">
    {{ $t("integrationsPage.telegram-bot-created") }}
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useIntegrationsStore } from "@/stores/integrations";
import { useUserStore } from "@/stores/user";
import { fetchSpokesFromFirebase } from "@/services/spoke";

export default {
  name: "CreateTelegramBot",
  data() {
    return {
      spokesOptions: [{ name: "None", value: null }],
      spokesNameOptions: ["None"],
      selectedSpoke: { name: "None", value: null },
      selectedSpokeId: "",
      loadingSpokes: false,
      loadingCreateBot: false,
      botToken: "",
      savingForm: false,
      valid: true,
      spokes: [],
      snackbarVisible: false,
    };
  },
  async created() {
    this.loadingSpokes = true;
    await this.fetchSpokes();
  },
  computed: {
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    async fetchSpokes() {
      const userSpokesObject = await fetchSpokesFromFirebase();
      const userSpokesObjectValues = Object.values(userSpokesObject);
      const userSpokesObjectKeys = Object.keys(userSpokesObject);
      userSpokesObjectValues.forEach((spoke, index) => {
        if (!spoke.template) {
          this.spokesOptions.push({
            name: spoke.data.name,
            value: userSpokesObjectKeys[index],
          });
          this.spokesNameOptions.push(spoke.data.name);
        }
      });
      this.spokes = userSpokesObject.filter((spoke) => !spoke.template);
      //this.templates = raw_spokes.filter((spoke) => spoke.template);
      this.loadingSpokes = false;
    },
    async createBot() {
      this.savingForm = true;
      try {
        this.selectedSpokeId = this.spokes.find(
          (s) => s.data.name === this.selectedSpoke.name
        )?.id;
        await this.addTelegramBot({
          spokeId: this.selectedSpokeId,
          botToken: this.botToken,
        });
        this.snackbarVisible = true;
        this.botToken = "";
        this.selectedSpoke = { name: "None", value: null };
      } catch (e) {
        alert("Error adding Spoke to Telegram: ", e);
        console.log("Error adding Spoke to Telegram: ", e);
      }
      this.savingForm = false;
    },
    ...mapActions(useIntegrationsStore, ["addTelegramBot"]),
  },
};
</script>
