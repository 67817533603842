<template>
  <v-container class="px-0 pt-0">
    <v-list>
      <div style="border-bottom: 0.5px solid gray"></div>
      <v-list-item
        class="px-4 py-0 my-0"
        :style="bibliotheca.processing && `opacity:0.6;`"
        style="border-bottom: 0.5px solid gray; min-height: 100px"
        v-for="bibliotheca in this.filteredBibliothecas"
        :key="bibliotheca.id"
      >
        <v-row no-gutters align="center">
          <v-col cols="12" md="6">
            <v-list-item-title
              @click="
                !bibliotheca.processing && goToEditBibliothecas(bibliotheca)
              "
              :class="{ hoverable: !bibliotheca.processing }"
              class="text-primary text-md-left mt-4 ml-md-6 mt-md-0"
            >
              {{
                bibliotheca.name ? bibliotheca.name : bibliotheca.bibliothecaId
              }}
              <IconComponent style="margin-bottom:-6px; margin-left: 6px; opacity: 0.5;" color="purple" :name="bibliotheca.originType.toLocaleLowerCase()" />
            </v-list-item-title>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item-title
              v-if="bibliotheca.processing"
              class="text-md-right pb-2 pa-md-6"
              ><span
                ><v-tooltip activator="parent" location="top"
                  >{{ $t("processing") }}
                </v-tooltip>
                <v-progress-circular
                  :size="20"
                  :width="4"
                  color="purple"
                  indeterminate
                >
                </v-progress-circular>
              </span>
            </v-list-item-title>
            <v-list-item-title v-else class="text-md-right pb-2 pa-md-6">
              <v-btn
                class="ma-1 la-1"
                size="small"
                icon
                color="transparent"
                :disabled="loading || loadingBibliotheca"
                @click="goToEditBibliothecas(bibliotheca)"
              >
                <IconComponent color="purple" name="edit" />
              </v-btn>
              <v-btn
                v-if="bibliotheca.origin !== 'public'"
                size="small"
                icon
                :disabled="loading || loadingBibliotheca"
                color="transparent"
                @click="openDeleteDialog(bibliotheca)"
              >
                <IconComponent color="red" name="delete" />
              </v-btn>
            </v-list-item-title>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-container>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center">{{
        $t("bibliothecas.delete-bibliotheca-confirmation")
      }}</v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="this.deleteDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="button"
            @click="myDeleteBibliotheca(this.selectedForDelete)"
            >{{ $t("delete") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBibliothecasStore } from "@/stores/bibliothecas";
import { useUserStore } from "@/stores/user";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "BibliothecasList",
  components: {
    IconComponent,
  },
  props: {
    bibliothecas: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loadingBibliotheca: false,
      deletedBibliothecas: [],
      deleteDialog: false,
      selectedForDelete: null,
    };
  },
  computed: {
    filteredBibliothecas() {
      return this.bibliothecas.filter(
        (b) => !this.deletedBibliothecas.includes(b.id)
      );
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    openDeleteDialog(template) {
      this.deleteDialog = true;
      this.selectedForDelete = template;
    },
    async myDeleteBibliotheca(bibliotheca) {
      this.deleteDialog = false;
      const bibliothecaId = bibliotheca.id;
      await this.deleteBibliotheca({
        userId: this.userData.userId,
        bibliothecaId: bibliothecaId,
      });
      this.deletedBibliothecas = this.deletedBibliothecas.concat([
        bibliothecaId,
      ]);
      this.selectedForDelete = null;
    },
    async goToEditBibliothecas(bibliotheca) {
      this.$router.push({
        name: "EditBibliotheca",
        params: { bibliothecaId: bibliotheca?.id },
      });
    },
    ...mapActions(useBibliothecasStore, ["deleteBibliotheca"]),
  },
};
</script>

<style scoped>
.configurations-card {
  max-width: 500px;
}
.origin {
  font-size: 12px;
  text-align: left;
}
.hover {
  transition: all 0.3s ease-in-out;
  opacity: 100%;
}
.hover:hover {
  opacity: 50%;
}
</style>
