import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebasePlugin = {
  install: (app, options) => {
    const { firebaseApp, auth } = options;
    const db = getFirestore(firebaseApp);
    const storage = getStorage(firebaseApp);

    app.config.globalProperties.$auth = auth;
    app.config.globalProperties.$db = db;
    app.config.globalProperties.$storage = storage;
  },
};

export default firebasePlugin;
