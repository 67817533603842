import {
  doc,
  getDocs,
  getDoc,
  collection,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import { sendMessage } from "@/services/conversation";
import { sendAction } from "@/services/actions";

export async function saveBibliothecaAction({ bibliothecaData }) {
  const userId = auth.currentUser.uid;
  //console.log("bibliothecaData: ", bibliothecaData)
  const res = await sendAction({
    actionId: "create-bibliotheca",
    args: {
      user: userId,
      author: bibliothecaData.author,
      originUrls: bibliothecaData.originUrls,
      originType: bibliothecaData.originType,
      strategy: bibliothecaData.strategy,
      additionalMetadata: bibliothecaData.additionalMetadata,
      bibliothecaId: bibliothecaData.bibliothecaId,
      name: bibliothecaData.name,
      description: bibliothecaData.description,
      language: bibliothecaData.language,
    },
    user: userId,
  });
  console.log("saved bibliotheca: ", res);
}

export async function deleteBibliotheca({ userId, id }) {
  const configPath = `users/${userId}/bibliothecas/`;
  const docRef = doc(collection(db, configPath), `${id}`);
  await deleteDoc(docRef);
  await sendAction({
    actionId: "drop-bibliotheca",
    args: { user: userId, author: userId, bibliothecaId: id },
    user: userId,
  });
}

export async function deleteFromBibliotheca({ userId, id, document }) {
  const deleteJson = JSON.stringify({ source: { $eq: document } });
  await sendAction({
    actionId: "delete-from-bibliotheca",
    args: {
      user: userId,
      author: userId,
      bibliothecaId: id,
      where: deleteJson,
    },
    user: userId,
  });
}

export async function retrieveBibliotheca({ author, bibliotheca }) {
  let bibliothecaId = bibliotheca;
  const userId = auth.currentUser.uid;
  if (author && bibliothecaId) {
    const message = `##query-bibliotheca user=${userId} author=${author} bibliothecaId=${bibliothecaId}`;
    const result = await sendMessage({
      conversationId: "temp",
      message,
      user: userId,
    });
    console.log("result: ", result);
  } else {
    console.log("Missing parameters. Creating Bibliotheca in cache");
    const configPath = `users/${userId}/bibliothecas/`;
    //Generate hashed unique bibliothecaId
    const bibliothecaId = Math.random().toString(36).substring(2, 15);
    const docRef = doc(collection(db, configPath), `${bibliothecaId}`);
    const newBibliotheca = {
      user: userId,
      author: "",
      description: "",
      name: "",
      language: "EN",
      documents: [],
      bibliothecaId: bibliothecaId,
      originUrls: "",
      createdAt: Date.now(),
    };
    await setDoc(docRef, newBibliotheca, { merge: true });
    return bibliothecaId;
  }
  return bibliothecaId;
}

export async function retrieveDocsFromBibliotheca({ author, bibliotheca }) {
  try {
    let bibliothecaId = bibliotheca;
    const userId = auth.currentUser.uid;
    const message = `##query-bibliotheca user=${userId} author=${author} bibliothecaId=${bibliothecaId} query="give me a list of all your sources"`;
    const result = await sendMessage({
      conversationId: "temp",
      message,
      user: userId,
    });
    console.log("result: ", result);
    return result;
  } catch (e) {
    console.log("Error revrieving docs from bibliotheca: ", e);
  }
}

export async function saveBibliothecaToFirebase({ bibliothecaData }) {
  try {
    const userId = auth.currentUser.uid;
    const configPath = `users/${userId}/bibliothecas/`;
    const docRef = doc(
      collection(db, configPath),
      `${bibliothecaData.bibliothecaId}`
    );
    const newBibliotheca = {
      user: bibliothecaData.user,
      author: bibliothecaData.author,
      description: bibliothecaData.description || "",
      name: bibliothecaData.name,
      documents: bibliothecaData.documents || [],
      bibliothecaId: bibliothecaData.bibliothecaId,
      language: bibliothecaData.language,
      originUrls: bibliothecaData.originUrls,
      strategy: bibliothecaData.strategy || "default",
      processing: bibliothecaData.processing || false,
      processingDocs: bibliothecaData.processingDocs || [],
      updatedAt: Date.now(),
    };
    //console.log("newBibliotheca: ", newBibliotheca)
    await setDoc(docRef, newBibliotheca, { merge: true });
    return bibliothecaData.bibliothecaId;
  } catch (e) {
    console.log("Error creating bibliotheca: ", e);
    return e;
  }
}

export async function fetchBibliothecasFromFirebase() {
  try {
    const userId = auth.currentUser.uid;
    const userBibliothecasCol = collection(db, "users", userId, "bibliothecas");
    const userBibliothecasSnapshot = await getDocs(userBibliothecasCol);
    const userBibliothecas = userBibliothecasSnapshot.docs.map((doc) => ({
      origin: "user",
      id: doc.id,
      data: doc.data(),
    }));
    const bibliothecas = [...userBibliothecas];
    return bibliothecas;
  } catch (error) {
    console.error("Error fetching bibliothecas:", error);
    return [];
  }
}

export async function fetchBibliotheca({ bibliothecaId }) {
  const userId = auth.currentUser.uid;
  try {
    const configPath = `users/${userId}/bibliothecas/`;
    const docRef = doc(collection(db, configPath), `${bibliothecaId}`);
    const docSnap = await getDoc(docRef);
    // originUrls is an Array when coming from actions backend, but a comma separated string when coming from firestore, and documents is legacy code
    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        originUrls: Array.isArray(docSnap.data()?.originUrls)
          ? docSnap.data()?.originUrls
          : docSnap.data()?.originUrls
          ? docSnap.data()?.originUrls.split(",")
          : docSnap.data()?.documents ?? [],
      };
    } else {
      console.log("No such firebase document!");
      return {};
    }
  } catch (error) {
    console.error("Error loading bibliotheca:", error);
  }
}

export async function listUserBibliothecas() {
  try {
    const userId = auth.currentUser.uid;
    const res = await sendAction({
      actionId: "list-bibliotheca",
      args: { user: userId, author: userId },
      user: userId,
    });
    return JSON.parse(res.payload.bibliothecas);
  } catch (error) {
    console.error("Error listing bibliothecas:", error);
    return [];
  }
}

export async function parseBibliothecaDocsList(message) {
  const docsArray = message.replaceAll(`'`, ``).split(",");
  return docsArray;
}
