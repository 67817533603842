import { defineStore } from "pinia";
import {
  deleteBibliotheca,
  fetchBibliothecasFromFirebase,
  listUserBibliothecas,
  saveBibliothecaAction,
  saveBibliothecaToFirebase,
} from "@/services/bibliotheca";

export const useBibliothecasStore = defineStore({
  id: "bibliothecas",

  state: () => ({
    bibliothecas: [],
    bibliothecasFromFirebase: [],
    bibliothecasCount: 0,
    bibliothecasLoaded: false,
    deletedBibliothecas: [],
  }),

  actions: {
    async fetchUserBibliothecas() {
      const res = await listUserBibliothecas();
      const userBibliothecasObjectValues = Object.values(res);
      const userBibliothecasObjectKeys = Object.keys(res);

      const bibliothecasArray = userBibliothecasObjectValues.map(
        (b, index) => ({
          ...b,
          id: userBibliothecasObjectKeys[index],
          processing:
            this.bibliothecas.find(
              (b) => b.id === userBibliothecasObjectKeys[index]
            )?.processing ?? false,
        })
      );
      this.bibliothecas = bibliothecasArray;
      this.bibliothecasCount = this.bibliothecas.length;
      await this.syncBibliothecasData();
      this.bibliothecasLoaded = true;
      return;
    },
    async saveBibliotheca(bibliothecaData) {
      if (
        this.bibliothecas.find((b) => b.id === bibliothecaData.bibliothecaId)
      ) {
        this.bibliothecas = this.bibliothecas.filter((b) => b.id !== bibliothecaData.bibliothecaId);
      }
      this.bibliothecas.push({
        ...bibliothecaData,
        originUrls: bibliothecaData.documents,
        id: bibliothecaData.bibliothecaId,
        processing: true,
      });
      await saveBibliothecaToFirebase({ bibliothecaData });
      await saveBibliothecaAction({ bibliothecaData });
      setTimeout(() => {
        this.fetchUserBibliothecas();
      }, 30000);
      //await this.fetchUserBibliothecas();
      this.bibliothecasCount += 1;
      return;
    },
    async deleteBibliotheca({ userId, bibliothecaId }) {
      deleteBibliotheca({ userId: userId, id: bibliothecaId });
      this.bibliothecas = this.bibliothecas.filter(
        (c) => c.bibliothecaId != bibliothecaId
      );
      this.deletedBibliothecas = this.deletedBibliothecas.concat([
        bibliothecaId,
      ]);
      this.bibliothecasCount -= 1;
      return;
    },
    async syncBibliothecasData() {
      // Checks for processing bibliothecas, sync docs between backend and firestore and updates state when processing is done
      this.bibliothecasFromFirebase = await fetchBibliothecasFromFirebase();
      for (const bf of this.bibliothecasFromFirebase) {
        const b = this.bibliothecas.find((b) => b.id === bf.id);
        if (bf.data?.processing) {
          if (b) {
            this.bibliothecas.find((b) => b.id === bf.id).processing = true;
          } else {
            this.bibliothecas.push({
              ...bf.data,
              originUrls: bf.data.documents,
              id: bf.data.bibliothecaId,
            });
          }
          for (const doc of bf.data?.processingDocs) {
            if (b?.originUrls.includes(doc)) {
              bf.data.processingDocs = bf.data?.processingDocs.filter(
                (d) => d !== doc
              );
            }
          }
          if (bf.data?.processingDocs.length === 0) {
            bf.data.processing = false;
            this.bibliothecas.find((b) => b.id === bf.id).processing = false;
          }
          await saveBibliothecaToFirebase({ bibliothecaData: bf.data });
          // if it's been processing for more than 10 minutes, probably there was an error
          if (Date.now() - bf.data?.updatedAt > 300000) {
            saveBibliothecaToFirebase({
              bibliothecaData: {
                ...bf.data,
                processing: false,
                documents: b?.originUrls,
                originUrls: String(b?.originUrls),
              },
            });
          }
        } else if (b && String(bf.data?.documents) !== String(b?.originUrls)) {
          // if there is a difference between firestore and backend, we take backend data as truth and update firestore
          saveBibliothecaToFirebase({
            bibliothecaData: {
              ...bf.data,
              documents: b?.originUrls.length > 0 ? b?.originUrls : [],
              originUrls: b?.originUrls.length > 0 ? String(b?.originUrls) : "",
            },
          });
        } else if (bf.data?.processingDocs?.length > 0) {
          saveBibliothecaToFirebase({
            bibliothecaData: {
              ...bf.data,
              processingDocs: [],
            },
          });
        }
        // check for public docs stored in firebase
        if (b && bf.data?.publicDocsArray?.length > 0) {
          this.bibliothecas.find((b) => b.id === bf.id).publicDocsArray =
            bf.data.publicDocsArray;
        }
      }
      return;
    },
  },
});
