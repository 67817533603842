<template>
  <v-dialog
    :value="buyCreditsDialog"
    @input="$emit('input', $event)"
    max-width="600px"
  >
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center"
        >{{ $t("not-enough-credits") }}
      </v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-btn color="primary" variant="outlined" class="mr-2" @click="close"
            >{{ $t("close") }} </v-btn
          ><router-link to="/buy-credits">
            <v-btn color="primary">{{ $t("buy-credits") }} </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BuyCreditsDialog",
  props: {
    buyCreditsDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>
