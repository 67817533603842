<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES as supportLocales, setI18nLanguage } from "../../i18n";

const { locale } = useI18n({ useScope: "global" });
const lngSwitcher = ref(null);
watch(locale, (val) => {
  setI18nLanguage(val);
});
function switchLanguate(item) {
  setI18nLanguage(item);
}
</script>

<template>
  <v-list-item @click="() => {}">
    <v-menu ref="lngSwitcher" location="start">
      <template v-slot:activator="{ props }">
        <v-list-item-title class="d-flex" v-bind="props">
          {{ $t("language") }}:
          <v-spacer></v-spacer>&nbsp;
          <span style="opacity: 70%;">
            {{ locale === "es" ? " Español " : " English " }} >
          </span>
        </v-list-item-title>
      </template>
      <v-list class="py-0">
        <v-list-item
          v-for="(item, index) in supportLocales"
          :key="index"
          v-model="locale"
          @click="switchLanguate(item)"
        >
          <v-list-item-title>{{
            item === "es"
              ? locale === "es"
                ? "Español"
                : "Spanish"
              : locale === "en"
              ? "English"
              : "Inglés"
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<style scoped>
.v-list-item-title {
  padding: 8px;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
}
</style>
