<template>
  <v-container v-if="loading || !bibliothecasLoaded" class="bibliotheca-editor">
    <v-row>
      <v-col cols="12"
        ><v-skeleton-loader
          class="mx-auto rounded-xl"
          elevation="12"
          height="100%"
          width="100%"
          v-if="loading || !bibliothecasLoaded"
          type="article, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <v-container class="bibliotheca-editor">
      <v-row>
        <v-col cols="12">
          <v-card
            style="margin-bottom: 20vh"
            class="elevation-12 px-6 py-4 px-md-12 rounded-xl"
          >
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="saveBibliothecaVue"
            >
              <v-row class="d-flex justify-space-between pt-4">
                <div class="align-start">
                  <v-row no-gutters>
                    <v-btn @click="$router.back()" icon>
                      <IconComponent name="back" />
                    </v-btn>
                    <v-card-title>{{
                      $t("bibliothecas.edit-bibliotheca")
                    }}</v-card-title>
                  </v-row>
                </div>

                <v-btn
                  type="submit"
                  color="transparent"
                  :loading="savingForm"
                  :disabled="selectedBibliotheca?.originUrls?.length === 0"
                  icon
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("save") }}</v-tooltip
                  ><IconComponent color="purple" name="save" />
                </v-btn>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        v-if="errorMessage"
                        type="error"
                        dismissible
                        colored-border
                        outlined
                      >
                        {{ errorMessage }}
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12">
                      <v-row>
                        <v-col cols="4" class="text-left pl-4">
                          <strong>Id: </strong> {{ bibliothecaId }}
                        </v-col>
                        <v-col cols="4" class="text-left">
                          <strong>Type: </strong>
                          <v-select
                            variant="solo-filled"
                            :items="bibliothecaOriginTypeOptions"
                            v-model="selectedBibliotheca.originType"
                            :rules="required"
                          />
                        </v-col>
                        <v-col cols="4" class="text-left">
                          <strong>Strategy: </strong>
                          <v-select
                            variant="solo-filled"
                            :items="bibliothecaStrategyOptions"
                            v-model="selectedBibliotheca.strategy"
                            :rules="required"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t(`name`)"
                            variant="solo-filled"
                            v-model="selectedBibliotheca.name"
                            :rules="required"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            variant="solo-filled"
                            :items="bibliotechaLanguageNameOptions"
                            v-model="selectedBibliothecaLanguage"
                            :label="$t(`language`)"
                          />
                        </v-col>
                      </v-row>

                      <v-textarea
                        :label="$t(`description`)"
                        v-model="selectedBibliotheca.description"
                        variant="solo-filled"
                        :rows="2"
                      />
                      <v-row>
                        <v-col cols="12" md="6"> </v-col>
                        <v-col cols="12" md="6"> </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-card-title
                      >{{ $t(`documents`) }}
                      <v-tooltip location="top" activator="parent"
                        >{{ $t(`bibliothecas.allowed-formats`) }}
                      </v-tooltip>
                    </v-card-title>
                    <v-btn
                      class="mt-2"
                      size="x-small"
                      color="button"
                      @click="inputFile"
                      :loading="uploadingDoc"
                      :disabled="selectedBibliotheca?.originUrls?.length > 4"
                      icon
                      ><v-tooltip activator="parent" location="bottom">
                        {{ $t("upload-document") }}</v-tooltip
                      >
                      <IconComponent name="add" />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col v-if="docs.length === 0" cols="12">
                      <v-label>{{ $t("no-docs-found") }}</v-label>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pl-md-6" cols="12">
                      <div v-for="(doc, index) in docs" :key="index">
                        <v-row align="center" no-gutters>
                          <v-col class="mb-4" cols="1"> • </v-col>
                          <v-col align="left" cols="11" md="8">
                            <v-label
                              v-if="index < initialDocNumber"
                              :text="
                                selectedBibliotheca.originUrls[index]
                                  .substring(
                                    selectedBibliotheca.originUrls[
                                      index
                                    ].lastIndexOf('/') + 1
                                  )
                                  .split('%2F')
                                  .pop()
                                  .replace(/[\#\?].*$/, '')
                                  .replaceAll('%20', ' ')
                              "
                              class="justify-left mb-4"
                            />
                            <v-text-field
                              v-else
                              :label="$t(`document-url`)"
                              :disabled="savingForm || !bibliothecasLoaded"
                              v-model="selectedBibliotheca.originUrls[index]"
                            />
                            <!--v-text-field
                              :label="$t(`document-url`)"
                              :disabled="
                                index < initialDocNumber ||
                                savingForm ||
                                !bibliothecasLoaded
                              "
                              v-model="selectedBibliotheca.originUrls[index]"
                            /-->
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-row class="mb-4" no-gutters>
                              <v-col cols="12">
                                <!-- 'Make Public' button -->
                                <!--v-btn
                                  v-if="index < initialDocNumber"
                                  @click="
                                    openConfirmationSwitchPrivacyDialog(index)
                                  "
                                  color="transparent"
                                  icon
                                  ><v-tooltip
                                    activator="parent"
                                    location="bottom"
                                  >
                                    {{
                                      isPublicDoc(
                                        selectedBibliotheca.originUrls[index]
                                      )
                                        ? $t("make-private")
                                        : $t("make-public")
                                    }}</v-tooltip
                                  ><IconComponent
                                    color="purple"
                                    :name="
                                      isPublicDoc(
                                        selectedBibliotheca.originUrls[index]
                                      )
                                        ? 'public'
                                        : 'private'
                                    "
                                  />
                                </v-btn-->
                                <!-- View document button, deprecated -->
                                <!--v-btn
                                  v-if="index < initialDocNumber"
                                  color="transparent"
                                  icon
                                  :disabled="
                                    !isPublicDoc(
                                      selectedBibliotheca.originUrls[index]
                                    )
                                  "
                                  @click="
                                    openTab(
                                      selectedBibliotheca.originUrls[index]
                                    )
                                  "
                                >
                                  <v-tooltip
                                    activator="parent"
                                    location="bottom"
                                  >
                                    {{ $t("bibliothecas.view-doc") }}</v-tooltip
                                  >
                                  <IconComponent
                                    :color="
                                      isPublicDoc(
                                        selectedBibliotheca.originUrls[index]
                                      )
                                        ? `purple`
                                        : `gray`
                                    "
                                    name="open"
                                  />
                                </v-btn-->
                                <v-btn
                                  v-if="index + 1 > initialDocNumber"
                                  color="transparent"
                                  icon
                                  :disabled="
                                    uploadingDoc ||
                                    savingForm ||
                                    !bibliothecasLoaded
                                  "
                                  @click="deleteUrl"
                                >
                                  <IconComponent color="button" name="close" />
                                </v-btn>
                                <v-btn
                                  v-else
                                  color="transparent"
                                  icon
                                  :disabled="
                                    uploadingDoc ||
                                    savingForm ||
                                    !bibliothecasLoaded
                                  "
                                  @click="openDeleteDialog(index)"
                                  ><v-tooltip
                                    activator="parent"
                                    location="bottom"
                                  >
                                    {{ $t("delete-document") }}</v-tooltip
                                  >
                                  <IconComponent color="red" name="delete" />
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="originType.toUpperCase() === 'CSV'"
                          class="mt-0 mx-4"
                          ><v-col cols="12">
                            <v-label>
                              {{ $t("bibliothecas.select-columns-label") }}:
                            </v-label>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="additionalMetadataBodyColumns"
                              :items="csvBodyColumns"
                              label="Select"
                              multiple
                              chips
                              :hint="$t(`bibliothecas.select-columns-text`)"
                              persistent-hint
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="additionalMetadataMetadataColumns"
                              :items="csvBodyColumns"
                              label="Select"
                              multiple
                              chips
                              :hint="$t(`bibliothecas.select-columns-links`)"
                              persistent-hint
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row>
                        <v-col>
                          <DropFile
                            v-if="selectedBibliotheca?.originUrls?.length < 50"
                            @uploadFiles="receiveDropFiles"
                            :acceptFileType="getOriginType(selectedBibliotheca.originType)"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="selectedBibliotheca?.originUrls?.length > 49">
                        <v-col>
                          <p style="color: red; margin-bottom: 20px">
                            {{ $t("bibliothecas.max-5-docs") }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <div
                            v-if="selectedBibliotheca?.originUrls?.length < 50"
                          >
                            {{ $t("auth.or") }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-btn
                        v-if="selectedBibliotheca?.originUrls?.length < 50"
                        color="button"
                        class="ml-2 mt-2"
                        @click="addDocument"
                        :loading="uploadingDoc || savingForm"
                        >{{ $t("add-url") }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex justify-space-between pa-4">
                    <div></div>
                    <div>
                      <v-btn
                        type="submit"
                        :disabled="
                          selectedBibliotheca?.originUrls?.length === 0
                        "
                        :loading="savingForm"
                        color="button"
                        >{{ $t("save") }}
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <input
              id="file-upload"
              type="file"
              ref="fileRef"
              hidden
              :accept="getOriginType(selectedBibliotheca.originType)"
              @change="uploadSelectedDocument"
            />
          </v-card>
          <!--v-card
            style="margin-bottom: 20vh"
            class="elevation-12 mt-12 px-6 py-4 px-md-12 rounded-xl"
          >
            <v-form ref="form" v-model="valid" @submit.prevent="createPDF">
              <v-row class="py-4">
                <div class="text-left text-h6 ml-4 mb-2">
                  {{ $t("bibliothecas.convert-url-to-pdf") }}
                </div>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="10">
                  <v-text-field v-model="urlToPDF" label="URL" required />
                </v-col>
                <v-col cols="12" md="2">
                  <v-row class="mb-4" no-gutters>
                    <v-col cols="12">
                      <v-btn
                        color="transparent"
                        icon
                        type="submit"
                        :disabled="!urlToPDF"
                        :loading="savingPDF || loadingSpokes"
                        ><v-tooltip activator="parent" location="bottom">
                          {{ $t("download") }}</v-tooltip
                        >
                        <IconComponent color="purple" name="download" />
                      </v-btn> </v-col
                  ></v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-->
        </v-col>
      </v-row>
    </v-container>
  </div>
  <v-snackbar v-model="snackbarVisible" top color="success" timeout="5000">
    {{ $t("bibliothecas.bibliotheca-saved-message") }}
  </v-snackbar>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center">{{
        $t("delete-confirmation")
      }}</v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="this.deleteDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="button" @click="deleteDocument()">{{
            $t("delete")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <ConfirmationDialog
    v-model="confirmationChangePrivacyDialog"
    :message="confirmationChangePrivacyDialogMessage"
    :actionMessage="$t(`confirm`)"
    @close-dialog="confirmationChangePrivacyDialog = false"
    @action="handleClickSwitchPrivacy"
  />
</template>

<script>
import IconComponent from "@/components/IconComponent.vue";
import DropFile from "@/components/DropFile.vue";
import { useBibliothecasStore } from "@/stores/bibliothecas";
import { useUserStore } from "@/stores/user";
import { mapState, mapActions } from "pinia";
import RobotJSON from "@/assets/lottie-animations/robot-cute.json";
import {
  deleteFromBibliotheca,
  saveBibliothecaToFirebase,
  fetchBibliotheca,
} from "@/services/bibliotheca";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { uploadFormDataToGcpBucket } from "@services/uploadStorage";

export default {
  name: "EditBibliothecasPage",
  components: {
    IconComponent,
    DropFile,
    ConfirmationDialog,
  },
  props: {
    bibliothecaId: String,
  },
  data() {
    return {
      versationId: null,
      errorMessage: "",
      urlToPDF: undefined,
      savingPDF: false,
      bibliothecaLanguageOptions: [
        { name: "EN", value: 0, fullname: "English" },
        { name: "ES", value: 1, fullname: "Español" },
      ],
      bibliotechaLanguageNameOptions: ["English", "Español"],
      bibliothecaOriginTypeOptions: ["PDF", "CSV"],
      bibliothecaStrategyOptions: ["default", "legacy", "graph"],
      RobotJSON,
      initialDocNumber: 5,
      snackbarVisible: false,
      loading: false,
      uploadingDoc: false,
      savingForm: false,
      valid: true,
      bibliothecaIsProcessing: false,
      processingDocs: [],
      indexSelected: 0,
      deleteDialog: false,
      confirmationChangePrivacyDialog: false,
      selectedDoc: null,
      originType: "PDF",
      csvBodyColumns: [],
      additionalMetadataBodyColumns: [],
      additionalMetadataMetadataColumns: [],
      file: "",
      required: [(v) => !!v || this.$t("required-field")],
      publicDocsArray: [],
      bibliotheca: {
        user: "",
        author: "",
        name: "",
        documents: [],
        description: "",
        language: "ES",
        originType: "PDF",
        strategy: "default",
        bibliothecaId: "",
        originUrls: [],
      },
    };
  },
  async created() {
    if (this.bibliothecaId) {
      this.bibliotheca = await fetchBibliotheca({
        bibliothecaId: this.bibliothecaId,
      });
      this.publicDocsArray = this.selectedBibliotheca.publicDocsArray ?? [];
      this.initialDocNumber = this.selectedBibliotheca.originUrls?.length;
    } else {
      console.error("No bibliothecaId provided");
    }
  },
  mounted() {},
  computed: {
    selectedBibliothecaLanguage: {
      get() {
        const selectedLanguageOption = this.bibliothecaLanguageOptions.find(
          (option) =>
            option.name ===
            (this.selectedBibliotheca.language === "SPA"
              ? "ES"
              : this.selectedBibliotheca.language)
        );
        return selectedLanguageOption
          ? selectedLanguageOption.fullname
          : "English";
      },
      set(newValue) {
        this.selectedBibliotheca.language =
          this.bibliothecaLanguageOptions.find(
            (option) => option.fullname === newValue
          ).name;
      },
    },
    computedAdditionalMetadataBodyColumns() {
      return [...this.selectedBibliotheca.bodyColumns, ...this.additionalMetadataBodyColumns]
    },
    computedAdditionalMetadataMetadataColumns() {
      return [...this.selectedBibliotheca.metadataColumns, ...this.additionalMetadataMetadataColumns]
    },
    selectedBibliotheca() {
      // We use data from firebase while the bibliotheca gets loaded from backend
      const b = this.bibliothecas?.find((b) => b.id === this.bibliothecaId);
      console.log('firebase bibliotheca:', b);
      console.log('this.bibliotheca:', this.bibliotheca);
      return this.bibliothecasLoaded && b
        ? b.originUrls[0] === "undefined"
          ? this.bibliotheca
          : b
        : this.bibliotheca;
    },
    docs() {
      return this.selectedBibliotheca?.originUrls?.filter(
        (d) => d != "undefined"
      );
    },
    additionalMetadata() {
      return (
        "bodyColumns=" +
        this.computedAdditionalMetadataBodyColumns.toString().replaceAll(",", ";") +
        ",metadataColumns=" +
        this.computedAdditionalMetadataMetadataColumns.toString().replaceAll(",", ";")
      );
    },
    confirmationChangePrivacyDialogMessage() {
      return this.selectedDoc && this.isPublicDoc(this.selectedDoc)
        ? this.$t(`make-doc-public-confirmation`)
        : this.$t(`make-doc-public-confirmation`);
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useBibliothecasStore, ["bibliothecas", "bibliothecasLoaded"]),
  },
  methods: {
    async receiveDropFiles(files) {
      if (files.length + this.selectedBibliotheca.originUrls.length > 50) {
        alert("Bibliothecas can have a maximum of 50 documents for now");
      } else {
        try {
          this.uploadingDoc = true;
          for (let i = 0; i < files.length - 1; i++) {
            if (files[i].size > 10497152) {
              alert(this.$t("bibliothecas.allowed-size"));
              return;
            }
          }
          for (let i = 0; i < files.length; i++) {
            await this.uploadToPrivateBucket(files[i]);
          }
          this.bibliothecaIsProcessing = true;
          //await this.saveBibliothecaVue();
        } catch (e) {
          alert(e);
        } finally {
          this.uploadingDoc = false;
        }
      }
    },
    inputFile() {
      this.indexSelected = this.selectedBibliotheca.originUrls.length + 1;
      const inputElement = document.getElementById("file-upload");
      inputElement.click();
    },
    async uploadSelectedDocument() {
      const docToUpload = this.$refs.fileRef.files[0];
      if (docToUpload.size > 10497152) {
        alert("File must be under 10MB");
        return;
      }
      this.uploadingDoc = true;
      const filetype = this.getFileType(docToUpload.name);
      this.originType = filetype.toUpperCase();
      await this.uploadToPrivateBucket(docToUpload);
      if (filetype === "csv") {
        const reader = new FileReader();
        reader.onload = () => {
          let text = reader.result;
          this.csvBodyColumns = text.split("\n")[0].split(",");
        };
        reader.readAsText(docToUpload);
      }
      this.uploadingDoc = false;
    },
    async uploadToPrivateBucket(doc) {
      this.uploadingDoc = true;
      const docToUpload = doc;
      const formData = new FormData();
      formData.append("file", docToUpload);
      formData.append("fileName", docToUpload.name);
      formData.append("bibliothecaId", this.bibliothecaId);
      formData.append("userId", this.userData.userId);
      try {
        const response = await uploadFormDataToGcpBucket({
          formData,
          url: "https://uploaddoctobibliothecabucket-ai32xjq4va-uc.a.run.app",
        });
        //console.log(response);
        this.selectedBibliotheca.originUrls[
          this.selectedBibliotheca.originUrls?.length
        ] = response?.url;
        this.initialDocNumber = this.initialDocNumber + 1;
        this.processingDocs.push(response?.url);
        this.bibliothecaIsProcessing = true;
        this.uploadingDoc = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.uploadingDoc = false;
      }
    },
    getFileType(filename) {
      return filename.split(".").pop();
    },
    openDeleteDialog(index) {
      this.indexSelected = index;
      this.deleteDialog = true;
      this.selectedDoc = this.selectedBibliotheca.originUrls[index];
    },
    openConfirmationSwitchPrivacyDialog(index) {
      this.confirmationChangePrivacyDialog = true;
      this.selectedDoc = this.selectedBibliotheca.originUrls[index];
    },
    async deleteDocument() {
      this.deleteDialog = false;
      this.selectedBibliotheca.originUrls.splice(this.indexSelected, 1);
      this.initialDocNumber = this.initialDocNumber - 1;
      await deleteFromBibliotheca({
        userId: this.userData.userId,
        id: this.bibliothecaId,
        document: this.selectedDoc,
      });
      console.log('selectedBibliotheca.strategy (deleting):', this.selectedBibliotheca.strategy);
      const bibliothecaData = {
        name: this.selectedBibliotheca.name,
        user: this.userData.userId,
        author: this.userData.userId,
        description: this.selectedBibliotheca.description,
        language: this.selectedBibliotheca.language,
        strategy: this.selectedBibliotheca.strategy,
        bibliothecaId: this.bibliothecaId,
        documents: this.selectedBibliotheca.originUrls,
        originUrls: String(this.selectedBibliotheca.originUrls) ?? "",
        processing: this.bibliothecaIsProcessing,
        processingDocs: this.processingDocs,
        createdAt:
          this.selectedBibliotheca.createdAt ?? this.bibliotheca.createdAt,
        updatedAt: Date.now(),
      };
      await saveBibliothecaToFirebase({ bibliothecaData });
      this.selectedDoc = null;
    },
    addDocument() {
      this.selectedBibliotheca.originUrls.push("");
    },
    deleteUrl() {
      this.selectedBibliotheca.originUrls.pop();
    },
    async saveBibliothecaVue(redirectToManageBibliothecas = true) {
      this.savingForm = true;
      try {
        if (!this.selectedBibliotheca?.name) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.errorMessage = this.$t("please-fix-errors");
          return;
        }
        this.errorMessage = "";
        this.snackbarVisible = true;
        if(this.selectedBibliotheca.originType.toUpperCase() === "CSV" && this.docs.length > 1){
          this.addAdditionalMetadata(this.additionalMetadata)
        }
        //TODO: Check URL with regex
        console.log('selectedBibliotheca.strategy:', this.selectedBibliotheca.strategy);
        const bibliothecaData = {
          name: this.selectedBibliotheca.name,
          user: this.userData.userId,
          author: this.userData.userId,
          description: this.selectedBibliotheca.description,
          language: this.selectedBibliotheca.language,
          bibliothecaId: this.bibliothecaId,
          documents: this.selectedBibliotheca.originUrls,
          originType: this.originType,
          strategy: this.selectedBibliotheca.strategy,
          additionalMetadata: this.additionalMetadata || "",
          originUrls: String(this.selectedBibliotheca.originUrls) ?? "",
          processing: this.bibliothecaIsProcessing,
          processingDocs: this.processingDocs,
          publicDocsArray: this.publicDocsArray,
          createdAt:
            this.selectedBibliotheca.createdAt ?? this.bibliotheca.createdAt,
          updatedAt: Date.now(),
        };
        await this.saveBibliotheca(bibliothecaData);
        if (redirectToManageBibliothecas) {
          //this.$router.push("/manage-bibliothecas");
        }
      } catch (error) {
        console.error("Error saving bibliotheca:", error);
      } finally {
        this.savingForm = false;
      }
    },
    openTab(url) {
      window.open(url, "_blank").focus();
    },
    handleClickSwitchPrivacy() {
      this.confirmationChangePrivacyDialog = false;
      if (this.isPublicDoc(this.selectedDoc)) {
        this.publicDocsArray = this.publicDocsArray.filter(
          (url) => url !== this.selectedDoc
        );
      } else {
        this.publicDocsArray.push(this.selectedDoc);
      }
      this.selectedDoc = null;
      console.log(this.publicDocsArray);
    },
    async createPDF() {
      // TODO
      console.log(this.urlToPDF);
    },
    isPublicDoc(doc) {
      return this.publicDocsArray.find((url) => url === doc) ?? false;
    },
    getOriginType(b) {
      if(!b) return
      switch (b.toLocaleLowerCase()) {
        case "pdf":
          return "application/pdf";
        case "csv":
          return ".csv, text/csv";
        default:
          return ".csv, text/csv, application/pdf";
      }
    },
    addAdditionalMetadata(am){
      am
    },
    ...mapActions(useBibliothecasStore, ["saveBibliotheca"]),
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
}
.bibliotheca-editor {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}
.conversation-bot-container {
  margin-top: 20px;
}
</style>
