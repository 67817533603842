/* eslint-disable */
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { router } from "./router";
import vuetify from "./plugins/vuetify";
import firebasePlugin from "./plugins/firebase";
import { firebaseApp, auth } from "./firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Vue3Lottie from "vue3-lottie";
import { VueShowdownPlugin, showdown } from "vue-showdown";
import { createHead } from "@unhead/vue";
import i18n from "./i18n";
import "vue3-lottie/dist/style.css";

// adds target='_blank' to links in markdown inside conversations
showdown.extension('targetlink', function() {
  return [{
    type: 'html',
    regex: /(<a [^>]+?)(>.*<\/a>)/g,
    replace: '$1 target="_blank"$2'
  }];
});

const pinia = createPinia();
const head = createHead();
//set background to be coherent with app's
//document.body.style.backgroundColor = "#F9F4F5";
onAuthStateChanged(auth, () => {
  createApp(App)
    .use(router)
    .use(head)
    .use(vuetify)
    .use(Vue3Lottie)
    .use(VueShowdownPlugin)
    .use(i18n())
    .use(firebasePlugin, { firebaseApp, auth })
    .use(pinia)
    .mount("#app");
});
