<template>
  <div class="conversations-page">
    <v-container>
      <v-progress-circular
        v-if="!userDataLoaded"
        :size="50"
        :width="5"
        color="purple"
        class="mt-16"
        indeterminate
      >
      </v-progress-circular>
      <v-card v-else class="elevation-12 pa-4 mb-2">
        <v-row class="my-5">
          <v-col>
            <h1 class="display-1 text-center">Conversations</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ConversationsList
              v-if="userData.activeConversations"
              :title="'Active Conversations'"
              :conversations="userData.activeConversations"
              :key="'active-conversations'"
            />
          </v-col>
          <v-col>
            <ConversationsList
              v-if="userData.ownedConversations"
              :title="'Owned Conversations'"
              :conversations="userData.ownedConversations"
              :key="'owned-conversations'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn color="primary" @click="goToCreateConversation"
              >Create Conversation</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import ConversationsList from "@/components/ConversationsList.vue";

export default {
  name: "SpokesPage",
  components: {
    ConversationsList,
  },
  data() {
    return {};
  },
  methods: {
    goToCreateConversation() {
      this.$router.push("/create-conversation");
    },
  },
  computed: {
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
};
</script>
