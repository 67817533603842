<template>
  <v-container class="user-homepage">
    <v-row>
      <v-col>
        <!--v-row>
          <v-col cols="12">
            <v-card class="elevation-12 pa-4 mb-2">
              <v-row>
                <v-col cols="12">
                  <v-card-title
                    style="white-space: normal"
                    class="text-left text-primary"
                    >{{ $t("homepage.welcome") }} {{ userEmail }}</v-card-title
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row-->
        <v-row>
          <v-col cols="6" md="3">
            <v-card
              @click="this.$router.push('/manage-spokes')"
              class="elevation-12 pa-4 mb-2 hoverable"
            >
              <v-tooltip activator="parent" location="bottom"
                ><strong>{{
                  $t("homepage.spokes-description")
                }}</strong></v-tooltip
              >
              <v-row no-gutters>
                <v-col cols="12" md="9">
                  <v-card-title
                    style="white-space: normal"
                    class="text-md-left text-body-2 text-md-subtitle-1 pa-0 pa-md-2"
                    ><strong>Spokes: </strong></v-card-title
                  >
                </v-col>
                <v-col cols="12" md="3">
                  <v-progress-circular
                    v-if="!spokesLoaded"
                    :size="16"
                    :width="2"
                    color="purple"
                    class="ml-md-8 mt-md-4"
                    indeterminate
                  ></v-progress-circular>
                  <v-card-title
                    v-else
                    style="white-space: normal"
                    class="text-md-right font-weight-regular text-body-1 pa-0 pa-md-2"
                    >{{ spokesCount }}</v-card-title
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <Vue3Lottie
                    :animationData="RobotJSON"
                    :height="100"
                    :width="100"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card
              @click="this.$router.push('/manage-bibliothecas')"
              class="elevation-12 pa-4 mb-2 hoverable"
            >
              <v-tooltip activator="parent" location="bottom"
                ><strong>{{
                  $t("homepage.bibliothecas-description")
                }}</strong></v-tooltip
              >
              <v-row no-gutters>
                <v-col cols="12" md="9">
                  <v-card-title
                    style="white-space: normal"
                    class="text-md-left text-body-2 text-md-subtitle-1 pa-0 pa-md-2"
                    ><strong>Bibliothecas:</strong></v-card-title
                  >
                </v-col>
                <v-col cols="12" md="3">
                  <v-progress-circular
                    v-if="!bibliothecasLoaded"
                    :size="16"
                    :width="2"
                    color="purple"
                    class="ml-md-8 mt-md-4"
                    indeterminate
                  ></v-progress-circular>
                  <v-card-title
                    v-else
                    style="white-space: normal"
                    class="text-md-right font-weight-regular text-body-1 pa-0 pa-md-2"
                    >{{ bibliothecasCount }}</v-card-title
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <Vue3Lottie
                    :animationData="BibliothecasJSON"
                    :height="100"
                    :width="100"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <a
              :href="
                $i18n.locale === `en`
                  ? `https://github.com/yggdrasil-ai/docs/blob/trunk/UserGuideEN.md#Action`
                  : `https://github.com/yggdrasil-ai/docs/blob/trunk/UserGuideES.md#acción`
              "
              target="_blank"
            >
              <v-card class="elevation-12 pa-4 mb-2">
                <v-tooltip activator="parent" location="bottom"
                  ><strong>{{
                    $t("homepage.actions-description")
                  }}</strong></v-tooltip
                >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-title
                      style="white-space: normal"
                      class="text-body-2 text-md-subtitle-1"
                      ><strong>{{ $t("actions") }}</strong></v-card-title
                    >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <Vue3Lottie
                      :animationData="RobotActionsJSON"
                      :height="100"
                      :width="100"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </a>
          </v-col>
          <v-col cols="6" md="3">
            <v-card
              @click="this.$router.push('/integrations')"
              class="elevation-12 pa-4 mb-2 hoverable"
            >
              <v-tooltip activator="parent" location="bottom"
                ><strong>{{
                  $t("homepage.integrations-description")
                }}</strong></v-tooltip
              >
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title
                    style="white-space: normal"
                    class="text-body-2 text-md-subtitle-1"
                    ><strong>{{ $t("integrations") }}</strong></v-card-title
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <Vue3Lottie
                    :animationData="IntegrationsJSON"
                    :height="100"
                    :width="100"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels model-value="myNews">
              <v-expansion-panel value="myNews" class="rounded-xl">
                <v-expansion-panel-title>
                  <span
                    class="text-primary font-weight-bold text-h4 text-left pa-2 px-md-4"
                  >
                    {{ $t("homepage.news-title") }}
                  </span>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row>
                    <v-col>
                      <NewsList :news="news" :loadingNews="!newsLoaded" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import NewsList from "@/components/NewsList.vue";
import { useNewsStore } from "@/stores/news";
import { useSpokesStore } from "@/stores/spokes";
import { useBibliothecasStore } from "@/stores/bibliothecas";
import { isAuthenticated } from "@/services/user";
import RobotJSON from "../assets/lottie-animations/robot-cute.json";
import RobotActionsJSON from "../assets/lottie-animations/robot-tools.json";
import BibliothecasJSON from "../assets/lottie-animations/doc.json";
import IntegrationsJSON from "../assets/lottie-animations/integrations.json";

export default {
  name: "UserHomepage",
  components: {
    NewsList,
  },
  data() {
    return {
      RobotJSON,
      RobotActionsJSON,
      BibliothecasJSON,
      IntegrationsJSON,
    };
  },
  created() {
    if (!isAuthenticated) return this.$router.push("/Login");
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    ...mapState(useNewsStore, ["news", "newsLoaded"]),
    ...mapState(useSpokesStore, ["spokesLoaded", "spokesCount"]),
    ...mapState(useBibliothecasStore, [
      "bibliothecasLoaded",
      "bibliothecasCount",
    ]),
  },
};
</script>

<style scoped>
.user-homepage {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-bottom: 20vh;
}
.row-spacing {
  margin-bottom: -50px;
  margin-top: -50px;
}
</style>
