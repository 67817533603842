<template>
  <v-container fluid class="registration-container">
    <v-list class="language-switcher-wrapper">
      <LanguageSwitcher class="language-switcher" />
    </v-list>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="4">
        <div class="align-center px-2">
          <img
            class="icon"
            src="@/assets/SPOKES_logo_HORIZONTAL_NEGRO.png"
            alt="Spokes App Icon"
          />
        </div>
        <!--registration-form @login="handleLogin"></registration-form-->
        <!--waitlist-form /-->
        <!--StripeTable /-->
        <FreeUserSignupForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FreeUserSignupForm from "@/components/auth/FreeUserSignupForm.vue";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher.vue";

export default {
  name: "RegistrationPage",
  components: {
    FreeUserSignupForm,
    LanguageSwitcher,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.registration-container {
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  height: 100vh;
}

.icon {
  height: 8vh;
  margin-bottom: 3vh;
  width: auto;
  object-fit: contain;
  max-width: 100%;
}

.language-switcher-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
}

.back-to-login {
  position: absolute;
  left: 10px;
  top: 20px;
  background-color: transparent;
}
</style>
