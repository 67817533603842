import { createRouter, createWebHistory } from "vue-router";
import { isAuthenticated } from "@/services/user";
import LoginPage from "./pages/auth/LoginPage.vue";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage.vue";
import RegistrationPage from "./pages/auth/RegistrationPage.vue";
import UserHomePage from "./pages/UserHomePage.vue";
import IntegrationsPage from "./pages/integrations/IntegrationsPage.vue";
import BibliothecasPage from "./pages/bibliothecas/BibliothecasPage.vue";
import ConfigurationPage from "./pages/ConfigurationPage.vue";
import SpokesPage from "./pages/spokes/SpokesPage.vue";
import ConversationsPage from "./pages/ConversationsPage.vue";
import CreateConversationPage from "./pages/CreateConversationPage.vue";
import EditSpokesPage from "./pages/spokes/EditSpokesPage.vue";
import EditBibliothecasPage from "./pages/bibliothecas/EditBibliothecasPage.vue";
import ConversationWindow from "./components/ConversationWindow.vue";
import SlackPage from "./pages/integrations/SlackPage.vue";
import TelegramPage from "./pages/integrations/TelegramPage.vue";
import ShopifyPage from "./pages/integrations/ShopifyPage.vue";
import UserConfigPage from "./pages/UserConfigPage.vue";
import BuyCreditsPage from "./pages/BuyCreditsPage.vue";
import OutputsPage from "./pages/OutputsPage.vue";
import AnalyticsPage from "./pages/AnalyticsPage.vue";

const routes = [
  { path: "", name: "Origin", component: UserHomePage },
  { path: "/", name: "Login", component: LoginPage },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
  },
  { path: "/register", name: "Registration", component: RegistrationPage },
  { path: "/home", name: "UserHome", component: UserHomePage },
  {
    path: "/configuration",
    name: "Configuration",
    component: ConfigurationPage,
  },
  {
    path: "/buy-credits",
    name: "BuyCredits",
    component: BuyCreditsPage,
  },
  {
    path: "/edit-spoke/:spokeId",
    name: "EditSpoke",
    component: EditSpokesPage,
    props: (route) => ({
      origin: route.params.origin,
      spokeId: route.params.spokeId,
    }),
  },
  {
    path: "/edit-bibliotheca/:bibliothecaId",
    name: "EditBibliotheca",
    component: EditBibliothecasPage,
    props: (route) => ({
      origin: route.params.origin,
      bibliothecaId: route.params.bibliothecaId,
    }),
  },
  {
    path: "/chat/:conversationId?",
    name: "ChatPage",
    component: ConversationWindow,
    props: (route) => ({
      conversationId: route.params.conversationId,
      spokeUser: route.query.spokeUser,
      owner: route.query.owner,
      spokeId: route.query.spokeId,
    }),
  },
  {
    path: "/manage-spokes",
    name: "ManageSpokes",
    component: SpokesPage,
  },
  {
    path: "/manage-outputs",
    name: "ManageOutputs",
    component: OutputsPage,
  },
  {
    path: "/manage-bibliothecas",
    name: "ManageBibliothecas",
    component: BibliothecasPage,
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: IntegrationsPage,
  },
  {
    path: "/integrations/slack",
    name: "Slack",
    component: SlackPage,
  },
  {
    path: "/integrations/telegram",
    name: "Telegram",
    component: TelegramPage,
  },
  {
    path: "/integrations/shopify",
    name: "Shopify",
    component: ShopifyPage,
  },
  {
    path: "/manage-analytics",
    name: "ManageAnalytics",
    component: AnalyticsPage,
  },
  {
    path: "/manage-conversations",
    name: "ManageConversations",
    component: ConversationsPage,
  },
  {
    path: "/user-config",
    name: "ManageUser",
    component: UserConfigPage,
  },
  {
    path: "/create-conversation",
    name: "CreateConversation",
    component: CreateConversationPage,
  },
];

const publicPages = [
  { path: "/home", name: "home", icon: "home" },
  { path: "/manage-spokes", name: "Spokes", icon: "robot" },
  {
    path: "/manage-bibliothecas",
    name: "Bibliothecas",
    icon: "bibliothecas",
  },
  { path: "/integrations", name: "integrations", icon: "integrations" },
  { path: "/user-config", name: "configuration", icon: "config" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  window.scrollTo({ top: 0, left: 0 });
  if (
    to.name !== "Login" &&
    to.name !== "Registration" &&
    to.name !== "ForgotPassword" &&
    to.name !== "ConversationWidget" &&
    to.name !== "WidgetApp" &&
    !isAuthenticated()
  ) {
    next({ name: "Login" });
  } else if (
    (to.name === "Login" ||
      to.name === "Registration" ||
      to.name === "ForgotPassword") &&
    isAuthenticated()
  ) {
    next({ name: "UserHome" });
  } else {
    next();
  }
});

export { router, publicPages };
