<template>
  <v-row v-if="loadingSpokes" class="mx-2">
    <v-col>
      <v-progress-circular
        :size="50"
        :width="5"
        color="purple"
        class="my-8"
        indeterminate
      ></v-progress-circular>
    </v-col>
  </v-row>
  <v-row no-gutters v-else>
    <v-col>
      <v-container class="px-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="spoke in spokes"
            :key="spoke.id"
          >
            <v-card class="elevation-4 pa-4">
              <v-btn
                v-if="integration && integrationType === 'slack'"
                class="ma-1"
                style="position: absolute; left: 10px; top: 10px"
                icon
                :loading="updatingDefaultSlackSpoke"
                size="small"
                color="white"
                @click="setDefaultSpokeForSlackWorkspace(spoke)"
                ><v-tooltip activator="parent" location="top">
                  {{
                    $t("integrationsPage.set-default-spoke-for-workspace")
                  }}</v-tooltip
                >
                <IconComponent
                  color="purple"
                  :name="spoke.defaultWorkspaceSpoke ? `star` : `emptyStar`"
                />
              </v-btn>
              <v-btn
                v-if="!spoke.defaultWorkspaceSpoke"
                class="ma-1"
                style="position: absolute; right: 10px; top: 10px"
                icon
                size="small"
                color="white"
                @click="openDeleteDialog(spoke)"
                ><v-tooltip activator="parent" location="bottom">
                  {{ $t("spokes.delete") }} {{ spoke.data.name }}</v-tooltip
                >
                <IconComponent
                  color="red"
                  :name="!integration ? `delete` : `close`"
                />
              </v-btn>
              <v-card-title class="text-primary text-h6 text-center"
                ><span @click="goToEditSpokes(spoke)" class="hoverable">
                  {{ spoke.data.name }}</span
                >
              </v-card-title>
              <div v-if="spoke.data.selectedStyle === 'image'">
                <v-img
                  @click="goToEditSpokes(spoke)"
                  class="align-end hoverable"
                  height="100"
                  :src="spoke.data.imageUrl"
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="purple"
                        indeterminate
                      ></v-progress-circular>
                    </div> </template
                ></v-img>
              </div>
              <div @click="goToEditSpokes(spoke)" v-else>
                <RobotsComponent
                  :index="spoke.data.selectedRobotAnimationIndex"
                  :hoverable="true"
                />
              </div>
              <v-row class="d-flex">
                <v-col
                  v-if="integration && integrationType === `telegram`"
                  cols="12"
                  class="text-left pa-4"
                >
                  <v-card-text>
                    <v-row>
                      <strong>Bot Id: </strong> &nbsp;
                      {{ spoke.botId }}
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col
                  v-else-if="integration && integrationType === `slack`"
                  cols="12"
                  class="text-left pa-4"
                >
                  <v-card-text v-f="spoke.channelId">
                    <v-row>
                      <strong>Channel ID: </strong>
                      &nbsp;
                      {{ spoke.channelId }}
                    </v-row>
                  </v-card-text>
                  <v-card-text v-if="spoke.botUserId">
                    <v-row>
                      <strong>Bot User Id: </strong> &nbsp;
                      {{ spoke.botUserId }}
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col v-else cols="12">
                  <v-card-text
                    class="pb-1 pt-4 hoverable"
                    @click="copySpokeId(spoke)"
                    ><v-tooltip activator="parent" location="top">
                      {{ $t("spokes.copy-spoke-id") }}</v-tooltip
                    >
                    <strong>id: </strong> &nbsp;
                    {{ spoke.id }}
                  </v-card-text>
                </v-col>
              </v-row>
              <div v-if="!integration">
                <v-btn
                  class="ma-1 la-1"
                  color="transparent"
                  size="small"
                  icon
                  @click.stop="showEmbedDialog(spoke)"
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("spokes.embed1") }} {{ spoke.data.name
                    }}{{ $t("spokes.embed2") }}</v-tooltip
                  >
                  <IconComponent color="purple" name="embed" />
                </v-btn>
                <v-btn
                  class="ma-1 la-1"
                  color="transparent"
                  size="small"
                  icon
                  @click.stop="goToChat(spoke)"
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("spokes.chat") }} {{ spoke.data.name }}</v-tooltip
                  >
                  <IconComponent color="purple" name="chat" />
                </v-btn>
                <v-btn
                  class="ma-1 la-1"
                  color="transparent"
                  icon
                  size="small"
                  @click="goToEditSpokes(spoke)"
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("spokes.edit") }} {{ spoke.data.name }}</v-tooltip
                  >
                  <IconComponent color="purple" name="edit" />
                </v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col
            v-if="spokes.length < 8 && !integration"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-row
              align="center"
              class="d-flex"
              style="height: 100%; min-height: 300px"
            >
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="goToCreateSpoke"
                  :loading="loadingCreateSpokes"
                  icon
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("spokes.create-one") }}</v-tooltip
                  >
                  <IconComponent name="add" :size="40" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
  <v-snackbar
    v-model="snackbarSpokeIdVisible"
    top
    color="success"
    timeout="2000"
  >
    {{ $t("spokes.spoke-id-copied") }}
  </v-snackbar>
  <v-dialog v-model="embedDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center">
        {{ $t("spokes.embeddable-iframe") }}
      </v-card-title>
      <v-card-text>
        <v-snackbar
          v-model="snackbarVisible"
          top
          color="success"
          timeout="2000"
        >
          {{ $t("spokes.code-copied") }}!
        </v-snackbar>
        <p class="text-primary">
          {{ $t("spokes.embed-instructions") }}
        </p>
        <v-textarea
          ref="iframearea"
          class="mt-8 hoverable"
          style="color: gray"
          v-model="embedCode"
          readonly
          rows="8"
          auto-grow
          outlined
          dense
          @click="copyToClipboard"
        ></v-textarea>
      </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-btn
            color="primary"
            class="mr-2"
            variant="outlined"
            @click="embedDialog = false"
            >{{ $t("close") }}</v-btn
          >
          <v-btn color="primary" @click="copyToClipboard">{{
            $t("copy")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center"
        >{{ deleteMessage }}
      </v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="this.deleteDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="myDeleteSpoke(this.selectedForDelete)"
            >{{ $t("delete") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <BuyCreditsDialog
    v-model="buyCreditsDialog"
    @close-dialog="buyCreditsDialog = false"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useIntegrationsStore } from "@/stores/integrations";
import { retrieveOrCreateSpoke } from "@/services/spoke";
import { auth } from "@/firebase";
import IconComponent from "@/components/IconComponent.vue";
import RobotsComponent from "@/components/RobotsComponent.vue";
import BuyCreditsDialog from "@/components/dialogs/BuyCreditsDialog.vue";

export default {
  name: "SpokesList",
  components: {
    IconComponent,
    RobotsComponent,
    BuyCreditsDialog,
  },
  props: {
    spokes: {
      type: Array,
      default: () => [],
    },
    loadingSpokes: {
      type: Boolean,
      default: false,
    },
    integration: {
      type: Boolean,
      default: false,
    },
    integrationType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      deletedSpokes: [],
      buyCreditsDialog: false,
      embedDialog: false,
      deleteDialog: false,
      embedCode: "",
      snackbarSpokeIdVisible: false,
      snackbarVisible: false,
      loadingCreateSpokes: false,
      selectedForDelete: null,
      updatingDefaultSlackSpoke: false,
    };
  },
  methods: {
    async goToCreateSpoke() {
      this.loadingCreateSpokes = true;
      const spokeDefaults = {
        description: this.$t("spokes.defaults.description"),
        context: this.$t("spokes.defaults.context"),
        startingMessage: this.$t("spokes.defaults.first-message"),
        goal: this.$t("spokes.defaults.goal"),
        personality: this.$t("spokes.defaults.personality"),
      };
      const spokeId = await retrieveOrCreateSpoke({
        spoke: undefined,
        spokeDefaults,
      });
      this.$router.push({ name: "EditSpoke", params: { spokeId: spokeId } });
      this.loadingCreateSpokes = false;
    },
    async copyToClipboard() {
      const nativeTextarea = this.$refs.iframearea;
      await navigator.clipboard.writeText(nativeTextarea.value);
      this.snackbarVisible = true;
    },
    async myDeleteSpoke(spoke) {
      if (this.integration && this.integrationType === "telegram") {
        this.deleteDialog = false;
        await this.deleteTelegramBot({ botId: spoke.botId });
        this.selectedForDelete = null;
      } else if (this.integration && this.integrationType === "slack") {
        this.deleteDialog = false;
        await this.deleteSlackChannel({
          workspace: spoke.workspaceId,
          channel: spoke.channelId,
        });
        this.selectedForDelete = null;
      } else {
        this.deleteDialog = false;
        const userId = auth.currentUser.uid;
        const spokeId = spoke.id;
        await this.deleteSpoke({ owner: userId, spokeId: spokeId });
        this.selectedForDelete = null;
      }
    },
    async setDefaultSpokeForSlackWorkspace(spoke) {
      this.updatingDefaultSlackSpoke = true;
      await this.setDefaultSpokeForWorkspace({
        spokeId: spoke.id,
        workspace: spoke.workspaceId,
        channel: spoke.channelId,
      });
      this.$emit("updateDefaultSlackSpokes");
      this.updatingDefaultSlackSpoke = false;
    },
    openDeleteDialog(spoke) {
      this.deleteDialog = true;
      this.selectedForDelete = spoke;
    },
    goToEditSpokes(spoke) {
      this.$router.push({ name: "EditSpoke", params: { spokeId: spoke?.id } });
    },
    async copySpokeId(spoke) {
      await navigator.clipboard.writeText(spoke?.id);
      this.snackbarSpokeIdVisible = true;
    },
    goToChat(spoke) {
      if (this.userData.credits != undefined && this.userData.credits < 1) {
        this.buyCreditsDialog = true;
      } else {
        const userId = auth.currentUser.uid;
        const spokeId = spoke.id;
        const brain = spoke.data.brain;
        this.$router.push({
          name: "ChatPage",
          query: { spokeUser: userId, spokeId: spokeId, brain: brain },
        });
      }
    },
    showEmbedDialog(spoke) {
      const script =
        '<script src="https://yggdrasil-ai-hermod.web.app/static/spokes-iframe-handler.js"></';
      this.embedCode = `<iframe id="spokes-iframe" src="https://yggdrasil-ai-hermod.web.app/widget/conversation?apiKey=${this.userData.apiKey}&spokeId=${spoke.id}" frameborder="0" scrolling="no" style="position: fixed; bottom: 10px; right: 10px; z-index: 9999; min-height: 150px; min-width: 150px; max-height: 70vh; max-width: 95vw; background-color: transparent;"></iframe>${script}script>`;
      this.embedDialog = true;
    },
    ...mapActions(useSpokesStore, ["deleteSpoke"]),
    ...mapActions(useIntegrationsStore, [
      "deleteTelegramBot",
      "deleteSlackChannel",
      "setDefaultSpokeForWorkspace",
    ]),
  },
  computed: {
    deleteMessage: {
      get() {
        return this.integrationType === "telegram"
          ? this.$t("integrationsPage.confirm-remove-bot-integration")
          : this.integrationType === "slack"
          ? this.$t("integrationsPage.confirm-remove-slack-channel")
          : this.$t("spokes.delete-confirmation");
      },
    },
    ...mapState(useSpokesStore, ["spokesCount"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
};
</script>
<style scoped>
.configurations-card {
  max-width: 500px;
}
.origin {
  font-size: 12px;
  text-align: left;
}
</style>
