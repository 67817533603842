import { doc, collection, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { sendAction } from "@/services/actions";

export async function logOut() {
  await auth.signOut();
}

export function isAuthenticated() {
  return auth.currentUser !== null;
}

export async function fetchUser() {
  const userId = auth.currentUser.uid;
  try {
    const docRef = doc(collection(db, `users`), `${userId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        displayName: auth.currentUser.displayName,
        email: auth.currentUser.email,
        userId: auth.currentUser.uid,
        photoUrl: auth.currentUser.photoURL,
      };
    } else {
      console.log("No such document!");
      return {};
    }
  } catch (error) {
    console.error("Error loading user data:", error);
    return {};
  }
}

export async function saveUserData({ userData }) {
  const userId = auth.currentUser.uid;
  try {
    const docRef = doc(collection(db, `users`), `${userId}`);
    await setDoc(docRef, ...userData);
    console.log("Saved user data: ", userData);
    return userData;
  } catch (e) {
    console.error("Error saving user data:", e);
    return {};
  }
}

export async function createFreeUser({ email, language }) {
  const res = await sendAction({
    actionId: "create-free-user",
    args: {
      author: "UmjgvP0qvnwN5xz6Ap6Z",
      email: email,
      language: language || "EN"
    },
    user: "UmjgvP0qvnwN5xz6Ap6Z",
    apiKey:"stripeAPIKey#!'4"
  });
  console.log(res);
  return res;
}
