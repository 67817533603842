import { auth, getIdToken } from "@/firebase";

export async function sendAction({ actionId, args, user, apiKey }) {
  //console.log("Sending action",  actionId  , ":",  user, " - ", args);
  const userId = user ?? auth.currentUser.uid;
  const idToken = apiKey ?? await getIdToken();

  if (!idToken) {
    throw new Error("User not authenticated");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      action: actionId,
      user: userId,
      args: args,
    }),
  };

  const response = await fetch(
    process.env.VUE_APP_ACTION_PROCESSOR_URL,
    requestOptions
  );

  if (!response.ok) {
    throw new Error(`Error sending action: ${response.statusText}`);
  }
  const result = await response.json();
  return result;
}

// sendEmail endpoints: https://sendemail-ai32xjq4va-uc.a.run.app
