<template>
  <v-card class="elevation-12 rounded-xl pb-6">
    <v-card-text class="py-0">
      <v-card-title class="text-h5 font-weight-bold pt-6"
        >{{ $t(`auth.registration`) }}
      </v-card-title>
      <br />
      <v-alert
        v-if="errorMessage"
        type="error"
        dismissible
        colored-border
        outlined
      >
        {{ errorMessage }}
      </v-alert>
      <v-form
        v-if="!signupSuccesful"
        ref="signupForm"
        v-model="valid"
        @submit.prevent="handleSignup"
      >
        <v-text-field
          v-model="email"
          label="Email"
          :rules="[rules.validEmail]"
        />
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mt-4"
              type="submit"
              :disabled="loading || !email || !valid"
              :loading="loading"
              color="button"
              >{{ $t(`auth.signup`) }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn to="/login" class="back-to-login">
              {{ $t(`auth.back-to-login`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div v-else>
        <IconComponent :size="80" color="green" name="success" />
        <v-card-title class="pb-4 pt-2 text-wrap"
          >{{ $t(`auth.signup-success`) }}
        </v-card-title>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import IconComponent from "@/components/IconComponent.vue";
import { createFreeUser } from "@/services/user";

export default {
  name: "FreeUserSignupForm",
  components: {
    IconComponent,
  },
  data() {
    return {
      valid: true,
      email: "",
      errorMessage: "",
      plans: ["Basic (6€/month)", "Pro (15€/month)", "Business (Custom)"],
      selectedPlan: null,
      error: false,
      loading: false,
      signupSuccesful: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 8 || "Min 8 characters",
        validEmail: (value) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(
            value
          ) || this.$t("auth.email-validation"),
      },
    };
  },
  methods: {
    async handleSignup() {
      if (!this.$refs.signupForm.validate()) {
        this.errorMessage = this.$t("please-fix-errors");
        return;
      }
      this.errorMessage = "";
      try {
        this.loading = true;
        const lng = this.$i18n.locale === "es" ? "ES" : "EN";
        const res = await createFreeUser({ email: this.email, language: lng });
        if (res.payload.created === "false") {
          this.errorMessage = res.responseMessage;
          return;
        }
        this.signupSuccesful = true;
      } catch (error) {
        console.error(error);
        this.erorr = false;
        this.errorMessage = this.$t("auth.error-uploading-form");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
