<template>
  <div v-if="!readMore" class="news-list">
    <v-select
      v-model="selectedTopics"
      :items="allowedTopics"
      :label="$t(`homepage.select-topic`)"
      multiple
      chips
      clearable
      outlined
      dense
      color="primary"
    ></v-select>
    <v-row v-if="loadingNews">
      <v-col
        cols="12"
        md="4"
        class="d-flex"
        style="max-height: 450px"
        v-for="piece in 6"
        :key="piece"
      >
        <v-skeleton-loader
          class="mx-auto"
          elevation="12"
          width="400"
          height="320"
          v-if="loadingNews"
          type="article, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="4"
        class="d-flex"
        style="max-height: 450px"
        v-for="piece in filteredNews"
        :key="piece.id"
      >
        <v-card @click="onReadMoreClick(piece)" class="elevation-1 pa-0 mb-2">
          <v-img
            class="align-end"
            height="250"
            :src="piece.imageUrl ? piece.imageUrl : defaultImageUrl"
            ><v-card-title
              style="white-space: normal; opacity: 0.85"
              class="pa-0"
            >
              <div class="text-h5 font-weight-bold text--primary bg-black pa-4">
                {{ $i18n.locale === `es` ? piece.title : piece.titleEn }}
              </div>
            </v-card-title></v-img
          >
          <div class="pa-4">
            <v-chip
              color="info"
              v-for="(topic, index) in piece.topics"
              :key="index"
              class="mr-1"
              v-on="on"
              small
              >{{ topic }}</v-chip
            >
          </div>
          <v-card-text>
            <p class="short-description">
              {{ $i18n.locale === `es` ? removeMarkdown(piece.description): removeMarkdown(piece.descriptionEn) }}
            </p>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-if="readMore">
    <v-row class="mx-0">
      <v-col class="px-0" cols="12">
        <div class="d-flex align-start my-4 mt-6">
          <v-btn @click="onBackButtonClick()" icon>
            <IconComponent name="back" />
          </v-btn>
          <div class="text-h4 font-weight-bold ml-4">
            {{ $i18n.locale === `es` ? selectedArticle.title : selectedArticle.titleEn }}
          </div>
        </div>
        <v-card class="elevation-1 pa-0 mb-16">
          <v-row>
            <v-col>
              <div class="d-flex align-center">
                <v-img
                  max-height="300"
                  :src="
                    selectedArticle.imageUrl
                      ? selectedArticle.imageUrl
                      : defaultImageUrl
                  "
                />
              </div>
            </v-col>
          </v-row>

          <vue-showdown
            class="pa-md-12 pa-4 text-left"
            :markdown="$i18n.locale === `es` ? `&nbsp;&nbsp;&nbsp;&nbsp;`+selectedArticle.description : `&nbsp;&nbsp;&nbsp;&nbsp;`+selectedArticle.descriptionEn"
            flavor="github"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconComponent from "@/components/IconComponent.vue";
import { VueShowdown } from "vue-showdown";

export default {
  name: "SpokesList",
  props: {
    news: {
      type: Array,
      default: () => [],
    },
    loadingNews: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueShowdown,
    IconComponent
  },
  data() {
    return {
      readMore: false,
      defaultImageUrl:
        "https://yggdrasil-ai-hermod.web.app/img/hermod.216a141f.png",
      selectedArticle: [],
      selectedTopics: [],
      allowedTopics: ["General Interest", "Technical", "Business"],
    };
  },
  methods: {
    onReadMoreClick(article) {
      this.selectedArticle = article;
      this.readMore = true;
    },
    onBackButtonClick() {
      this.readMore = false;
    },
    removeMarkdown(s){
      return s.replace(/&nbsp;|<br>/g, '')
    }
  },
  computed: {
    filteredNews() {
      if (this.selectedTopics.length === 0) {
        return this.news.slice().sort((a, b) => b.created_at - a.created_at);
      } else {
        return this.news
          .filter((piece) => {
            return piece.topics?.some((topic) =>
              this.selectedTopics.includes(topic)
            );
          })
          .slice()
          .sort((a, b) => b.created_at - a.created_at);
      }
    },
  },
};
</script>
<style scoped>
.configurations-card {
  max-width: 500px;
}
.tag {
  font-size: 12px;
  text-align: left;
}

.paragraph {
  display: inline-block;
}

.short-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
</style>
