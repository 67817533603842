<template>
  <div class="main">
    <div
      class="dropzone-container mx-md-16"
      :style="isDragging && 'border-color: purple; background-color: #e1f3ff;'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        :accept="acceptFileType"
      />

      <label for="fileInput" class="file-label hoverable">
        <div>{{ $t("drop-files-here") }}.</div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropFile",
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  props: {
    acceptFileType: String,
  },
  methods: {
    onChange() {
      this.files = this.$refs.file.files;
      this.$emit("uploadFiles", this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>
<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 4rem;
  margin-top: 0;
  margin-bottom: 10px;
  background: #f7fafc;
  width: 100%;
  border: 2px dashed;
  border-color: #9e9e9e;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>
