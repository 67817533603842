import { defineStore } from "pinia";
import {
  listOpenConversations,
  deleteConversation,
  fetchConversationDataFromFirebase,
} from "@/services/conversation";

export const useConversationsStore = defineStore({
  id: "conversations",

  state: () => ({
    openConversations: {},
    deletedConversations: [],
    openConversationsCount: 0,
    conversationsLoaded: false,
  }),

  actions: {
    async fetchOpenConversations() {
      const res = await listOpenConversations();
      const openConversationsArray = res;
      this.openConversations = await JSON.parse(
        JSON.stringify(openConversationsArray)
      );
      this.openConversationsCount = res.length;
      this.conversationsLoaded = true;
    },
    async deleteConversation(conversation) {
      deleteConversation({
        conversationId: conversation,
      });
      this.deletedConversations = this.deletedConversations.concat([
        conversation,
      ]);
      this.openConversations = this.openConversations.filter(
        (c) => !this.deletedConversations.includes(c)
      );
      this.openConversationsCount -= 1;
    },
    separateIds(ids) {
      const [userId, spokeId, conversationId] = ids.split(".");
      return { userId, spokeId, conversationId };
    },
    async getConversationDataFromFirebase({ user, spoke, conversation }) {
      return await fetchConversationDataFromFirebase({
        user,
        spoke,
        conversation,
      });
    },
  },
});
