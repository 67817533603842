import {
  doc,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import { sendAction } from "@/services/actions";
import { randomNameGenerator } from "@/utils/nameGenerator";

export async function saveSpoke({ owner, spokeId, spokeData }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "create-spoke",
    args: {
      owner: owner,
      author: userId,
      spokeId: spokeId,
      spokeConfig: spokeData,
    },
    user: userId,
  });
  console.log("saved spoke: ", res.payload);
}

export async function retrievePublicTemplate({ userId, id }) {
  const originPath = "publicTemplates";
  const destinyPath = `users/${userId}/spokes/`;
  console.log("Retrieving public template", originPath, destinyPath);
  const docRef = doc(collection(db, originPath), `${id}`);
  console.log("achieved ref", docRef);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("Document found");
    const docData = docSnap.data();
    docData["template"] = true;
    const newDocRef = doc(collection(db, destinyPath), `${id}`);
    await setDoc(newDocRef, docData);
  } else {
    console.log("No such document!");
  }
}

export async function deleteSpokeFromFirebase({ userId, id }) {
  const configPath = `users/${userId}/spokes/`;
  const docRef = doc(collection(db, configPath), `${id}`);
  await deleteDoc(docRef);
}

export async function saveSpokeToFirebase({ userId, id, spokeData }) {
  const configPath = `users/${userId}/spokes/`;
  const docRef = doc(collection(db, configPath), `${id}`);
  await setDoc(docRef, spokeData, { merge: true });
}

export async function deleteSpoke({ owner, spokeId }) {
  const userId = auth.currentUser.uid;
  await sendAction({
    actionId: "delete-spoke",
    args: { author: userId, owner: owner, spokeId: spokeId },
    user: userId,
  });
}

export async function retrieveOrCreateSpoke({ spoke, spokeDefaults }) {
  console.log("Retrieving spokesman", spoke);
  let spokeId = spoke;
  const userId = auth.currentUser.uid;
  if (spoke) {
    /*
    await sendAction({
      actionId: "retrieve-spoke",
      args: { author: userId, spokeId: spoke },
      user: userId,
    });*/
  } else {
    console.log("Missing parameters. Creating spokesman in cache");
    const configPath = `users/${userId}/spokes/`;
    //Generate hashed unique spokeId
    const spokeId = Math.random().toString(36).substring(2, 15);
    const docRef = doc(collection(db, configPath), `${spokeId}`);
    const spokes = {
      owner: userId,
      brain: "mistral-7B",
      description: spokeDefaults.description || "",
      expectedOutput: "",
      bibliotheca: "None",
      context: spokeDefaults.context || "",
      startingMessage: spokeDefaults.startingMessage || "",
      goal: spokeDefaults.goal || "",
      qa: "",
      language: "EN",
      color: spokeDefaults.color || "#2E012E",
      selectedStyle: "robot",
      selectedRobotAnimationIndex: 0,
      imageUrl: "",
      name: randomNameGenerator(),
      personality: spokeDefaults.personality || "",
      template: true,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    await setDoc(docRef, spokes);
    console.log("Returning spokeId", spokeId);
    return spokeId;
  }
  return spokeId;
}

export async function retrieveSpoke({ spokeId }) {
  console.log("Retrieving spokesman", spokeId);
  const userId = auth.currentUser.uid;
  try {
    const spoke = await sendAction({
      actionId: "retrieve-spoke",
      args: { author: userId, spokeId: spokeId, brain: undefined },
      user: userId,
    });

    return spoke.payload;
  } catch (e) {
    console.error("Error loading spoke: ", e);
    return {};
  }
}

export async function fetchSpokesFromFirebase() {
  try {
    const userId = auth.currentUser.uid;
    // Fetch moods from the public collection
    const publicSpokesCol = collection(db, "publicTemplates");
    const publicSpokesSnapshot = await getDocs(publicSpokesCol);
    const publicSpokes = publicSpokesSnapshot.docs.map((doc) => ({
      origin: "public",
      id: doc.id,
      data: doc.data(),
      template: true,
    }));

    // Fetch moods from the user-specific collection
    const userSpokesCol = collection(db, "users", userId, "spokes");
    const userSpokesSnapshot = await getDocs(userSpokesCol);
    const userSpokes = userSpokesSnapshot.docs.map((doc) => ({
      origin: "user",
      id: doc.id,
      data: doc.data(),
      template: doc.data()["template"],
    }));

    // Combine public and user-specific moods
    const spokes = [...publicSpokes, ...userSpokes];
    //console.log("Loaded spokes:", spokes);
    return spokes;
  } catch (error) {
    // Handle any errors that may occur
    console.error("Error fetching spokes:", error);
    return [];
  }
}

export async function fetchSpoke({ spokeId }) {
  const userId = auth.currentUser.uid;
  try {
    const configPath = `users/${userId}/spokes/`;
    const docRef = doc(collection(db, configPath), `${spokeId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        selectedRobotAnimationIndex:
          docSnap.data().selectedRobotAnimationIndex ?? 0,
      };
    } else {
      console.log("No such document!");
      return {};
    }
  } catch (error) {
    console.error("Error loading spokesman:", error);
    return {};
  }
}

export async function listUserSpokes() {
  try {
    const userId = auth.currentUser.uid;
    const res = await sendAction({
      actionId: "list-spokes",
      args: { owner: userId, author: userId },
      user: userId,
    });
    return JSON.parse(res.payload.spokes);
  } catch (error) {
    console.error("Error listing spokes:", error);
    return [];
  }
}

export async function listActions() {
  try {
    const userId = auth.currentUser.uid;
    const res = await sendAction({
      actionId: "list-actions",
      args: { user: userId, author: userId },
      user: userId,
    });
    return res.payload.actions;
  } catch (error) {
    console.error("Error listing actions:", error);
    return [];
  }
}
