<template>
  <v-card class="elevation-12 rounded-xl pb-6">
    <v-card-text class="py-0">
      <v-form @submit.prevent="handleRecoverPassword">
        <v-card-title class="text-h5 font-weight-bold py-6"
          >{{ $t("auth.password-recover") }}
        </v-card-title>
        <v-alert
          v-if="errorMessage"
          type="error"
          dismissible
          colored-border
          icon="mdi-alert-circle-outline"
          outlined
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          v-model="email"
          label="Email"
          :rules="[rules.required, rules.validEmail]"
        />
        <v-row>
          <v-col cols="12">
            <v-btn
              type="submit"
              :loadibg="loading"
              :disabled="!email"
              color="button"
              >{{ $t("auth.send") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn to="/login" class="back-to-login">
              {{ $t(`auth.back-to-login`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      email: "",
      errorMessage: "",
      error: false,
      emailSending: false,
      loading: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 8 || "Min 8 characters",
        validEmail: (value) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(
            value
          ) || this.$t("auth.email-validation"),
      },
    };
  },
  methods: {
    async handleRecoverPassword() {
      try {
        this.loading = true;
        this.error = false;
        this.errorMessage = "";
        const auth = getAuth();
        console.log(`Recover password for ${this.email}`);
        await sendPasswordResetEmail(auth, this.email);
        alert("Password Reset Email Sent.");
        //this.$router.push("/home");
      } catch (error) {
        console.error("Recover password failed failed:", error);
        this.error = true;
        this.errorMessage = this.$t("auth.reset-password-failed");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.google-icon {
  margin-right: 6px;
  width: 18px;
  height: 18px;
}
</style>
