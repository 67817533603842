import { defineStore } from "pinia";
import { fetchNews } from "@/services/news";

export const useNewsStore = defineStore({
  id: "news",

  state: () => ({
    news: {},
    newsLoaded: false
  }),

  actions: {
    async fetchAllNews() {
      const res = await fetchNews();
      this.news = res;
      this.newsLoaded = true;
    },
  },
});
