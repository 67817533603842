import { getDocs, collection } from "firebase/firestore";
import { db } from "@/firebase";

export async function fetchNews() {
    try {
      // Fetch news
      const NewsCol = collection(db, 'news');
      const NewsSnapshot = await getDocs(NewsCol);
      const news = NewsSnapshot.docs.map(doc => doc.data());
      return news
    } catch (error) {
      // Handle any errors that may occur
      console.error('Error fetching news:', error);
      return [];
    }
  }