<template>
  <v-btn
    fab
    small
    fixed
    top
    right
    class="ma-4 elevation-24"
    color="primary"
    @click="toggleDrawer"
    style="position: fixed; top: -4px; left: 0px; z-index: 10000"
  >
  <IconComponent name="menu" />
  </v-btn>
  <v-navigation-drawer
    :width="220"
    class="pa-2 pt-8"
    style="box-shadow: -15px 0px 10px 15px #111; "
    color="primary"
    v-model="this.myDrawer"
  >
    <v-list class="d-flex flex-column" height="88%">
      <v-list-item
        v-for="item in menuItems"
        :key="item.name"
        @click="$router.push(item.path)"
      >
        <v-list-item-title color="primary">
          <IconComponent :name="item.icon" style="margin-bottom: -6px!important;margin-left: 4px;" />
          <span class="pl-4"><strong>{{ $t(item.name) }}</strong></span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <a
      href="https://yggdrasilai.org"
      rel="noopener"
      class="powered-by d-flex px-10 hoverable"
      style="white-space: nowrap"
    >
      {{ $t("lateral-menu.by-yggdrasil") }}
      <img class="icon" src="@/assets/yggdrasil.png" alt="Yggdrasil Icon" />
    </a>
  </v-navigation-drawer>
</template>

<script>
import { publicPages } from "@/router";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "LateralMenu",
  components: {
    IconComponent,
  },
  data() {
    return {
      myDrawer: this.drawer,
      menuItems: publicPages,
    };
  },
  methods: {
    toggleDrawer() {
      this.myDrawer = !this.myDrawer;
    },
  },
};
</script>

<style scoped>
.icon {
  height: 40px;
  width: auto;
  object-fit: contain;
  max-width: 100%;
  margin-top: -8px;
}
a {
  color: white;
}

.v-list-item {
  border-radius: 25px !important;
  justify-content: left;
  width: 100%;
  margin: 4px 0;
}

.v-list-item::after {
  border: none!important;
}

.powered-by {
  position: absolute;
  margin-top: 40px;
}
</style>
