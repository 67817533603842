<template>
  <v-container class="telegram-page" style="margin-bottom: 20vh">
    <v-row class="d-flex justify-space-between pa-4">
      <div class="align-start pa-2">
        <v-row align="center">
          <v-btn @click="$router.back()" icon>
            <IconComponent name="back" />
          </v-btn>
          <v-card-title class="text-primary font-weight-bold text-h4 text-left"
            >Slack
          </v-card-title>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pb-6 px-2">
          <v-card-title
            class="text-primary font-weight-bold text-h5 text-md-h4 text-left pt-6 px-md-8 pb-4"
            >{{ $t("integrationsPage.my-slack-spokes") }}</v-card-title
          >
          <v-row>
            <v-col class="pl-8 pl-md-12 py-6">
              <div class="d-flex text-left">
                <span class="mr-2 mt-2"
                  ><strong> {{ $t("integrationsPage.add-workspace") }}:</strong>
                </span>
                <a
                  href="https://slack.com/oauth/v2/authorize?client_id=132718710787.4480677923155&scope=channels:history,chat:write,groups:history,groups:read,im:history,im:read,channels:read,files:read&user_scope="
                  target="_blank"
                  class="hoverable"
                >
                  <v-tooltip activator="parent" location="bottom">
                    {{
                      $t("integrationsPage.add-workspace-instructions")
                    }}</v-tooltip
                  ><img
                    alt="Add to Slack"
                    height="40"
                    width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                /></a>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!slackWorkspacesLoaded" class="mx-2">
            <v-col>
              <v-progress-circular
                :size="50"
                :width="5"
                color="purple"
                class="my-4"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-expansion-panels
            model-value="myWorkspaces"
            v-for="wsKey in Object.keys(slackWorkspaces)"
            :key="wsKey"
            class="w-100 pa-2"
          >
            <v-expansion-panel value="" class="rounded-xl">
              <v-expansion-panel-title>
                <div class="d-flex align-center">
                  <v-img
                    contain
                    :src="slackLogo"
                    height="26px"
                    width="26px"
                    style="max-width: 26px"
                  ></v-img>
                  <span class="ml-4 text-body-1">
                    <strong>
                      {{ slackWorkspaces[wsKey].workspaceName }}</strong
                    ></span
                  >
                  <!--span>({{ wsKey }})</span-->
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <SpokesList
                  :spokes="slackSpokes.filter((s) => s.workspaceId === wsKey)"
                  :loadingSpokes="!spokesLoaded || !slackWorkspacesLoaded"
                  :integration="true"
                  integrationType="slack"
                  @updateDefaultSlackSpokes="updateDefaultSlackSpokes"
                />
                <div class="my-4">
                  <v-btn
                    @click="openAddChannelDialog(wsKey)"
                    color="button"
                    class="text-left"
                  >
                    <v-tooltip activator="parent" location="bottom">
                      {{
                        $t("integrationsPage.add-spoke-to-slack-channel")
                      }}</v-tooltip
                    >
                    {{ $t("integrationsPage.add-bot") }}
                  </v-btn>
                  <v-btn
                    color="button"
                    variant="outlined"
                    class="ml-2 hoverable"
                    @click="
                      (e) => {
                        openDeleteDialog(
                          (item = { wsKey }),
                          (type = 'workspace')
                        );
                      }
                    "
                    ><span style="text-transform: none !important">{{
                      $t("integrationsPage.delete-workspace")
                    }}</span>
                  </v-btn>
                </div>
                <v-dialog v-model="addChannelDialog" max-width="600px">
                  <v-card class="pa-4">
                    <v-btn
                      style="position: absolute; right: 6px; top: 2px"
                      icon
                      @click="this.addChannelDialog = false"
                      ><IconComponent name="close" />
                    </v-btn>
                    <v-card-title
                      class="text-primary text-wrap text-center mt-4"
                      >{{ $t("integrationsPage.add-spoke-to-slack-channel") }}:
                    </v-card-title>
                    <v-card-text class="text-primary"> </v-card-text>
                    <v-row class="pa-4 text-center">
                      <v-col>
                        <CreateSlackBot
                          :slackWorkspace="this.selectedItem"
                          @closeDialog="this.addChannelDialog = false"
                        />
                        <v-spacer></v-spacer>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="deleteDialog" max-width="600px">
                  <v-card class="pa-4">
                    <v-card-title class="text-primary text-wrap text-center"
                      >{{ deleteMessage }}
                    </v-card-title>
                    <v-card-text class="text-primary"> </v-card-text>
                    <v-row class="pa-4 text-center">
                      <v-col>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          variant="outlined"
                          class="mr-2"
                          @click="this.deleteDialog = false"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          color="primary"
                          :loading="loadingDelete"
                          @click="myDelete(this.selectedItem, this.deleteType)"
                          >{{ $t("delete") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useIntegrationsStore } from "@/stores/integrations";
import CreateSlackBot from "@/components/integrations/CreateSlackBot.vue";
import SpokesList from "@/components/SpokesList.vue";
import { fetchSpoke } from "@/services/spoke";
import SlackLogo from "@/assets/logos/Slack_logo.png";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "TelegramPage",
  components: {
    CreateSlackBot,
    SpokesList,
    IconComponent,
  },
  data() {
    return {
      slackLogo: SlackLogo,
      addChannelDialog: false,
      deleteDialog: false,
      selectedSpoke: null,
      selectedItem: null,
      deleteType: null,
      deleteMessage: "",
      loadingDelete: false,
      defaultSpokes: [],
    };
  },
  async created() {
    await this.updateDefaultSlackSpokes();
  },
  methods: {
    test() {
      console.log("this.slackSpokes: ", this.slackSpokes);
    },
    openAddChannelDialog(item) {
      this.selectedItem = item;
      this.addChannelDialog = true;
    },
    closeAddChannelDialog() {
      this.addChannelDialog = false;
    },
    openDeleteDialog(item, type) {
      if (type === "workspace") {
        this.deleteMessage = this.$t(
          "integrationsPage.confirm-remove-slack-workspace"
        );
      } else if (type === "channel") {
        this.deleteMessage = this.$t(
          "integrationsPage.confirm-remove-slack-channel"
        );
      }
      this.deleteType = type;
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    async updateDefaultSlackSpokes() {
      await this.fetchUserSlackWorkspaces();
      this.defaultSpokes = [];
      for (const w in this.slackWorkspaces) {
        let isDefaultSpokeInChannels = false;
        if (Object.keys(this.slackWorkspaces[w].channels).length === 0) {
          // add default Spoke if channels is empty
          const defaultSpoke = await fetchSpoke({
            spokeId: this.slackWorkspaces[w].defaultSpokeId,
          });
          this.defaultSpokes.push({
            data: defaultSpoke,
            botToken: this.slackWorkspaces[w].botToken,
            botUserId: this.slackWorkspaces[w].botUserId,
            channelId: "",
            slackWorkspace: this.slackWorkspaces[w].workspaceName,
            workspaceId: w,
            defaultWorkspaceSpoke: true,
          });
        } else {
          // add default Spoke if it's not in channels
          for (const c in this.slackWorkspaces[w].channels) {
            if (
              this.slackWorkspaces[w].channels[c].spokeId ===
              this.slackWorkspaces[w].defaultSpokeId
            ) {
              isDefaultSpokeInChannels = true;
              break;
            }
          }
          if (!isDefaultSpokeInChannels) {
            const defaultSpoke = await fetchSpoke({
              spokeId: this.slackWorkspaces[w].defaultSpokeId,
            });
            this.defaultSpokes.push({
              data: defaultSpoke,
              botToken: this.slackWorkspaces[w].botToken,
              botUserId: this.slackWorkspaces[w].botUserId,
              channelId: "",
              slackWorkspace: this.slackWorkspaces[w].workspaceName,
              workspaceId: w,
              defaultWorkspaceSpoke: true,
            });
          }
        }
      }
    },
    async myDelete(item, type) {
      this.loadingDelete = true;
      if (type === "workspace") {
        await this.deleteSlackWorkspace({
          workspace: item.wsKey,
        });
      } else if (type === "channel") {
        await this.deleteSlackChannel({
          workspace: item.wsKey,
          channel: item.channel,
        });
      }
      this.loadingDelete = false;
      this.deleteDialog = false;
      this.selectedItem = null;
    },
    ...mapActions(useIntegrationsStore, [
      "fetchUserSlackWorkspaces",
      "deleteSlackChannel",
      "deleteSlackWorkspace",
    ]),
  },
  computed: {
    slackSpokes: {
      get() {
        const bots = [];
        if (this.spokes?.length > 0) {
          for (const w in this.slackWorkspaces) {
            for (const c in this.slackWorkspaces[w].channels) {
              this.spokes.find((s) => {
                if (s.id === this.slackWorkspaces[w].channels[c].spokeId) {
                  bots.push({
                    ...s,
                    botToken: this.slackWorkspaces[w].botToken,
                    botUserId: this.slackWorkspaces[w].botUserId,
                    channelId: this.slackWorkspaces[w].channels[c].channelId,
                    slackWorkspace: this.slackWorkspaces[w].workspaceName,
                    workspaceId: w,
                    defaultWorkspaceSpoke:
                      this.slackWorkspaces[w].defaultSpokeId === s.id,
                  });
                }
              });
            }
          }
        }
        return [...bots, ...this.defaultSpokes];
      },
    },
    ...mapState(useSpokesStore, ["spokes", "spokesLoaded"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useIntegrationsStore, [
      "slackWorkspaces",
      "slackWorkspacesLoaded",
    ]),
  },
};
</script>

<style></style>
