<template>
  <v-container class="telegram-page" style="margin-bottom: 20vh">
    <v-row class="d-flex justify-space-between pa-4">
      <div class="align-start pa-2">
        <v-row align="center">
          <v-btn @click="$router.back()" icon>
            <IconComponent name="back" />
          </v-btn>
          <v-card-title class="text-primary font-weight-bold text-h4 text-left"
            >Telegram</v-card-title
          >
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CreateTelegramBot />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels model-value="mySpokes">
          <v-expansion-panel value="mySpokes" class="rounded-xl">
            <v-expansion-panel-title>
              <span
                class="text-primary font-weight-bold text-h5 text-md-h4 text-left pa-2 px-md-4"
                >{{ $t("integrationsPage.my-telegram-spokes") }}</span
              >
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <SpokesList
                :spokes="telegramSpokes"
                :loadingSpokes="!spokesLoaded || !telegramBotsLoaded"
                :integration="true"
                integrationType="telegram"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useIntegrationsStore } from "@/stores/integrations";
import CreateTelegramBot from "@/components/integrations/CreateTelegramBot.vue";
import SpokesList from "@/components/SpokesList.vue";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "TelegramPage",
  components: {
    CreateTelegramBot,
    SpokesList,
    IconComponent,
  },
  computed: {
    telegramSpokes: {
      get() {
        const bots = [];
        if (this.spokes?.length > 0) {
          for (const t in this.telegramBots) {
            this.spokes.find((s) => {
              if (s.id === this.telegramBots[t].spokeId) {
                bots.push({
                  ...s,
                  botToken: this.telegramBots[t].botToken,
                  botId: t,
                });
              }
            });
          }
        }

        return bots;
      },
    },
    ...mapState(useSpokesStore, ["spokes", "spokesLoaded"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useIntegrationsStore, ["telegramBots", "telegramBotsLoaded"]),
  },
};
</script>

<style></style>
