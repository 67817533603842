<template>
  <v-container class="spokes-page">
    <v-row>
      <v-col cols="12">
        <v-expansion-panels model-value="myBibliothecas">
          <v-expansion-panel value="myBibliothecas" class="rounded-xl">
            <v-expansion-panel-title>
              <v-row class="d-flex justify-space-between px-4 mt-2">
                <div class="align-start mb-4 pa-2">
                  <v-row align="center">
                    <v-card-title
                      class="text-primary font-weight-bold text-h4 text-left"
                    >
                      Bibliothecas
                    </v-card-title>
                  </v-row>
                </div>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row align="center" class="pt-2">
                <v-col cols="12" md="11">
                  <v-text-field
                    :label="$t(`search`) + ` Bibliotheca`"
                    variant="solo"
                    v-model="bibliothecaSearchInput"
                    hide-details
                    rounded
                  >
                    <template v-slot:prepend-inner>
                      <IconComponent name="search" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="1">
                  <v-btn
                    :loading="loadingCreateBibliotheca"
                    icon
                    size="small"
                    color="button"
                    @click="
                      (e) => {
                        goToCreateBibliotheca();
                        e.stopPropagation();
                      }
                    "
                    ><v-tooltip activator="parent" location="bottom">
                      {{ $t("bibliothecas.create-one") }}</v-tooltip
                    >
                    <IconComponent name="add" />
                  </v-btn>
                </v-col>
              </v-row>
              <v-progress-circular
                v-if="!bibliothecasLoaded"
                :size="50"
                :width="5"
                color="purple"
                class="my-10"
                indeterminate
              ></v-progress-circular>
              <v-row>
                <v-col
                  ><h4
                    style="margin-top: 20px; margin-bottom: 10px"
                    v-if="bibliothecasLoaded && bibliothecas.length === 0"
                  >
                    {{ $t("bibliothecas.empty") }}
                  </h4>
                  <v-btn
                    v-if="bibliothecasLoaded && bibliothecas.length === 0"
                    color="button"
                    :loading="loadingCreateBibliotheca"
                    @click="goToCreateBibliotheca"
                    >{{ $t("bibliothecas.create-one") }}</v-btn
                  >
                </v-col>
              </v-row>
              <BibliothecasList
                v-if="bibliothecasLoaded"
                :loading="loadingCreateBibliotheca"
                :bibliothecas="filteredBibliothecas"
              />
              <v-pagination
                v-model="currentBibliothecasPage"
                :disabled="bibliothecaSearchInput.length > 0"
                :length="bibliothecasPageCount"
                rounded="circle"
              ></v-pagination>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBibliothecasStore } from "@/stores/bibliothecas";
import { useUserStore } from "@/stores/user";
import BibliothecasList from "@/components/BibliothecasList.vue";
import { retrieveBibliotheca } from "@/services/bibliotheca";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "BibliothecasPage",
  components: {
    BibliothecasList,
    IconComponent,
  },
  data() {
    return {
      bibliothecaSearchInput: "",
      bibliothecasList: [],
      loadingCreateBibliotheca: false,
      itemsPerPage: 6,
      currentBibliothecasPage: 1,
      timeoutId: "",
      timeoutId2: "",
    };
  },
  mounted() {
    //this.fetchUserBibliothecas();
    this.timeoutId3 = setTimeout(() => {
      this.fetchUserBibliothecas();
    }, 60000);
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.timeoutId2);
  },
  computed: {
    bibliothecasPageCount() {
      if (this.bibliothecasLoaded) {
        return Math.ceil(this.bibliothecas.length / this.itemsPerPage);
      } else {
        return 0;
      }
    },
    filteredBibliothecas() {
      if (this.bibliothecasLoaded) {
        return this.bibliothecas
          .filter((bibliotheca) =>
            bibliotheca.name
              .toLowerCase()
              .includes(this.bibliothecaSearchInput.toLowerCase())
          )
          .slice(
            (this.currentBibliothecasPage - 1) * this.itemsPerPage,
            this.currentBibliothecasPage * this.itemsPerPage
          );
      } else {
        return [];
      }
    },
    ...mapState(useBibliothecasStore, ["bibliothecas", "bibliothecasLoaded"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    async goToCreateBibliotheca() {
      try {
        this.loadingCreateBibliotheca = true;
        const bibliothecaId = await retrieveBibliotheca({
          owner: this.userData.userId,
          bibliotheca: undefined,
        });
        this.$router.push({
          name: "EditBibliotheca",
          params: { bibliothecaId: bibliothecaId },
        });
        this.loadingCreateBibliotheca = false;
      } catch (e) {
        console.log(e);
        this.loadingCreateBibliotheca = false;
      }
    },
    ...mapActions(useBibliothecasStore, ["fetchUserBibliothecas"]),
  },
};
</script>

<style scoped>
.spokes-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-bottom: 20vh;
}
</style>
