<template>
  <v-app-bar color="primary" dense>
    <v-spacer></v-spacer>
    <div class="d-flex align-center px-2">
      <router-link to="/">
        <img
          class="icon"
          src="@/assets/SPOKES_logo_SOLO_NUBE.png"
          alt="Spokes App Icon"
        />
      </router-link>
    </div>
    <v-spacer></v-spacer>

    <v-menu :close-on-content-click="false" :location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          icon
          color="white"
          v-bind="props"
          @click="toggleAccountMenu = !toggleAccountMenu"
          style="position: absolute; right: 10px"
        >
          <v-avatar :size="36">
            <v-img
              v-if="userData.photoUrl"
              :src="userData.photoUrl"
              width="36"
              height="36" />
            <IconComponent name="account" color="white" :size="36"
          /></v-avatar>
        </v-btn>
      </template>
      <v-list style="min-width: 220px" class="py-0">
        <v-list-item :key="index">
          <v-list-item-title style="font-size: 12px">
            <v-row align="center">
              <v-col cols="4">
                <v-avatar :size="32">
                  <v-img
                    v-if="userData.photoUrl"
                    :src="userData.photoUrl"
                    width="32"
                    height="32" />
                  <IconComponent v-else name="account" :size="32"
                /></v-avatar>
              </v-col>

              <v-col
                align="left"
                style="padding-left: 0; margin-left: -10px"
                cols="8"
                ><v-row no-gutters style="margin-bottom: -8px">
                  <v-col>
                    {{
                      userData.displayName
                        ? userData.displayName
                        : userData.email
                    }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    ><router-link
                      to="/buy-credits"
                      class="hoverable font-weight-regular text-black"
                      ><v-tooltip activator="parent" location="bottom">
                        {{ $t("buy-credits") }} </v-tooltip
                      >{{ userData.credits }} credits</router-link
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <!--v-list-item-title>{{ $t("profile") }}</v-list-item-title-->
        <LanguageSwitcher class="language-switcher" />
        <v-divider></v-divider>
        <v-list-item @click="() => {}" :key="index">
          <a
            href="https://github.com/yggdrasil-ai/docs"
            target="_blank"
            class="text-black"
          >
            <v-list-item-title>
              {{ $t("top-menu.documentation") }}
            </v-list-item-title>
          </a>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logout">
          <v-list-item-title>{{ $t("top-menu.logout") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { isAuthenticated, logOut } from "@/services/user";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher.vue";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "TopBar",
  components: {
    IconComponent,
    LanguageSwitcher,
  },
  data() {
    return {
      toggleAccountMenu: false,
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
    async logout() {
      await logOut();
      this.$router.push("/Login");
    },
  },
  computed: {
    isLoggedIn() {
      return isAuthenticated();
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
};
</script>

<style scoped>
.icon {
  height: 34px;
  width: auto;
  object-fit: contain;
  max-width: 100%;
}
a {
  text-decoration: none;
  color: white;
}

.v-list-item-title {
  padding: 8px;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
}
</style>
