<template>
<stripe-pricing-table
          :pricing-table-id="stripeTableId"
          :publishable-key="stripePublishableKey"
        >
        </stripe-pricing-table>

</template>

<script>
export default {
  name: "StripeTable",
  data() {
    return {
      loading: false,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  computed: {
    stripeTableId() {
      return this.$i18n.locale === "es"
        ? process.env.VUE_APP_STRIPE_TABLE_ID
        : process.env.VUE_APP_STRIPE_TABLE_ID_EN;
    },
  },
};
</script>