<template>
  <v-container
    class="container pa-3 rounded"
    :class="{ open: !loading, shadow: !loading }"
    fluid
  >
    <v-card>
      <div v-if="loading">
        <v-progress-circular indeterminate color="purple"></v-progress-circular>
      </div>
      <div v-if="!loading" class="conversation-page">
        <v-card-title v-if="!embed" class="text-primary">{{
          conversation.name
        }}</v-card-title>
        <v-card-subtitle v-if="!embed" class="text-primary mb-8">{{
          currentConversationId
        }}</v-card-subtitle>
        <div class="messages-container" ref="messagesContainer">
          <div v-for="(message, index) in conversation.messages" :key="index">
            <div
              class="mb-2 px-3 py-2 rounded-lg message"
              :class="[
                message.role === 'assistant'
                  ? 'bg-white text-dark float-left text-left'
                  : 'bg-primary text-white float-right text-right',
                message.role === 'assistant' ? 'mr-2' : 'ml-2',
              ]"
            >
              <vue-showdown
                class="markdown-container"
                :markdown="message.content"
                flavor="github"
              />
            </div>
            <div class="clearfix"></div>
          </div>
          <div v-if="sendingMessage">
            <div
              class="mb-2 px-3 py-2 rounded-lg message bg-white text-dark float-left text-left mr-2"
            >
              <div class="loading">
                <span>·</span><span>·</span><span>·</span>
              </div>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="10">
            <v-textarea
              v-model="userMessage"
              @keydown.enter.prevent="
                ($event.shiftKey && $event.keyCode === 13) || sendUserMessage()
              "
              @keydown.shift.enter="$event.target.value += '\n'"
              placeholder="Type your message here..."
              :disabled="sendingMessage"
              rows="2"
              auto-grow
            ></v-textarea>
          </v-col>
          <v-col cols="2" class="d-flex flex-column align-center">
            <div
              v-if="sendingMessage"
              class="d-flex align-center justify-center flex-grow-1"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <template v-else>
              <v-btn @click="sendUserMessage" class="mb-2">Send</v-btn>
              <v-btn @click="closeChat" class="bg-primary">Close</v-btn>
            </template>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import {
  sendMessage,
  createConversation,
  deleteConversation,
} from "@/services/conversation";
import { getAuth } from "firebase/auth";
import { VueShowdown } from "vue-showdown";

export default {
  name: "ConversationBot",
  components: {
    VueShowdown,
  },
  props: {
    conversationId: String,
    owner: String,
    spokeId: String,
    spokeUser: String,
    brain: String,
    embed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      valid: true,
      currentConversationId: `${this.owner}.${this.currentOwner}.${this.conversationId}`,
      filledOwner: this.owner,
      conversationKey: 0,
      retryCount: 0,
      userMessage: "",
      sendingMessage: false,
      conversation: {
        name: "",
        messages: [],
        spokeId: "",
        brain: "",
        prompt: "",
        spokeUser: "",
        user_id: "",
      },
    };
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.userEmail = user.email;
      this.user_id = user.uid;
      if (!this.filledOwner) {
        this.filledOwner = this.user_id;
      }
    } else {
      this.$router.push("/");
    }
    if (this.conversationId && this.spokeId) {
      const shortId = this.conversationId;
      this.currentConversationId = `${this.filledOwner}.${this.spokeId}.${shortId}`;
      await this.fetchConversationData();
    } else if (this.spokeUser && this.spokeId && this.brain) {
      await this.myCreateConversation();
    } else {
      console.error("Missing required query parameters or conversationId");
    }
  },
  mounted() {
    this.handleRetry();
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    handleRetry() {
      if (this.currentConversationId) {
        const db = this.$db;
        console.log(`Fetching: ${this.currentConversationId}`);
        const [user, spoke, conversation] =
          this.currentConversationId.split(".");
        const conversationRef = doc(
          db,
          "users",
          user,
          "spokes",
          spoke,
          "conversations",
          conversation
        );
        onSnapshot(conversationRef, (doc) => {
          this.conversation = doc.data();
          if (this.conversation === undefined) {
            console.log("conversation undefined");
            setTimeout(() => {
              this.retryCount++;
              this.$nextTick(() => {
                this.handleRetry();
              });
            }, 1000); // Wait for 1 second before retrying
          } else {
            console.log("Conversation data:", this.conversation);
            this.loading = false;
            this.scrollToBottom();
          }
        });
      } else {
        console.log("currentConversationId undefined");
        if (this.retryCount < 5) {
          // Limit the number of retries
          setTimeout(() => {
            this.retryCount++;
            this.$nextTick(() => {
              this.handleRetry();
            });
          }, 1000); // Wait for 1 second before retrying
        } else {
          // Show an error message or navigate to another page
        }
      }
    },

    async fetchConversationData() {
      const db = this.$db;
      try {
        const [user, spoke, conversation] =
          this.currentConversationId.split(".");
        const conversationRef = doc(
          db,
          "users",
          user,
          "spokes",
          spoke,
          "conversations",
          conversation
        );
        const conversationSnapshot = await getDoc(conversationRef);

        if (conversationSnapshot.exists) {
          this.conversation = conversationSnapshot.data();
        } else {
          console.log("No such conversation!");
        }
      } catch (error) {
        console.error("Error fetching conversation data:", error);
      }
    },

    async myCreateConversation() {
      try {
        const shortId = Date.now();
        const generatedConversationId = `${this.filledOwner}.${this.spokeId}.${shortId}`;

        await createConversation({
          author: "#frontendAgent#",
          channel: `FRONTEND.${generatedConversationId}`,
          spokeUser: this.spokeUser,
          brain: this.brain,
          spokeId: this.spokeId,
          forUser: this.user_id,
          conversationId: shortId,
          owner: this.user_id,
          type: "frontGenerated",
        });
        this.currentConversationId = generatedConversationId;
        this.$router.push({
          name: "ChatPage",
          params: { conversationId: shortId },
          query: { owner: this.user_id, spokeId: this.spokeId },
        });
      } catch (error) {
        console.error("Error creating conversation:", error);
      }
    },

    async closeChat() {
      deleteConversation({
        conversationId: this.currentConversationId,
        author: this.user_id,
      });
      if (this.embed) {
        this.$emit("close");
      } else {
        this.$router.push({ name: "ManageSpokes" });
      }
    },

    async sendUserMessage() {
      if (!this.userMessage) {
        return;
      }
      this.sendingMessage = true;
      const message = {
        author: this.user_id,
        content: this.userMessage,
        role: "user",
      };
      console.log("Sending message:", message);

      try {
        this.conversation.messages.push(message);

        const conversationId = this.currentConversationId;
        const payload = message.content;
        this.userMessage = "";
        await sendMessage({
          conversationId,
          message: payload,
          user: this.user_id,
        });
        this.sendingMessage = false;
      } catch (error) {
        console.error("Error sending message:", error);
        this.userMessage = "ERROR";
        this.sendingMessage = false;
      }
    },
  },
};
</script>

<style scoped>
.loading span {
  display: inline-block;
  margin-right: 2px;
  opacity: 0;
  animation: loading 1s infinite;
}

.loading span:nth-child(1) {
  animation-delay: 0s;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.conversation-page {
  max-width: 80%;
  margin: 0 auto;
}
.widget-container {
  max-width: 400px;
  margin: 0 auto;
}
.messages-container {
  max-height: 60vh;
  overflow-y: auto;
}
.message {
  word-break: break-word;
  max-width: 85%;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.markdown-container :deep(pre) {
  background-color: #e8e8e8;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
}

.markdown-container :deep(table) {
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.markdown-container :deep(table) th,
.markdown-container :deep(table) td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
}

.markdown-container :deep(ul),
.markdown-container :deep(ol) {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.markdown-container :deep(li) {
  margin-bottom: 0.5rem;
}

.markdown-container :deep(ul) ul,
.markdown-container :deep(ul) ol,
.markdown-container :deep(ol) ul,
.markdown-container :deep(ol) ol {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/* Headings */
.markdown-container :deep(h1),
.markdown-container :deep(h2),
.markdown-container :deep(h3),
.markdown-container :deep(h4),
.markdown-container :deep(h5),
.markdown-container :deep(h6) {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-container :deep(blockquote) {
  padding-left: 1rem;
  border-left: 3px solid #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}

/* Inline code */
.markdown-container :deep(code) {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  font-size: 0.9em;
}

/* Links */
.markdown-container :deep(a) {
  color: #3498db;
  text-decoration: none;
}

.markdown-container :deep(a:hover) {
  text-decoration: underline;
}

/* Horizontal rule */
.markdown-container :deep(hr) {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
