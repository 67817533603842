<template>
  <v-dialog
    :value="confirmationDialog"
    @input="$emit('input', $event)"
    max-width="600px"
  >
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center">{{
        message
      }}</v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="close"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="button" @click="action">{{ actionMessage }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    confirmationDialog: {
      type: Boolean,
      default: () => false,
    },
    message:{
      type: String
    },
    actionMessage:{
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close-dialog");
    },
    action() {
      this.$emit("action");
    },
  },
};
</script>
