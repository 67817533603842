import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDfF7cOhf4wLTHtFHrkm4zJ-iO7mX38_-k",
  authDomain: "yggdrasil-ai-hermod.firebaseapp.com",
  projectId: "yggdrasil-ai-hermod",
  storageBucket: "yggdrasil-ai-hermod.appspot.com",
  messagingSenderId: "874257179571",
  appId: "1:874257179571:web:43f40926ed7aec2a4c21c6",
  measurementId: "G-LYNS3GNM56",
};

// aux firebase app for file storage
const firebaseStorageAppConfig = {
  apiKey: "AIzaSyDL41JGwT60oSHBv9p7exQWuKhCUYjT7IY",
  authDomain: "spokes-images.firebaseapp.com",
  projectId: "spokes-images",
  storageBucket: "spokes-images.appspot.com",
  messagingSenderId: "608817560943",
  appId: "1:608817560943:web:87e853aa8ac2e412acbc9a",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) return null;
  return await user.getIdToken();
};
const db = getFirestore(firebaseApp);

const firebaseImagesStorageApp = initializeApp(firebaseStorageAppConfig, 'spokes-images');
const storage = getStorage(firebaseImagesStorageApp)

export { firebaseApp, auth, getIdToken, db, storage };
