<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="elevation-12 pa-8 px-6 px-md-12 pb-16">
            <v-row>
              <v-col>
                <h1 class="display-1 text-left text-primary">
                  {{ $t("configuration") }}
                </h1>
                <div style="border-bottom: 0.5px solid gray"></div>
              </v-col>
            </v-row>
            <!-- API Key Section -->
            <v-row>
              <v-col>
                <v-row align="center">
                  <v-col cols="12" md="10">
                    <v-text-field
                      v-model="userData.apiKey"
                      :type="apiKeyVisible ? 'text' : 'password'"
                      label="API Key"
                      readonly
                      class="mx-0 mx-md-4 mt-4"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      @click="apiKeyVisible = !apiKeyVisible"
                      color="transparent"
                      icon
                      ><IconComponent
                        color="purple"
                        :name="apiKeyVisible ? 'hide' : 'show'"
                      />
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="transparent"
                      icon
                      @click="copyAPIKey"
                      ><IconComponent color="purple" name="copy"
                    /></v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Subscription and Usage Section -->
            <v-row>
              <v-col>
                <v-card class="elevation-2 pa-6 pa-md-8">
                  <h2 class="text-primary text-left mb-4">
                    {{ $t("config.sub-and-use") }}:
                  </h2>
                  <v-row class="mx-0 mx-md-4 py-4">
                    <v-col
                      class="d-flex justify-center text-left text-md-center"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $t("config.subscription") }}:</h4>
                      <p class="ml-2">{{ this.subscription }}</p>
                    </v-col>
                    <v-col
                      class="d-flex justify-center text-left text-md-center"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $t("config.spokes-count") }}:</h4>
                      <p class="ml-2">{{ spokesCount }}</p>
                    </v-col>
                    <v-col
                      class="d-flex justify-center text-left text-md-center"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $t("config.conversation-count") }}:</h4>
                      <p class="ml-2">{{ userData.conversationCount }}</p>
                    </v-col>
                    <v-col
                      class="d-flex justify-center text-left text-md-center"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $t("config.credits") }}:</h4>
                      <p class="ml-2">{{ userData.credits ?? "∞" }}</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <router-link to="/buy-credits">
                  <v-btn color="primary">
                    {{ $t("buy-credits") }}
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="elevation-12 pa-8 px-md-12 my-6">
        <v-row>
          <v-col>
            <h1 class="display-1 text-left text-primary">
              {{ $t("config.open-conversations") }}
            </h1>
            <div style="border-bottom: 0.5px solid gray"></div>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12">
            <v-text-field
              :label="$t(`config.search-conversation`)"
              variant="solo"
              v-model="conversationsSearchInput"
              hide-details
              rounded
            >
              <template v-slot:prepend-inner>
                <IconComponent name="search" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <ConversationsList
              :conversations="filteredConversations"
              :loadingConversations="!conversationsLoaded"
            />
          </v-col>
          <v-col>
            <v-pagination
              v-model="currentConversationsPage"
              :disabled="conversationsSearchInput.length > 0"
              :length="conversationsPageCount"
              rounded="circle"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbarVisible" top color="success" timeout="2000">
      {{ $t("config.api-key-copied") }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useConversationsStore } from "@/stores/conversations";
import ConversationsList from "@/components/ConversationsList.vue";
import IconComponent from "@/components/IconComponent.vue";
import { getPlan } from "@/utils/stripe";

export default {
  name: "UserConfigPage",
  components: {
    ConversationsList,
    IconComponent,
  },
  data() {
    return {
      apiKeyVisible: false,
      snackbarVisible: false,
      conversationsSearchInput: "",
      itemsPerPage: 6,
      currentConversationsPage: 1,
    };
  },
  mounted(){
    this.fetchOpenConversations();
  },
  methods: {
    async copyAPIKey() {
      await navigator.clipboard.writeText(this.userData.apiKey);
      this.snackbarVisible = true;
    },
    getConversationData(ids) {
      const indexOfFirstDot = ids.indexOf(".");
      const indexOfSecondDot = ids.indexOf(".", indexOfFirstDot + 1);
      const userId = ids.slice(0, indexOfFirstDot);
      const spokeId = ids.slice(indexOfFirstDot + 1, indexOfSecondDot);
      const conversationId = ids.slice(indexOfSecondDot + 1);
      const spokeName = this.spokes.find((s) => s.id === spokeId)?.data?.name;
      return { userId, spokeId, conversationId, spokeName };
    },
    ...mapActions(useConversationsStore, ["fetchOpenConversations"]),
  },
  computed: {
    conversationsPageCount() {
      if (this.conversationsLoaded) {
        return Math.ceil(this.openConversations.length / this.itemsPerPage);
      } else {
        return 0;
      }
    },
    filteredConversations() {
      if (this.conversationsLoaded) {
        return this.openConversations
          .filter((c) =>
            this.getConversationData(c)
              .spokeName?.toLowerCase()
              .includes(this.conversationsSearchInput?.toLowerCase())
          )
          .slice(
            (this.currentConversationsPage - 1) * this.itemsPerPage,
            this.currentConversationsPage * this.itemsPerPage
          );
      } else {
        return [];
      }
    },
    subscription() {
      return getPlan(this.userData?.plan);
    },
    ...mapState(useSpokesStore, ["spokes", "spokesLoaded", "spokesCount"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useConversationsStore, [
      "openConversations",
      "openConversationsCount",
      "conversationsLoaded",
    ]),
  },
};
</script>

<style scoped></style>
