<template>
  <section class="buycredits-page pb-16 pb-md-12">
    <v-row>
      <v-col>
        <v-card-title class="text-primary font-weight-bold text-h4 mb-6">{{
          $t("plan-upgrade")
        }}</v-card-title>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        ><StripeTable />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import StripeTable from '@/components/StripeTable.vue';
export default {
  name: "IntegrationsPage",
  components:{
    StripeTable
  },
  data() {
    return {
      loading: false,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  computed: {
    stripeTableId() {
      return this.$i18n.locale === "es"
        ? process.env.VUE_APP_STRIPE_TABLE_ID
        : process.env.VUE_APP_STRIPE_TABLE_ID_EN;
    },
  },
};
</script>

<style scoped>
.buycredits-page {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  min-height: 100vh;
  align-items: left;
  justify-content: left;
  background-color: white;
}
</style>
