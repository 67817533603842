<template>
  <v-app id="app" :class="appClass">
    <top-bar v-if="!isIframeLayout && isLoggedIn"></top-bar>
    <lateral-menu v-if="!isIframeLayout && isLoggedIn"></lateral-menu>
    <v-main class="pb-16 pb-md-0">
      <router-view></router-view>
      <iframe
        v-if="renderAppSpoke"
        id="spokes-iframe"
        :src="getUrl()"
        frameborder="0"
        scrolling="no"
        style="
          position: fixed;
          bottom: 10px;
          right: 10px;
          z-index: 9999;
          min-height: 120px;
          min-width: 120px;
          max-height: 70vh;
          max-width: 95vw;
          background-color: transparent;
        "
      >
      </iframe>
    </v-main>
  </v-app>
</template>

<script>
import { useHead } from "@unhead/vue";
import { mapActions, mapState } from "pinia";
import { useNewsStore } from "@/stores/news";
import { useIntegrationsStore } from "@/stores/integrations";
import { useBibliothecasStore } from "@/stores/bibliothecas";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useConversationsStore } from "@/stores/conversations";
import TopBar from "@/components/layout/TopBar.vue";
import LateralMenu from "./components/layout/LateralMenu.vue";
import { isAuthenticated } from "@/services/user";

export default {
  name: "App",
  components: {
    TopBar,
    LateralMenu,
  },
  created() {
    if (this.isLoggedIn) {
      this.fetchUserData();
      this.fetchUserSpokes();
      this.fetchAllNews();
      this.fetchOpenConversations();
      this.fetchUserBibliothecas();
      this.fetchUserSlackWorkspaces();
      this.fetchUserTelegramBots();
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://yggdrasil-ai-hermod.web.app/static/spokes-iframe-handler.js";
    script.async = true;
    document.body.appendChild(script);
  },
  setup() {
    useHead({
      // Can be static or computed
      title: `Spokes App`,
      meta: [
        {
          name: `slack-app-id`,
          content: `A04E4KXT54K`,
        },
      ],
    });
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    isIframeLayout() {
      return this.$route.name === "ConversationWidget";
    },
    renderAppSpoke() {
      return this.$route.name === "ChatPage"
        ? false
        : this.showAppSpoke ?? true;
    },
    isLoggedIn() {
      return isAuthenticated();
    },
    appClass() {
      if (this.$route.name === "ConversationWidget") {
        return "bg-grey-lighten-5 widget"; // set background color for ConversationWidget route
      } else {
        return "bg-grey-lighten-5"; // set default background color for other routes
      }
    },
    ...mapState(useUserStore, ["showAppSpoke"]),
  },
  methods: {
    getUrl() {
      const url =
        this.$i18n.locale === `es`
          ? `https://yggdrasil-ai-hermod.web.app/widget/conversation?apiKey=DcKnPLhE0NQIASrEYrMx6GG4Sd73-0fe51f90411e36ebb4be79f56f5fb0cf&spokeId=6gvtaruisdh`
          : `https://yggdrasil-ai-hermod.web.app/widget/conversation?apiKey=DcKnPLhE0NQIASrEYrMx6GG4Sd73-0fe51f90411e36ebb4be79f56f5fb0cf&spokeId=xibie5acqnk`;
      return url;
    },
    ...mapActions(useNewsStore, ["fetchAllNews"]),
    ...mapActions(useUserStore, ["fetchUserData"]),
    ...mapActions(useSpokesStore, ["fetchUserSpokes"]),
    ...mapActions(useConversationsStore, ["fetchOpenConversations"]),
    ...mapActions(useBibliothecasStore, ["fetchUserBibliothecas"]),
    ...mapActions(useIntegrationsStore, [
      "fetchUserSlackWorkspaces",
      "fetchUserTelegramBots",
    ]),
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

.widget {
  max-width: 800;
  max-height: 1000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgb(var(--v-theme-background));
}

.v-btn {
  text-transform: none !important;
}

.v-card {
  border-radius: 25px !important ;
  padding: 20px;
}

.v-btn.v-btn--disabled {
  background-color: transparent !important;
  color: gray !important;
  opacity: 0.5 !important;
}

.hoverable {
  transition: all 300ms ease-in-out;
  opacity: 100%;
  cursor: pointer;
}

.hoverable:hover {
  opacity: 80%;
}

a {
  text-decoration: none;
}
</style>
