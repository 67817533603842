import { defineStore } from "pinia";
import {
  addSpokeToTelegram,
  addSpokeToSlackChannel,
  setDefaultSpokeForWorkspace,
  deleteTelegramBot,
  deleteSlackWorkspace,
  deleteSlackChannel,
  listSlackWorkspaces,
  listTelegramBots,
} from "@/services/integrations";

export const useIntegrationsStore = defineStore({
  id: "integrations",

  state: () => ({
    telegramBots: {},
    slackWorkspaces: {},
    telegramBotsCount: 0,
    slackWorkspacesCount: 0,
    telegramBotsLoaded: false,
    slackWorkspacesLoaded: false,
  }),

  actions: {
    async fetchUserTelegramBots() {
      const res = await listTelegramBots();
      this.telegramBots = res;
      this.telegramBotsCount = res.length;
      this.telegramBotsLoaded = true;
      return;
    },
    async addTelegramBot({ spokeId, botToken }) {
      await addSpokeToTelegram({ spokeId, botToken });
      await this.fetchUserTelegramBots();
      this.telegramBotsCount += 1;
      return;
    },
    async deleteTelegramBot({ botId }) {
      await deleteTelegramBot({ botId });
      delete this.telegramBots[botId];
      await this.fetchUserTelegramBots();
      this.telegramBotsCount -= 1;
      return;
    },
    async fetchUserSlackWorkspaces() {
      const res = await listSlackWorkspaces();
      this.slackWorkspaces = res;
      this.slackWorkspacesCount = res?.length;
      this.slackWorkspacesLoaded = true;
      return;
    },
    async setDefaultSpokeForWorkspace({ spokeId, workspace, channel }) {
      await setDefaultSpokeForWorkspace({ spokeId, workspace, channel });
      await this.fetchUserSlackWorkspaces();
      this.slackWorkspacesCount += 1;
      return;
    },
    async addSlackWorkspace({ spokeId, workspace, channel }) {
      await addSpokeToSlackChannel({ spokeId, workspace, channel });
      await this.fetchUserSlackWorkspaces();
      this.slackWorkspacesCount += 1;
      return;
    },
    async deleteSlackWorkspace({ workspace }) {
      await deleteSlackWorkspace({ workspace });
      delete this.slackWorkspaces[workspace];
      await this.fetchUserSlackWorkspaces();
      this.slackWorkspacesCount -= 1;
      return;
    },
    async deleteSlackChannel({ workspace, channel }) {
      await deleteSlackChannel({ workspace, channel });
      delete this.slackWorkspaces[workspace];
      await this.fetchUserSlackWorkspaces();
      this.slackWorkspacesCount -= 1;
      return;
    },
  },
});
