export async function uploadToGcpBucket({ file, url }) {
  try {
    const response = await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: file
        }),
      }
    );
    const data = await response.json();

    if (response.status >= 400 && response.status < 600) {
      throw new Error(data.message || "Bad response from server");
    }

    return data;
  } catch (e) {
    alert(e);
    return e;
  }
}

export async function uploadFormDataToGcpBucket({ formData, url }) {
  try {
    const response = await fetch(
      url,
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();

    if (response.status >= 400 && response.status < 600) {
      throw new Error(data.message || "Bad response from server");
    }

    return data;
  } catch (e) {
    alert(e);
    return e;
  }
}
