<template>
  <v-container class="px-0">
    <v-list>
      <v-list-item
        class="elevation-2 rounded-xl px-4 ma-2"
        v-for="template in this.filteredTemplates"
        :key="template.id"
      >
        <v-row align="center">
          <v-col cols="5">
            <v-list-item-subtitle class="origin">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip
                    color="info"
                    style="height: auto; white-space: normal"
                    class="pa-1"
                    v-on="on"
                  >
                    {{ template.origin }}
                  </v-chip>
                </template>
              </v-menu>
            </v-list-item-subtitle>
            <v-list-item-title class="text-primary text-wrap">
              {{ template.data.name ? template.data.name : "Template Name" }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-primary"
              >{{ template.data.language }} -
              {{ template.data.description }}</v-list-item-subtitle
            >
          </v-col>
          <v-col cols="7">
            <v-list-item-title class="text-right pa-6 text-wrap">
              <v-btn
                color="transparent"
                icon
                size="small"
                @click="goToEditSpokes(template)"
              >
                <IconComponent color="purple" name="edit" />
              </v-btn>
              <v-btn
                v-if="template.origin !== 'public'"
                size="small"
                icon
                color="transparent"
                @click="openDeleteDialog(template)"
              >
                <IconComponent color="red" name="delete"
              /></v-btn>
            </v-list-item-title>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-container>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary">{{
        $t("delete-confirmation")
      }}</v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="outlined"
          class="mr-2"
          @click="this.deleteDialog = false"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          color="primary"
          @click="myDeleteTemplate(this.selectedForDelete)"
          >{{ $t("delete") }}</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import {
  deleteSpokeFromFirebase,
  retrievePublicTemplate,
} from "@/services/spoke";
import { useUserStore } from "@/stores/user";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "SpokesList",
  components: {
    IconComponent,
  },
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deletedTemplates: [],
      deleteDialog: false,
      selectedForDelete: null,
    };
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(
        (template) => !this.deletedTemplates.includes(template.id)
      );
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    openDeleteDialog(template) {
      this.deleteDialog = true;
      this.selectedForDelete = template;
    },
    myDeleteTemplate(template) {
      this.deleteDialog = false;
      this.selectedForDelete = null;
      const templateId = template.id;
      deleteSpokeFromFirebase({ userId: this.userData.userId, id: templateId });
      this.deletedTemplates = this.deletedTemplates.concat([templateId]);
    },
    goToEditSpokes(template) {
      const templateId = template.id;
      if (template.origin == "public") {
        retrievePublicTemplate({
          userId: this.userData.userId,
          id: template.id,
        });
      }
      this.$router.push({ name: "EditSpoke", params: { spokeId: templateId } });
    },
  },
};
</script>
<style scoped>
.configurations-card {
  max-width: 500px;
}
.origin {
  font-size: 12px;
  text-align: left;
}
</style>
