<template>
    <v-container class="analytics-page">
      <v-row>
        <v-col>
          <v-card class="elevation-12 pa-4 mb-2">
            <v-row justify="end" align="center" class="my-5">
              <v-col cols="12" md="auto" class="text-right">
                <v-btn color="secondary" class="ma-2" @click="refreshData">
                  <svg-icon type="mdi" color="secondary" :path="refreshIcon"></svg-icon></v-btn>
                <v-btn color="primary" @click="downloadDataPopup">
                  <svg-icon type="mdi" color="primary" :path="downloadIcon"></svg-icon></v-btn>
              </v-col>
              <v-col cols="12">
                <h1 class="text-primary display-1 text-left">Analytics</h1>
              </v-col>
            </v-row>
            <iframe
              :src="dataStudioUrl"
              width="100%"
              height="1000"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </v-card>
          <v-card class="elevation-12 pa-4 mb-2">
            <v-card-title class="text-left text-primary">Conversation Explorer</v-card-title>
            <v-row>
                <v-col cols="10">
                    <v-text-field
                    label="Enter Conversation ID"
                    v-model="conversationId"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-btn color="primary" @click="fetchConversationFiles">Fetch Files</v-btn>
                </v-col>
            </v-row>
            <v-expansion-panels>          
              <v-expansion-panel v-if="conversationOutputs" class="text-primary" title="Output">
                <v-expansion-panel-text>
                  <v-card>
                    <v-card-text>
                      <v-list dense class="custom-output-list">
                        <v-list-item v-for="(value, key) in conversationOutputs" :key="key">
                          <v-list-item-title class="font-weight-bold text-primary">{{ key }}</v-list-item-title>
                          <v-list-item-subtitle class="text-primary">{{ value }}</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-text>              
              </v-expansion-panel>
              <v-expansion-panel v-if="conversationInfo" class="text-primary" title="Conversation">
                <v-expansion-panel-text>
                  <v-card class="mb-4">
                    <v-card-text>
                      <div class="messages-container" ref="messagesContainer">
                        <div
                          v-for="(message, index) in conversationInfo.messages"
                          :key="index" 
                        >
                          <div class="mb-2 px-3 py-2 rounded-lg message"
                          :class="[
                            message.role === 'assistant' ? 'bg-white text-dark float-left text-left' : 'bg-primary text-white float-right text-right',
                            message.role === 'assistant' ? 'mr-2' : 'ml-2',
                          ]">
                            <vue-showdown class="markdown-container" :markdown="message.content" flavor="github" />
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-primary headline">Download Data</v-card-title>
        <v-card-text>
          <v-text-field 
                type="date"
                v-bind="attrs"
                v-on="on"
                v-model="startDate"
                label="Start Date"
                :value="startDate"
          ></v-text-field>
          <v-text-field
                type="date"
                v-bind="attrs"
                v-on="on"
                v-model="endDate"
                label="End Date"
                :value="endDate"
          ></v-text-field>
          <v-select
            :items="spokeNames"
            v-model="selectedSpokeComputed"
            label="Select a spoke"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false">Close</v-btn>
          <v-btn color="secondary" text @click="downloadData">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { getAuth } from "firebase/auth";
  import { fetchFinishedConversation, cleanConversations, retrieveOutputs } from "@/services/conversation";
  import { fetchSpokesFromFirebase } from "@/services/spoke";
  import { VueShowdown } from 'vue-showdown';
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiDatabaseRefreshOutline, mdiDownloadBoxOutline } from '@mdi/js';
  
  export default {
    name: "AnalyticsPage",
    data() {
      return {
        dialog: false,
        menuStartDate: false,
        menuEndDate: false,
        startDate: null,
        endDate: null,
        spokeItems: [],
        selectedSpoke: null,
        downloadIcon: mdiDownloadBoxOutline,
        refreshIcon: mdiDatabaseRefreshOutline,
        dataStudioUrl: "",
        userId: "",
        conversationId: "",
        conversationInfo: "",
        conversationOutputs: "",
      };
    },
    components: {
      VueShowdown,
      SvgIcon,
    },
    async created() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        var params = {
          user: user.uid,
        };
        var paramsAsString = JSON.stringify(params);
        var encodedParams = encodeURIComponent(paramsAsString);
        this.dataStudioUrl = `https://lookerstudio.google.com/embed/reporting/89e442ea-ec8a-4247-8209-f4c03bab1527/page/S28PD?params=${encodedParams}`;
        console.log(`Data Studio URL: ${this.dataStudioUrl}`);
      } else {
        this.$router.push("/");
      }
    },
    computed: {
      spokeNames: {
        get() {
          return this.spokeItems.map((option) => option.data.name);
        },
      },
      selectedSpokeComputed: {
        get() {
          const selectedSpokeOption = this.spokeItems.find((option) => option.id === this.selectedSpoke);
          return selectedSpokeOption ? selectedSpokeOption.data.name : 'All';
        },
        set(newValue) {
          this.selectedSpoke = this.spokeItems.find((option) => option.data.name === newValue).id;
          console.log(this.selectedSpoke)
        },
      },
    },
    methods: {
        async fetchConversationFiles() {
          if (this.conversationId) {
            try {
              const conversationData = await fetchFinishedConversation({ conversationId: this.conversationId });
              this.conversationInfo = conversationData.conversation;
              this.conversationOutputs = conversationData.output;
              console.log(this.conversationOutputs);
            } catch (error) {
              console.error('Error fetching conversation data:', error);
              alert('Error fetching conversation data.');
            }
          } else {
            alert('Please enter a conversation ID.');
          }
        },
        refreshData() {
          cleanConversations({ author: this.userId, user: this.userId })
        },
        async downloadDataPopup() {
          this.spokeItems = await fetchSpokesFromFirebase();
          this.spokeItems.push({ id: "all", data: { name: "All" } });
          this.dialog = true;
        },
        async downloadData() {
          const spoke = this.selectedSpoke;
          const startDate = this.startDate ? this.startDate.replace(/-/g, '') : null;
          const endDate = this.endDate ? this.endDate.replace(/-/g, '') : null;
          this.dialog = false;
          console.log(
            `Downloading data for ${this.userId} from ${startDate} to ${endDate} for spoke ${spoke}`
          )
          await retrieveOutputs({ author: this.userId, user: this.userId, startDate, endDate, spoke });
        }
    },
  };
  </script>
  
  <style scoped>
  .analytics-page {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    height: 100vh;
  }
  .custom-output-list .v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-output-list .v-list-item:last-child {
  border-bottom: none;
}

.custom-output-list .v-list-item-content {
  padding: 12px 0;
}

.messages-container {
    max-height: 60vh;
    overflow-y: auto;
  }
  .message {
    word-break: break-word;
    max-width: 85%;
  }
  .clearfix::after {
    content: '';
    display: table;
    clear: both;
  }
  
  .markdown-container :deep(pre) {
    background-color: #e8e8e8;
    padding: 1rem;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  .markdown-container :deep(table) {
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  .markdown-container :deep(table) th,
  .markdown-container :deep(table) td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
  }

  .markdown-container :deep(ul),
.markdown-container :deep(ol) {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.markdown-container :deep(li) {
  margin-bottom: 0.5rem;
}

.markdown-container :deep(ul) ul,
.markdown-container :deep(ul) ol,
.markdown-container :deep(ol) ul,
.markdown-container :deep(ol) ol {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/* Headings */
.markdown-container :deep(h1),
.markdown-container :deep(h2),
.markdown-container :deep(h3),
.markdown-container :deep(h4),
.markdown-container :deep(h5),
.markdown-container :deep(h6) {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-container :deep(blockquote) {
  padding-left: 1rem;
  border-left: 3px solid #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}

/* Inline code */
.markdown-container :deep(code) {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  font-size: 0.9em;
}

/* Links */
.markdown-container :deep(a) {
  color: #3498db;
  text-decoration: none;
}

.markdown-container :deep(a:hover) {
  text-decoration: underline;
}

/* Horizontal rule */
.markdown-container :deep(hr) {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
  </style>
  
