<template>
  <div>
    <v-container v-if="loading || !spokesLoaded" class="spokes-editor">
      <v-row>
        <v-col cols="12"
          ><v-skeleton-loader
            class="mx-auto rounded-xl"
            elevation="12"
            height="100%"
            width="100%"
            v-if="loading || !spokesLoaded"
            type="article, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container class="spokes-editor">
        <v-row>
          <v-col cols="12">
            <v-card
              style="margin-bottom: 20vh"
              class="elevation-12 px-6 py-4 px-md-12 rounded-xl"
            >
              <v-form ref="form" v-model="valid" @submit.prevent="saveSpokeVue">
                <v-row class="d-flex justify-space-between pt-4">
                  <div class="align-start">
                    <v-row no-gutters>
                      <v-btn @click="$router.back()" icon>
                        <IconComponent name="back" />
                      </v-btn>
                      <v-card-title>{{ $t("edit") }}</v-card-title>
                    </v-row>
                  </div>
                  <v-btn
                    type="submit"
                    color="transparent"
                    :loading="savingForm"
                    icon
                    @click="saveAsTemplate = false"
                    ><v-tooltip activator="parent" location="bottom">
                      {{ $t("save") }}</v-tooltip
                    ><IconComponent color="purple" name="save" />
                  </v-btn>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-alert
                          v-if="errorMessage"
                          type="error"
                          dismissible
                          colored-border
                          outlined
                        >
                          {{ errorMessage }}
                        </v-alert>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12">
                            <v-card-title>{{ $t("style") }}</v-card-title>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="12">
                            <div style="height: 180px">
                              <div v-if="selectedStyle === `robot`">
                                <v-row align="center">
                                  <v-col cols="2"
                                    ><v-btn
                                      @click="changeRobotAnimation(-1)"
                                      icon
                                    >
                                      <IconComponent name="back" :size="40" />
                                    </v-btn> </v-col
                                  ><v-col cols="8">
                                    <Transition name="slide-fade">
                                      <RobotsComponent
                                        :index="
                                          selectedSpoke.selectedRobotAnimationIndex
                                        "
                                        :height="160"
                                        :width="160"
                                      />
                                    </Transition>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-btn
                                      @click="changeRobotAnimation(1)"
                                      icon
                                    >
                                      <IconComponent name="right" :size="40" />
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </div>
                              <div
                                v-if="selectedStyle === `image`"
                                class="hoverable"
                                @click="inputFile"
                              >
                                <v-row
                                  ><v-col>
                                    <IconComponent
                                      v-if="
                                        !selectedSpoke.imageUrl ||
                                        imageUploadedURL < 5
                                      "
                                      name="upload"
                                      style="cursor: pointer; color: purple"
                                      class="mt-10"
                                      :size="64"
                                    />
                                    <v-img
                                      v-else
                                      class="align-end"
                                      height="120"
                                      :src="imageUploadedURL"
                                      ><template v-slot:placeholder>
                                        <div
                                          class="d-flex align-center justify-center fill-height"
                                        >
                                          <v-progress-circular
                                            color="purple"
                                            indeterminate
                                          ></v-progress-circular>
                                        </div>
                                      </template>
                                    </v-img>
                                  </v-col>
                                </v-row>
                                <v-row
                                  ><v-col>
                                    <v-btn
                                      variant="outlined"
                                      size="small"
                                      :loading="uploadingImage"
                                      >{{ $t("upload-image") }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </div>
                              <input
                                type="file"
                                accept="image/*"
                                @change="uploadImage"
                                ref="file"
                                id="upload-image"
                                hidden
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              class="mr-1"
                              :class="{ active: selectedStyle === `robot` }"
                              color="gray"
                              @click="
                                () => {
                                  selectedStyle = 'robot';
                                }
                              "
                              icon
                              ><v-tooltip activator="parent" location="bottom">
                                {{ $t("select-robot") }}</v-tooltip
                              >
                              <IconComponent name="robot" />
                            </v-btn>
                            <v-btn
                              class="ml-1"
                              :class="{ active: selectedStyle === `image` }"
                              color="gray"
                              @click="
                                () => {
                                  selectedStyle = 'image';
                                }
                              "
                              icon
                              ><v-tooltip activator="parent" location="bottom">
                                {{ $t("upload-image") }}</v-tooltip
                              >
                              <IconComponent name="image" />
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <div>
                              <v-tooltip activator="parent" location="bottom">
                                {{ $t("widget-color") }}</v-tooltip
                              >
                              <label for="widgetColor"
                                ><strong>Color: </strong>
                              </label>
                              <input
                                style="cursor: pointer"
                                type="color"
                                id="widgetColor"
                                name="widgetColor"
                                v-model="widgetColor"
                              />
                            </div>
                          </v-col>
                          <!-- <v-col cols="12" md="6">
                            <v-select
                              :disabled="true"
                              :items="outputNameOptions"
                              v-model="selectedOutput"
                              label="Output Form"
                            ></v-select>
                          </v-col> -->
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              :label="$t(`name`)"
                              variant="solo-filled"
                              v-model="selectedSpoke.name"
                              :rules="required"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mt-4" cols="6">
                            <strong>Id: </strong> {{ spokeId }}
                          </v-col>
                          <!-- <v-col cols="12" md="6">
                            <v-select
                              :disabled="true"
                              :items="outputNameOptions"
                              v-model="selectedOutput"
                              label="Output Form"
                            ></v-select>
                          </v-col> -->
                        </v-row>

                        <v-textarea
                          :label="$t(`short-description`)"
                          v-model="selectedSpoke.description"
                          variant="solo-filled"
                          :rules="required"
                          :rows="1"
                        />
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-select
                              :items="brainNameOptions"
                              variant="solo-filled"
                              v-model="selectedBrain"
                              :hint="getCreditCost()"
                              persistent-hint
                              :label="$t(`brain`)"
                              :rules="required"
                              class="mb-2"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              :items="languageNameOptions"
                              variant="solo-filled"
                              v-model="selectedLanguage"
                              :label="$t(`language`)"
                              :rules="required"
                            />
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col cols="10">
                            <v-select
                              variant="solo-filled"
                              :items="bibliothecaNameOptions"
                              :disabled="loadingBibliotheca"
                              v-model="selectedBibliotheca"
                              :label="$t(`knowledge`)"
                            />
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              size="x-small"
                              color="button"
                              class="mt-3"
                              :disabled="loadingBibliotheca"
                              :loading="loadingCreateBibliotheca"
                              @click="goToCreateBibliotheca"
                              icon
                              ><v-tooltip activator="parent" location="bottom">
                                {{ $t("bibliothecas.create-one") }}</v-tooltip
                              ><IconComponent name="add"
                            /></v-btn>
                          </v-col>
                        </v-row>

                        <v-row no-gutters
                          ><v-col>
                            <v-textarea
                              :label="$t(`starting-message`)"
                              variant="solo-filled"
                              v-model="selectedSpoke.startingMessage"
                              :rules="required"
                              :rows="1"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row
                      ><v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-textarea
                              :label="$t(`personality`)"
                              variant="solo-filled"
                              v-model="selectedSpoke.personality"
                              :rules="required"
                              :rows="3"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-textarea
                              :label="$t(`goal`)"
                              variant="solo-filled"
                              v-model="selectedSpoke.goal"
                              :rules="required"
                              :rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-textarea
                          :label="$t(`context`)"
                          variant="solo-filled"
                          v-model="selectedSpoke.context"
                          :rules="required"
                          :rows="5"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-expansion-panels
                      model-value="advConfig"
                      class="w-100 pa-2"
                    >
                      <v-expansion-panel value="" class="rounded-xl">
                        <v-expansion-panel-title>
                          <div style="font-weight: 500">
                            {{ $t(`advanced-configuration`) }}
                          </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <!-- QA -->
                          <v-row no-gutters>
                            <v-card-title>{{ $t(`spokes.qa`) }}</v-card-title>
                          </v-row>
                          <v-row no-gutters>
                            <v-col>
                              <v-textarea
                                :label="$t(`spokes.qa-description`)"
                                :placeholder="$t(`spokes.qa-example`)"
                                v-model="selectedSpoke.qa"
                                variant="solo-filled"
                                :rows="3"
                              />
                            </v-col>
                          </v-row>

                          <!-- Actions -->
                          <v-row no-gutters>
                            <v-card-title>{{ $t(`actions`) }}</v-card-title>
                            <v-btn
                              class="mt-2"
                              size="x-small"
                              color="button"
                              :loading="loadingActions"
                              @click="addCommand"
                              icon
                              ><v-tooltip activator="parent" location="bottom">
                                {{ $t("add-action") }}</v-tooltip
                              >
                              <IconComponent name="add" />
                            </v-btn>
                          </v-row>
                          <v-row>
                            <v-col
                              v-if="selectedSpoke?.commands?.length === 0"
                              cols="12"
                            >
                              <v-label>{{ $t(`no-actions`) }}</v-label>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="px-4" cols="12">
                              <div
                                v-for="(
                                  command, index
                                ) in selectedSpoke.commands"
                                :key="index"
                              >
                                <v-row align="center">
                                  <v-col cols="1" class="mb-6">
                                    <strong>{{ index + 1 }}</strong>
                                  </v-col>
                                  <v-col cols="9" md="10">
                                    <v-select
                                      :label="$t(`action`) + (index + 1)"
                                      :items="actionsArray"
                                      :disabled="loadingActions"
                                      v-model="selectedSpoke.commands[index]"
                                    />
                                  </v-col>
                                  <v-col
                                    style="position: absolute; right: 0"
                                    cols="3"
                                    sm="2"
                                    md="1"
                                  >
                                    <v-btn
                                      size="small"
                                      color="transparent"
                                      class="mb-6"
                                      icon
                                      @click="deleteCommand(index)"
                                    >
                                      <IconComponent
                                        color="button"
                                        name="close"
                                      />
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-btn
                                color="button"
                                @click="addCommand"
                                :disabled="loadingActions"
                                >{{ $t("add-action") }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Save Button -->
                    <v-row class="d-flex justify-space-between pa-4">
                      <div />
                      <div>
                        <!--v-btn
                          v-if="!showConversationBot"
                          :disabled="!valid"
                          class="mr-2"
                          color="button"
                          @click="createTestConversation"
                          >Test</v-btn
                        -->
                        <v-btn
                          type="submit"
                          :loading="savingForm"
                          color="button"
                          @click="saveAsTemplate = false"
                          >{{ $t("save") }}</v-btn
                        >
                      </div>
                    </v-row>

                    <div v-if="loadingTestBot">
                      <v-progress-circular
                        indeterminate
                        color="purple"
                      ></v-progress-circular>
                    </div>

                    <ConversationBot
                      v-if="showConversationBot"
                      :conversationId="testConversationId"
                      :spokeId="spokeId"
                      :embed="true"
                      @close="onConversationBotClose"
                    ></ConversationBot>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <BuyCreditsDialog
    v-model="buyCreditsDialog"
    @close-dialog="buyCreditsDialog = false"
  />
</template>

<script>
import ConversationBot from "@/components/ConversationBot.vue";
import IconComponent from "@/components/IconComponent.vue";
import { createConversation } from "@/services/conversation";
import { collection, getDocs } from "firebase/firestore";
import { mapActions, mapState } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { fetchSpoke, listActions } from "@/services/spoke";
import {
  listUserBibliothecas,
  retrieveBibliotheca,
} from "@/services/bibliotheca";
import RobotsComponent from "@/components/RobotsComponent.vue";
import BuyCreditsDialog from "@/components/dialogs/BuyCreditsDialog.vue";
import { storage } from "@/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: "EditSpokesPage",
  components: {
    ConversationBot,
    IconComponent,
    RobotsComponent,
    BuyCreditsDialog,
  },
  props: {
    spokeId: String,
  },
  data() {
    return {
      showConversationBot: false,
      errorMessage: "",
      outputOptions: [{ name: "Default", value: null }],
      outputNameOptions: ["Default"],
      bibliothecaOptions: [{ name: "None", value: null }],
      bibliothecaNameOptions: ["None"],
      styleOptions: ["robot", "image"],
      saveAsTemplate: true,
      loading: false,
      buyCreditsDialog: false,
      loadingBibliotheca: true,
      loadingCreateBibliotheca: false,
      loadingActions: true,
      loadingTestBot: false,
      uploadingImage: false,
      savingForm: false,
      valid: true,
      userActionsOptions: [],
      userActionsNameOptions: [],
      actionListObject: [],
      actionsArray: [],
      imageUploadedURL: "",
      required: [(v) => !!v || this.$t("required-field")],
      brainOptions: [
        { name: "gpt-3.5-turbo", value: 0 },
        { name: "gpt-4", value: 1 },
        { name: "mistral-7B", value: 2 },
        { name: "google", value: 3 },
        { name: "mistral-pro", value: 4 },
        { name: "claude-basic", value: 5 },
        { name: "claude-pro", value: 6 },
      ],
      brainNameOptions: ["gpt-3.5-turbo", "gpt-4", "mistral-7B", "google", "mistral-pro", "claude-basic", "claude-pro"],
      languageOptions: [
        { name: "EN", value: 0, fullname: "English" },
        { name: "ES", value: 1, fullname: "Español" },
      ],
      languageNameOptions: ["English", "Español"],
      spoke: {
        name: "",
        description: "",
        brain: "mistral-7B",
        bibliotheca: "",
        personality: "",
        context: "",
        qa: "",
        goal: "",
        color: "#2E012E",
        selectedStyle: "robot",
        selectedRobotAnimationIndex: 0,
        imageUrl: "",
        startingMessage: "",
        expectedOutput: "",
        language: "ES",
        commands: [],
      },
    };
  },
  async created() {
    if (this.spokeId) {
      this.spoke = await fetchSpoke({ spokeId: this.spokeId });
      this.imageUploadedURL = this.spoke.imageUrl ?? "";
      this.fetchBibliothecas();
      this.fetchActions();
    } else {
      console.error("No spokeId provided");
    }
  },
  mounted() {},
  computed: {
    selectedOutput: {
      get() {
        const selectedOutputOption = this.outputOptions.find(
          (option) => option.value === this.selectedSpoke.expectedOutput
        );
        return selectedOutputOption ? selectedOutputOption.name : "Default";
      },
      set(newValue) {
        this.selectedSpoke.expectedOutput = this.outputOptions.find(
          (option) => option.name === newValue
        ).value;
      },
    },
    selectedActions: {
      get() {
        const selectedActionsOption = this.actionsOptions.find(
          (option) => option.value === this.selectedSpoke.commands
        );
        return selectedActionsOption
          ? selectedActionsOption.name
          : this.$t("spokes.loading-actions");
      },
      set(newValue) {
        this.selectedSpoke.commands = this.actionsOptions.find(
          (option) => option.name === newValue
        ).value;
      },
    },
    selectedBibliotheca: {
      get() {
        const selectedBibliothecaOption = this.bibliothecaOptions.find(
          (option) => option.value === this.selectedSpoke.bibliotheca
        );
        return selectedBibliothecaOption
          ? selectedBibliothecaOption.name
          : this.loadingBibliotheca
          ? this.$t("spokes.loading-bibliothecas")
          : "None ";
      },
      set(newValue) {
        this.selectedSpoke.bibliotheca = this.bibliothecaOptions.find(
          (option) => option.name === newValue
        ).value;
      },
    },
    selectedStyle: {
      get() {
        const selectedStyleOptions = this.styleOptions.find(
          (option) => option === this.selectedSpoke.selectedStyle
        );
        return selectedStyleOptions ? selectedStyleOptions : "robot";
      },
      set(newValue) {
        this.selectedSpoke.selectedStyle = this.styleOptions.find(
          (option) => option === newValue
        );
      },
    },
    selectedBrain: {
      get() {
        const selectedBrainOption = this.brainOptions.find(
          (option) => option.name === this.selectedSpoke.brain
        );
        return selectedBrainOption ? selectedBrainOption.name : "mistral-7B";
      },
      set(newValue) {
        this.selectedSpoke.brain = this.brainOptions.find(
          (option) => option.name === newValue
        ).name;
      },
    },
    selectedLanguage: {
      get() {
        const selectedLanguageOption = this.languageOptions.find(
          (option) =>
            option.name ===
            (this.selectedSpoke.language === "SPA"
              ? "ES"
              : this.selectedSpoke.language)
        );
        return selectedLanguageOption
          ? selectedLanguageOption.fullname
          : "English";
      },
      set(newValue) {
        this.selectedSpoke.language = this.languageOptions.find(
          (option) => option.fullname === newValue
        ).name;
      },
    },
    selectedSpoke() {
      return (
        [...this.spokes, ...this.templates].find((s) => s.id === this.spokeId)
          ?.data ?? this.spoke
      );
    },
    widgetColor: {
      get() {
        return this.selectedSpoke.color ?? "#2E012E";
      },
      set(newValue) {
        this.selectedSpoke.color = newValue;
      },
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useSpokesStore, ["spokes", "templates", "spokesLoaded"]),
  },
  methods: {
    deleteCommand(index) {
      if (!this.loadingActions) this.selectedSpoke.commands.splice(index, 1);
    },
    addCommand() {
      this.selectedSpoke.commands.push("");
    },
    inputFile() {
      const inputElement = document.getElementById("upload-image");
      inputElement.click();
    },
    changeRobotAnimation(n) {
      if (!this.selectedSpoke.selectedRobotAnimationIndex)
        this.selectedSpoke.selectedRobotAnimationIndex = 0;
      if (n === -1) {
        if (this.selectedSpoke.selectedRobotAnimationIndex === 0) {
          this.selectedSpoke.selectedRobotAnimationIndex = 13;
        } else {
          this.selectedSpoke.selectedRobotAnimationIndex += n;
        }
      } else {
        if (this.selectedSpoke.selectedRobotAnimationIndex === 13) {
          this.selectedSpoke.selectedRobotAnimationIndex = 0;
        } else {
          this.selectedSpoke.selectedRobotAnimationIndex += n;
        }
      }
    },
    async uploadImage() {
      const imageToUpload = this.$refs.file.files[0];
      if (imageToUpload.size > 1048576) {
        alert("File must be under 1MB.");
        return;
      }
      this.uploadingImage = true;
      const storageRef = ref(
        storage,
        "spokes-images/" + this.userData.userId + "/" + imageToUpload.name
      );
      uploadBytes(storageRef, imageToUpload)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          console.log("Download URL: ", downloadURL);
          this.imageUploadedURL = downloadURL;
          this.selectedSpoke.imageUrl = downloadURL;
          this.selectedStyle = "image";
          this.uploadingImage = false;
        });
    },
    onConversationBotClose() {
      this.showConversationBot = false;
    },
    async createTestConversation() {
      if (!this.$refs.form.validate()) {
        this.errorMessage = this.$t("please-fix-errors");
        return;
      }
      if (this.userData.credits != undefined && this.userData.credits < 1) {
        this.buyCreditsDialog = true;
        return;
      }
      this.loadingTestBot = true;
      this.saveAsTemplate = false;
      await this.saveSpokeVue();

      const shortId = Date.now();
      const generatedConversationId = `${this.filledOwner}.${this.spokeId}.${shortId}`;

      await createConversation({
        author: this.userData.userId,
        channel: `FRONTEND.${generatedConversationId}`,
        spokeUser: this.userData.userId,
        brain: this.selectedSpoke.brain,
        spokeId: this.spokeId,
        forUser: this.userData.userId,
        conversationId: shortId,
        owner: this.userData.userId,
        type: "lite",
      });
      this.loadingTestBot = false;
      this.testConversationId = shortId;
      this.showConversationBot = true;
    },
    async fetchOutputOptions() {
      const db = this.$db;
      const outputDocsRef = collection(
        db,
        `users/${this.userData.userId}/outputs`
      );
      const outputDocsSnap = await getDocs(outputDocsRef);

      outputDocsSnap.forEach((doc) => {
        this.outputOptions.push({
          name: doc.data().name,
          value: doc.id,
        });
        this.outputNameOptions.push(doc.data().name);
      });
    },
    async fetchBibliothecas() {
      const userBibliothecasObject = await listUserBibliothecas();
      const userBibliothecasObjectValues = Object.values(
        userBibliothecasObject
      );
      const userBibliothecasObjectKeys = Object.keys(userBibliothecasObject);
      userBibliothecasObjectValues.forEach((bibliotheca, index) => {
        this.bibliothecaOptions.push({
          name: bibliotheca.name,
          value: userBibliothecasObjectKeys[index],
        });
        this.bibliothecaNameOptions.push(bibliotheca.name);
      });
      this.loadingBibliotheca = false;
    },
    async fetchActions() {
      this.actionListObject = await listActions();
      this.actionsArray = [
        ...Object.keys(this.actionListObject?.public),
        ...Object.keys(this.actionListObject?.user),
      ];
      if (!Array.isArray(this.selectedSpoke.commands)) {
        this.selectedSpoke.commands = [];
      }
      this.loadingActions = false;
    },
    async saveSpokeVue(redirectToManageSpokes = true) {
      if (!this.$refs.form.validate() || !this.valid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.errorMessage = this.$t("please-fix-errors");
        return;
      }
      this.savingForm = true;
      this.errorMessage = "";
      try {
        const spokeData = {
          name: this.selectedSpoke.name,
          description: this.selectedSpoke.description,
          brain: this.selectedBrain,
          personality: this.selectedSpoke.personality,
          context: this.selectedSpoke.context,
          goal: this.selectedSpoke.goal,
          startingMessage: this.selectedSpoke.startingMessage,
          language: this.selectedSpoke.language,
          qa: this.selectedSpoke.qa || "",
          color: this.widgetColor || "#2E012E",
          owner: this.userData.userId,
          spokeId: this.spokeId,
          selectedStyle: this.selectedStyle,
          selectedRobotAnimationIndex:
            this.selectedSpoke.selectedRobotAnimationIndex || 0,
          imageUrl: this.imageUploadedURL,
          template: this.saveAsTemplate,
          commands: this.selectedSpoke.commands,
          bibliotheca: this.selectedSpoke.bibliotheca,
          ...(this.selectedSpoke.expectedOutput !== null
            ? { expectedOutput: this.selectedSpoke.expectedOutput }
            : {}),
          createdAt: this.selectedSpoke.createdAt ?? null,
          updatedAt: Date.now(),
        };
        await this.saveSpoke({
          owner: this.userData.userId,
          spokeId: this.spokeId,
          spokeData: spokeData,
        });
        if (redirectToManageSpokes) {
          this.$router.push("/manage-spokes");
        }
      } catch (error) {
        console.error("Error saving spokes:", error);
      }

      this.savingForm = false;
    },
    async goToCreateBibliotheca() {
      try {
        this.loadingCreateBibliotheca = true;
        const bibliothecaId = await retrieveBibliotheca({
          owner: this.userData.userId,
          bibliotheca: undefined,
        });
        this.$router.push({
          name: "EditBibliotheca",
          params: { bibliothecaId: bibliothecaId },
        });
        this.loadingCreateBibliotheca = false;
      } catch (e) {
        console.log(e);
        this.loadingCreateBibliotheca = false;
      }
    },
    getCreditCost() {
      switch (this.selectedBrain) {
        case "gpt-3.5-turbo":
          return `1 ` + this.$t(`conversation`) + ` = 1 ` + this.$t(`credit`);
        case "gpt-4":
          return (
            `1 ` +
            this.$t(`conversation`) +
            ` = 10 ` +
            this.$t(`config.credits`)
          );
        case "mistral-7B":
          return this.$t(`free`);
        case "google":
          return this.$t(`free`);
        case "mistral-pro":
          return (
            `1 ` +
            this.$t(`conversation`) +
            ` = 3 ` +
            this.$t(`config.credits`)
          );
        case "claude-basic":
          return `1 ` + this.$t(`conversation`) + ` = 1 ` + this.$t(`credit`);
        case "claude-pro":
          return (
            `1 ` +
            this.$t(`conversation`) +
            ` = 5 ` +
            this.$t(`config.credits`)
          );
      }
    },
    ...mapActions(useSpokesStore, ["saveSpoke"]),
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
}
.spokes-editor {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}
.conversation-bot-container {
  margin-top: 20px;
}

.active {
  color: purple;
  border: solid 1px purple;
}

.slide-left {
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}

.slide-right {
  animation: slide-right 0.5s forwards;
  -webkit-animation: slide-right 0.5s forwards;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
