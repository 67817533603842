<template>
  <v-card class="elevation-0 rounded-xl pa-4 w-100">
    <v-form ref="form" v-model="valid" @submit.prevent="createBot">
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            :items="spokesNameOptions"
            variant="solo-filled"
            v-model="selectedSpoke.name"
            :disabled="loadingSpokes"
            :label="$t(`integrationsPage.select-spoke`)"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="slackChannel"
            :label="$t(`integrationsPage.slack-channel-id`)"
            required
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between pa-4">
        <v-col>
          <div />
          <div class="text-center">
            <v-btn
              type="submit"
              :disabled="
                !valid ||
                savingForm ||
                loadingSpokes ||
                selectedSpoke.name === `None` ||
                !slackWorkspace ||
                !slackChannel
              "
              :loading="savingForm || loadingSpokes"
              color="button"
              @click="saveAsTemplate = false"
              >{{ $t("integrationsPage.add-bot") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <v-snackbar v-model="snackbarVisible" top color="success" timeout="2000">
    {{ $t("integrationsPage.slack-bot-created") }}
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useIntegrationsStore } from "@/stores/integrations";
import { useUserStore } from "@/stores/user";
import { fetchSpokesFromFirebase } from "@/services/spoke";

export default {
  name: "CreateSlackBot",
  data() {
    return {
      spokesOptions: [{ name: "None", value: null }],
      spokesNameOptions: ["None"],
      selectedSpoke: { name: "None", value: null },
      selectedSpokeId: "",
      loadingSpokes: false,
      loadingCreateBot: false,
      slackChannel: "",
      savingForm: false,
      valid: true,
      spokes: [],
      snackbarVisible: false,
    };
  },
  props: {
    slackWorkspace: {
      type: String,
      default: "",
    },
  },
  async created() {
    this.loadingSpokes = true;
    await this.fetchSpokes();
  },
  computed: {
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    async fetchSpokes() {
      const userSpokesObject = await fetchSpokesFromFirebase();
      const userSpokesObjectValues = Object.values(userSpokesObject);
      const userSpokesObjectKeys = Object.keys(userSpokesObject);
      userSpokesObjectValues.forEach((spoke, index) => {
        if (!spoke.template) {
          this.spokesOptions.push({
            name: spoke.data.name,
            value: userSpokesObjectKeys[index],
          });
          this.spokesNameOptions.push(spoke.data.name);
        }
      });
      this.spokes = userSpokesObject.filter((spoke) => !spoke.template);
      //this.templates = raw_spokes.filter((spoke) => spoke.template);
      this.loadingSpokes = false;
    },
    async createBot() {
      this.savingForm = true;
      try {
        this.selectedSpokeId = this.spokes.find(
          (s) => s.data.name === this.selectedSpoke.name
        )?.id;
        await this.addSlackWorkspace({
          spokeId: this.selectedSpokeId,
          workspace: this.slackWorkspace,
          channel: this.slackChannel,
        });
        this.snackbarVisible = true;
        this.slackChannel = "";
        this.selectedSpoke = { name: "None", value: null };
      } catch (e) {
        alert("Error adding Spoke to Slack: ", e);
        console.log("Error adding Spoke to Slack: ", e);
      }
      this.savingForm = false;
      this.$emit("closeDialog");
    },
    ...mapActions(useIntegrationsStore, ["addSlackWorkspace"]),
  },
};
</script>
