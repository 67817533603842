<template>
  <v-card class="elevation-12 rounded-xl pb-6">
    <v-card-text class="py-0">
      <v-form @submit.prevent="handleLogin">
        <v-card-title class="text-h5 font-weight-bold py-6">{{
          $t(`auth.login`)
        }}</v-card-title>
        <v-alert
          v-if="errorMessage"
          type="error"
          dismissible
          colored-border
          outlined
        >
          {{ errorMessage }}
        </v-alert>
        <v-text-field
          v-model="email"
          label="Email"
          :rules="[rules.required, rules.validEmail]"
        />
        <v-text-field
          v-model="password"
          :label="$t(`auth.password`)"
          type="password"
          :rules="[rules.required]"
        />
        <div class="text-right mb-6">
          <router-link
            class="text-decoration-none hoverable"
            to="/forgot-password"
            >{{ $t(`auth.forgot-password`) }}</router-link
          >
        </div>
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="!email || !password"
          color="button"
          >{{ $t(`auth.signin`) }}</v-btn
        >
        <v-card-title class="pt-0 text-body-1">{{
          $t(`auth.or`)
        }}</v-card-title>
        <v-btn :loading="loadingGoogle" @click="loginWithGoogle" color="button">
          <img class="google-icon" :src="googleIcon" />{{
            $t("auth.google-signin")
          }}
        </v-btn>
        <v-card-text>{{ $t("auth.dont-have-account") }}</v-card-text>
        <v-btn @click="$router.push('/register')" color="secondary">{{
          $t(`auth.signup`)
        }}</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
} from "@firebase/auth";
import GoogleIcon from "@/assets/logos/Google_icon.png";

export default {
  name: "LoginForm",
  data() {
    return {
      googleIcon: GoogleIcon,
      email: "",
      password: "",
      errorMessage: "",
      error: false,
      loading: false,
      loadingGoogle: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 8 || "Min 8 characters",
        validEmail: (value) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(
            value
          ) || this.$t("auth.email-validation"),
      },
    };
  },
  methods: {
    async handleLogin() {
      try {
        this.loading = true;
        this.error = false;
        this.errorMessage = "";
        const auth = getAuth();
        //console.log(`Sign in attempt for ${this.email}`);
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push("/home");
      } catch (error) {
        console.error("Login failed:", error);
        this.error = true;
        this.errorMessage = this.$t("auth.wrong-credentials");
      } finally {
        this.loading = false;
      }
    },
    async loginWithGoogle() {
      try {
        this.loadingGoogle = true;
        this.error = false;
        this.errorMessage = "";
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        this.$router.push("/home");
      } catch (error) {
        console.error("Google login failed:", error);
        this.error = true;
        this.errorMessage = this.$t("auth.google-login-failed");
      } finally {
        this.loadingGoogle = false;
      }
    },
  },
};
</script>

<style scoped>
.google-icon {
  margin-right: 6px;
  width: 18px;
  height: 18px;
}
</style>
