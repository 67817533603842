<template>
  <v-container class="container pa-3 rounded bg-grey-lighten-5" fluid>
    <div v-if="!spokesLoaded">
      <v-row align="center" style="height: 90vh">
        <v-col cols="12">
          <v-progress-circular
            :size="50"
            :width="5"
            color="purple"
            indeterminate
          ></v-progress-circular> </v-col
      ></v-row>
    </div>
    <div v-if="spokesLoaded" class="conversation-page elevation-4">
      <div class="top-area pb-2">
        <v-row>
          <v-col align="left" cols="12">
            <v-btn
              size="small"
              class="backButton mt-1"
              @click="$router.back()"
              icon
            >
              <IconComponent name="back" />
            </v-btn>

            <div class="d-flex">
              <div class="ml-10 mt-1">
                <div v-if="selectedSpoke.selectedStyle === 'image'">
                  <!--v-tooltip activator="parent" location="bottom">
                Conversation Id: {{ currentConversationId }}</v-tooltip
                  -->
                  <v-img
                    v-if="selectedSpoke.imageUrl"
                    height="40"
                    :src="selectedSpoke?.imageUrl"
                  >
                    <template v-slot:placeholder>
                      <div
                        class="d-flex align-center justify-center fill-height"
                      >
                        <v-progress-circular
                          color="purple"
                          indeterminate
                        ></v-progress-circular>
                      </div> </template
                  ></v-img>
                </div>
                <div v-if="selectedSpoke.selectedStyle === 'robot'">
                  <RobotsComponent
                    :index="selectedSpoke.selectedRobotAnimationIndex"
                    :height="50"
                    :width="50"
                  />
                </div>
              </div>
              <v-card-title class="text-primary mt-2">{{
                selectedSpoke.name
              }}</v-card-title>

              <v-btn
                class="mt-2"
                @click="goToEditSpokes"
                icon
                size="small"
                color="transparent"
                ><v-tooltip activator="parent" location="top">
                  {{ $t("spokes.edit") }} {{ selectedSpoke.name }}</v-tooltip
                ><v-progress-circular
                  v-if="loadingConversation"
                  indeterminate
                  color="purple"
                  size="16"
                  width="3"
                ></v-progress-circular>
                <IconComponent v-else color="purple" name="edit" />
              </v-btn>
            </div>
            <v-btn
              @click="openDeleteDialog"
              icon
              size="small"
              color="transparent"
              class="close-button"
              ><v-tooltip activator="parent" location="top">
                {{ $t("delete-conversation") }}
              </v-tooltip>
              <IconComponent color="red" name="delete" />
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="messages-container px-4 pt-2" ref="messagesContainer">
        <v-row>
          <v-col v-if="conversation">
            <div v-for="(message, index) in conversation.messages" :key="index">
              <div
                class="mb-2 px-3 py-2 rounded-lg message"
                :class="[
                  message.role === 'assistant'
                    ? 'bg-white text-dark float-left text-left'
                    : 'bg-primary text-white float-right text-right',
                  message.role === 'assistant' ? 'mr-2' : 'ml-2',
                ]"
              >
                <a
                  v-if="message.attachments?.imageUrl?.length > 3"
                  :href="message.attachments?.imageUrl"
                  target="_blank"
                >
                  <v-img
                    :src="message.attachments?.imageUrl"
                    height="200"
                    class="pb-2 hoverable"
                  />
                </a>
                <audio
                  v-if="message.attachments?.audioUrl?.length > 3"
                  :src="message.attachments?.audioUrl"
                  controls
                ></audio>
                <vue-showdown
                  class="markdown-container"
                  :markdown="
                    message.content?.replace(/<hidden>.*?<\/hidden>/gs, '')
                  "
                  :extensions="['targetlink']"
                  flavor="github"
                />
              </div>
              <div class="clearfix"></div>
            </div>
            <div v-if="sendingMessage">
              <div
                class="mb-2 px-3 py-2 rounded-lg message bg-white text-dark float-left text-left mr-2"
              >
                <div class="loading">
                  <span>·</span><span>·</span><span>·</span>
                </div>
              </div>
            </div>
          </v-col></v-row
        >
      </div>
      <div class="bottom-area px-2">
        <v-row
          no-gutters
          align="left"
          justify="left"
          v-if="imageUploadedURL.length > 3"
        >
          <v-col cols="8" sm="9" md="10" lg="11"> </v-col>
          <v-col cols="4" sm="3" md="2" lg="1">
            <v-img :src="imageUploadedURL" height="50">
              <v-btn
                icon
                size="x-small"
                @click="() => (imageUploadedURL = ``)"
                style="position: absolute; right: 0px; top: 0px"
              >
                <IconComponent size="16" name="close" />
              </v-btn>
            </v-img>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="left"
          justify="left"
          v-if="audioUploadedURL.length > 3"
        >
          <v-col cols="8" sm="9" md="10" lg="11"> </v-col>
          <v-col cols="4" sm="3" md="2" lg="1">
            <v-img :src="require('@/assets/audiofile.png')" height="50">
              <v-btn
                icon
                size="x-small"
                @click="() => (audioUploadedURL = ``)"
                style="position: absolute; right: 0px; top: 0px"
              >
                <IconComponent size="16" name="close" />
              </v-btn>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" sm="9" md="10" lg="11">
            <v-textarea
              v-model="userMessage"
              @keydown.enter.prevent="
                ($event.shiftKey && $event.keyCode === 13) || sendUserMessage()
              "
              @keydown.shift.enter="$event.target.value += '\n'"
              :placeholder="$t(`type-your-message`)"
              rows="2"
            ></v-textarea>
          </v-col>
          <v-col
            cols="4"
            sm="3"
            md="2"
            lg="1"
            class="d-flex flex-column align-center mt-4"
          >
            <v-btn
              @click="sendUserMessage"
              :disabled="sendingMessage || loadingUpload"
              icon
              style="margin-left: -60px"
              size="small"
              class="bg-primary"
            >
              <IconComponent size="22" name="send"
            /></v-btn>
            <v-btn
              @click="inputFile"
              :disabled="
                sendingMessage ||
                loadingConversation ||
                imageUploadedURL.length > 3
              "
              icon
              :loading="loadingUpload"
              style="position: absolute; margin-right: -40px"
              size="small"
              variant="outlined"
            >
              <IconComponent size="22" name="attachment"
            /></v-btn>
          </v-col>
          <input
            id="file-upload"
            type="file"
            ref="fileRef"
            hidden
            accept="audio/wav, image/png, image/gif, image/jpeg"
            @change="uploadFile"
          />
        </v-row>
      </div>
    </div>
  </v-container>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center"
        >{{ $t("delete-conversation-confirmation") }}
      </v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="this.deleteDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn color="button" @click="closeChat">{{ $t("delete") }} </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSpokesStore } from "@/stores/spokes";
import { useConversationsStore } from "@/stores/conversations";
import {
  sendMessage,
  createConversation,
  deleteConversation,
} from "@/services/conversation";
import IconComponent from "@/components/IconComponent.vue";
import RobotsComponent from "./RobotsComponent.vue";
import { storage } from "@/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uploadToGcpBucket } from "@services/uploadStorage";

export default {
  name: "ConversationWindow",
  components: {
    IconComponent,
    RobotsComponent,
  },
  props: {
    conversationId: String,
    owner: String,
    spokeId: String,
    spokeUser: String,
  },
  data() {
    return {
      loadingConversation: true,
      loadingUpload: false,
      deleteDialog: false,
      valid: true,
      currentConversationId: `${this.owner}.${this.currentOwner}.${this.conversationId}`,
      filledOwner: this.owner,
      conversationKey: 0,
      retryCount: 0,
      userMessage: "",
      imageUploadedURL: "",
      audioUploadedURL: "",
      sendingMessage: false,
      spoke: {
        name: "",
        description: "",
        brain: "mistral-7B",
        bibliotheca: "",
        personality: "",
        context: "",
        goal: "",
        selectedStyle: "robot",
        selectedRobotAnimationIndex: 0,
        imageUrl: "",
        startingMessage: "",
        expectedOutput: "",
        language: "EN",
        commands: [],
      },
      conversation: {
        name: "",
        messages: [],
        spokeId: "",
        brain: "",
        prompt: "",
        spokeUser: "",
      },
    };
  },
  async created() {
    if (this.userData.credits != undefined && this.userData.credits < 1) {
      //this.$router.push("/buy-credits");
    }
    this.filledOwner = this.spokeUser || this.owner;
    if (this.conversationId && this.spokeId) {
      const shortId = this.conversationId;
      this.currentConversationId = `${this.filledOwner}.${this.spokeId}.${shortId}`;
      const [user, spoke, conversation] = this.currentConversationId.split(".");
      this.conversation = await this.getConversationDataFromFirebase({ user, spoke, conversation });
    } else if (this.spokeUser && this.spokeId) {
      await this.myCreateConversation();
    } else {
      console.error("Missing required query parameters or conversationId");
    }
  },
  mounted() {
    this.handleRetry();
  },
  beforeMount() {
    this.setAppSpoke({ show: false });
  },
  unmounted() {
    this.setAppSpoke({ show: true });
  },
  computed: {
    selectedSpoke() {
      return [...this.spokes, ...this.templates].find(
        (s) => s.id === this.spokeId
      )?.data;
    },
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
    ...mapState(useSpokesStore, ["spokes", "templates", "spokesLoaded"]),
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs?.messagesContainer;
        if (container) container.scrollTop = container?.scrollHeight;
      });
    },
    inputFile() {
      const inputElement = document.getElementById("file-upload");
      inputElement.click();
    },
    async uploadFile() {
      try {
        this.loadingUpload = true;
        let docus = [];
        const docToUpload = this.$refs.fileRef.files[0];
        const fileName = document.getElementById("file-upload").value;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (docToUpload.size > 10497152) {
          alert("File must be under 10MB.");
          this.loadingUpload = false;
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(docToUpload);
        reader.onload = function () {
          docus.push(reader.result);
        };
        const storageRef = ref(
          storage,
          "conversation-media/" +
            this.userData.userId +
            "/" +
            this.selectedSpoke.brain +
            "/" +
            this.spokeId +
            "/outputs/" +
            this.conversationId +
            "/workspace/" +
            docToUpload.name
        );
        uploadBytes(storageRef, docToUpload)
          .then((snapshot) => {
            return getDownloadURL(snapshot.ref);
          })
          .then(async (downloadURL) => {
            //console.log("Download URL: ", downloadURL);
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
              this.imageUploadedURL = downloadURL;
              const response = await uploadToGcpBucket({
                file: {
                  userId: this.userData.userId,
                  spokeId: this.spokeId,
                  conversationId: this.conversationId,
                  model: this.selectedSpoke.brain,
                  imageName: docToUpload.name + "-" + Date.now(),
                  image: docus[0],
                },
                url: "https://uploadimagetobucket-ai32xjq4va-uc.a.run.app",
              });
              console.log(response?.message);
            } else {
              this.audioUploadedURL = downloadURL;
            }
            this.loadingUpload = false;
          });
      } catch (e) {
        console.error(e);
        this.loadingUpload = false;
      }
    },
    handleRetry() {
      if (this.currentConversationId) {
        const db = this.$db;
        //console.log(`Fetching: ${this.currentConversationId}`);
        const [user, spoke, conversation] =
          this.currentConversationId.split(".");
        const conversationRef = doc(
          db,
          "users",
          user,
          "spokes",
          spoke,
          "conversations",
          conversation
        );
        onSnapshot(conversationRef, (doc) => {
          this.conversation = doc.data();
          if (this.conversation === undefined) {
            //console.log("conversation undefined");
            setTimeout(() => {
              this.retryCount++;
              this.$nextTick(() => {
                this.handleRetry();
              });
            }, 1000); // Wait for 1 second before retrying
          } else {
            //console.log("Conversation data:", this.conversation);
            this.scrollToBottom();
            this.loadingConversation = false;
          }
        });
      } else {
        //console.log("currentConversationId undefined");
        if (this.retryCount < 5) {
          // Limit the number of retries
          setTimeout(() => {
            this.retryCount++;
            this.$nextTick(() => {
              this.handleRetry();
            });
          }, 1000); // Wait for 1 second before retrying
        } else {
          // Show an error message or navigate to another page
        }
      }
    },
    openDeleteDialog() {
      this.deleteDialog = true;
    },
    async myCreateConversation() {
      try {
        const shortId = Date.now();
        const generatedConversationId = `${this.filledOwner}.${this.spokeId}.${shortId}`;
        await createConversation({
          author: "#frontendAgent#",
          channel: `FRONTEND.${generatedConversationId}`,
          spokeUser: this.spokeUser,
          brain: this.selectedSpoke.brain,
          spokeId: this.spokeId,
          forUser: this.userData.userId,
          conversationId: shortId,
          owner: this.userData.userId,
          type: "frontGenerated",
        });
        this.currentConversationId = generatedConversationId;
        this.$router.push({
          name: "ChatPage",
          params: { conversationId: shortId },
          query: { owner: this.userData.userId, spokeId: this.spokeId },
        });
      } catch (error) {
        console.error("Error creating conversation:", error);
      }
    },
    async closeChat() {
      deleteConversation({
        conversationId: this.currentConversationId,
        author: this.userData.userId,
      });
      // can't use router.back() because of the url redirect
      this.$router.push({ name: "ManageSpokes" });
    },
    async sendUserMessage() {
      if (!this.userMessage || this.loadingConversation) {
        return;
      }
      this.sendingMessage = true;
      const message = {
        author: this.userData.userId,
        content: this.userMessage,
        role: "user",
        attachments: {
          imageUrl: this.imageUploadedURL,
          audioUrl: this.audioUploadedURL,
        },
      };
      //console.log("Sending message:", message);

      try {
        this.conversation.messages.push(message);
        this.imageUploadedURL = "";
        this.audioUploadedURL = "";
        const conversationId = this.currentConversationId;
        const payload = message.content;
        this.userMessage = "";
        await sendMessage({
          conversationId,
          message: payload,
          user: this.userData.userId,
          attachments: message.attachments,
        });
      } catch (error) {
        alert(error);
        console.error("Error sending message:", error);
      } finally {
        this.sendingMessage = false;
        this.imageUploadedURL = "";
        this.audioUploadedURL = "";
      }
    },
    goToEditSpokes() {
      this.$router.push({
        name: "EditSpoke",
        params: { spokeId: this.spokeId },
      });
    },
    ...mapActions(useUserStore, ["setAppSpoke"]),
    ...mapActions(useConversationsStore, ["deleteConversation", "separateIds", "getConversationDataFromFirebase"]),
  },
};
</script>

<style scoped>
.loading span {
  display: inline-block;
  margin-right: 2px;
  opacity: 0;
  animation: loading 1s infinite;
}

.loading span:nth-child(1) {
  animation-delay: 0s;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.conversation-page {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
  height: 88vh;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.top-area {
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 10px 10px -15px #111;
}

.bottom-area {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.widget-container {
  max-width: 400px;
  margin: 0 auto;
}
.messages-container {
  max-height: 68vh;
  min-height: 68vh;
  overflow-y: auto;
  background-color: #f7f2f7;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-height: 700px) {
  .messages-container {
    max-height: 60vh;
    min-height: 60vh;
  }
}

@media only screen and (min-height: 700px) and (max-height: 800px) {
  .messages-container {
    max-height: 64vh;
    min-height: 64vh;
  }
}
.message {
  word-break: break-word;
  max-width: 85%;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.question-tooltip {
  position: absolute;
}

.backButton {
  position: absolute;
  left: 2px;
  top: 5px;
}

.markdown-container :deep(pre) {
  background-color: #e8e8e8;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
}

.markdown-container :deep(table) {
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.markdown-container :deep(table) th,
.markdown-container :deep(table) td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
}

.markdown-container :deep(ul),
.markdown-container :deep(ol) {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.markdown-container :deep(li) {
  margin-bottom: 0.5rem;
}

.markdown-container :deep(ul) ul,
.markdown-container :deep(ul) ol,
.markdown-container :deep(ol) ul,
.markdown-container :deep(ol) ol {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/* Headings */
.markdown-container :deep(h1),
.markdown-container :deep(h2),
.markdown-container :deep(h3),
.markdown-container :deep(h4),
.markdown-container :deep(h5),
.markdown-container :deep(h6) {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-container :deep(blockquote) {
  padding-left: 1rem;
  border-left: 3px solid #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}

/* Inline code */
.markdown-container :deep(code) {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  font-size: 0.9em;
}

/* Links */
.markdown-container :deep(a) {
  color: #3498db;
  text-decoration: none;
}

.markdown-container :deep(a:hover) {
  text-decoration: underline;
}

/* Horizontal rule */
.markdown-container :deep(hr) {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.v-btn.v-btn--disabled {
  background-color: initial !important;
  color: gray !important;
  opacity: 0.5 !important;
}
</style>
