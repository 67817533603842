import { defineStore } from "pinia";
import {
  fetchSpokesFromFirebase,
  saveSpoke,
  deleteSpoke,
  deleteSpokeFromFirebase,
  saveSpokeToFirebase,
  listUserSpokes,
} from "@/services/spoke";

export const useSpokesStore = defineStore({
  id: "spokes",

  state: () => ({
    spokes: [],
    spokesFromfirebase: [],
    templates: [],
    spokesAndDraftsCount: 0,
    spokesCount: 0,
    templatesCounts: 0,
    spokesLoaded: false,
    deletedSpokes: [],
  }),

  actions: {
    async fetchUserSpokes() {
      const res = await listUserSpokes();
      const userSpokesObjectValues = Object.values(res);
      const userSpokesObjectKeys = Object.keys(res);

      this.spokes = userSpokesObjectValues.map((s, index) => ({
        data: { ...s },
        id: userSpokesObjectKeys[index],
      }));

      this.spokesAndDraftsCount = res.length;
      this.spokesCount = this.spokes.length;
      this.templatesCount = this.templates.length;
      this.spokesLoaded = true;
      await this.syncSpokesList();
      return;
    },
    async saveSpoke({ owner, spokeId, spokeData }) {
      if (this.spokes.find((s) => s.id === spokeId)) {
        this.spokes = this.spokes.filter((s) => s.id !== spokeId);
      }
      this.spokes.push({
        data: { ...spokeData },
        id: spokeId,
      });
      await saveSpokeToFirebase({
        userId: owner,
        id: spokeId,
        spokeData: spokeData,
      });
      await saveSpoke({ owner: owner, spokeId: spokeId, spokeData: spokeData });
      //await this.fetchUserSpokes();
      this.spokesAndDraftsCount += 1;
      this.spokesCount += 1;
      return;
    },
    async deleteSpoke({ owner, spokeId }) {
      deleteSpoke({ owner: owner, spokeId: spokeId });
      deleteSpokeFromFirebase({ userId: owner, id: spokeId });
      this.spokes = this.spokes.filter((c) => c.data.spokeId != spokeId);
      this.templates = this.templates.filter((c) => c.data.spokeId != spokeId);
      //await this.fetchUserSpokes();
      this.deletedSpokes = this.deletedSpokes.concat([spokeId]);
      this.spokesAndDraftsCount -= 1;
      this.spokesCount -= 1;
      return;
    },
    async syncSpokesList() {
      const spokesFirebase = await fetchSpokesFromFirebase();
      this.spokesFromfirebase = spokesFirebase.filter(
        (spoke) => !spoke.template
      );
      this.templates = spokesFirebase.filter((spoke) => spoke.template);
      for (const spoke of this.spokes) {
        const s = this.spokesFromfirebase.find((s) => s.id === spoke.id);
        if (s === undefined) {
          await saveSpokeToFirebase({
            userId: spoke.data.owner,
            id: spoke.id,
            spokeData: { ...spoke.data },
          });
        }
      }
    },
  },
});
