<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-progress-circular
        v-if="loadingConversations || !spokesLoaded"
        :size="50"
        :width="5"
        color="purple"
        class="my-8"
        indeterminate
      >
      </v-progress-circular>
      <v-list v-else class="my-0 py-0">
        <v-list-item
          class="px-4 py-0 my-0"
          style="border-bottom: 0.5px solid gray"
          v-for="conversation in conversations"
          :key="conversation"
        >
          <v-row no-gutters align="center">
            <v-col
              @click="goToChat(conversation)"
              style="cursor: pointer"
              class="hoverable my-0 py-0"
              cols="12"
              md="9"
            >
              <v-list-item-title
                class="text-primary text-md-left mt-12 mt-md-0"
              >
                Spoke:
                {{
                  spokes.find((s) => s.id === separateIds(conversation).spokeId)?.data?.name ??
                  separateIds(conversation).spokeId
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-primary text-md-left">
                {{ $t("conversation") }}:
                {{ separateIds(conversation).conversationId }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12" md="3">
              <v-list-item-title class="text-md-right pa-6">
                <v-btn
                  color="transparent"
                  icon
                  :disabled="loading || loadingBibliotheca"
                  @click="goToChat(conversation)"
                  ><v-tooltip activator="parent" location="bottom">
                    {{ $t("config.view-conversation") }} </v-tooltip
                  ><IconComponent color="purple" name="open"
                /></v-btn>
                <v-btn
                  size="small"
                  icon
                  color="transparent"
                  @click="openDeleteDialog(conversation)"
                >
                  <IconComponent color="red" name="delete" />
                </v-btn>
              </v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
  <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card class="pa-4">
      <v-card-title class="text-primary text-wrap text-center"
        >{{ $t("delete-confirmation") }}
      </v-card-title>
      <v-card-text class="text-primary"> </v-card-text>
      <v-row class="pa-4 text-center">
        <v-col>
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="this.deleteDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="button"
            @click="myDeleteConversation(this.selectedForDelete)"
            >{{ $t("delete") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { useConversationsStore } from "@/stores/conversations";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "ConversationsList",
  data() {
    return {
      deleteDialog: false,
      selectedForDelete: null,
    };
  },
  components: {
    IconComponent,
  },
  props: {
    conversations: {
      type: Array,
      default: () => [],
    },
    loadingConversations: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToChat(ids) {
      const idsObject = this.separateIds(ids);
      this.$router.push({
        name: "ChatPage",
        params: { conversationId: idsObject.conversationId },
        query: {
          spokeUser: idsObject.userId,
          spokeId: idsObject.spokeId,
        },
      });
    },
    openDeleteDialog(conversation) {
      this.deleteDialog = true;
      this.selectedForDelete = conversation;
    },
    myDeleteConversation(conversation) {
      this.deleteConversation(conversation);
      this.deleteDialog = false;
      this.selectedForDelete = null;
    },
    ...mapActions(useConversationsStore, ["deleteConversation", "separateIds"]),
  },
  computed: {
    ...mapState(useSpokesStore, ["spokes", "spokesLoaded", "spokesCount"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
};
</script>
<style scoped>
.conversations-card {
  max-width: 500px;
}
</style>
