import { defineStore } from "pinia";
import { fetchUser, saveUserData, logOut } from "@/services/user";

export const useUserStore = defineStore({
  id: "user",

  state: () => ({
    userData: {},
    userDataLoaded: false,
    showAppSpoke: true,
  }),

  actions: {
    async fetchUserData() {
      const res = await fetchUser();
      this.userData = res;
      this.userDataLoaded = true;
      return;
    },
    async saveUserData(userData) {
      await saveUserData(userData);
      await this.fetchUserData();
      return;
    },
    setAppSpoke({ show }) {
      this.showAppSpoke = show;
    },
    async logOut() {
      await logOut();
      return;
    },
  },
});
