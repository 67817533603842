<template>
    <v-container class="config-page">
      <v-card>
        <v-card-title class="text-center">Create Conversation Configuration</v-card-title>
        <v-form @submit.prevent="createConfiguration">
          <v-text-field v-model="configurationName" label="Configuration Name" required></v-text-field>
          <!-- Add more fields for your configuration options here -->
          <v-card-actions>
            <v-btn type="submit" color="primary" class="mx-auto">Create Configuration</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
</template>
  
<script>
  export default {
    name: 'ConfigurationPage',
    data() {
      return {
        configurationName: ''
      };
    },
    methods: {
      createConfiguration() {
        console.log('Creating configuration with name:', this.configurationName);
        // Send a POST request to your backend with the configuration data
      }
    }
  };
</script>
  
<style>
  .config-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>