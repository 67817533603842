<template>
  <v-container class="spokes-page">
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel class="rounded-xl">
            <v-expansion-panel-title>
              <v-row>
                <v-col cols="12">
                  <v-card-title
                    class="text-primary font-weight-bold text-h4 text-left"
                  >
                    {{ $t("spokes.drafts") }}
                  </v-card-title>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-text-field
                :label="$t(`search`)"
                variant="solo"
                v-model="draftSearchInput"
                hide-details
                rounded
              >
                <template v-slot:prepend-inner>
                  <IconComponent name="search" />
                </template>
              </v-text-field>
              <v-progress-circular
                v-if="!spokesLoaded"
                :size="50"
                :width="5"
                color="purple"
                class="mt-16"
                indeterminate
              ></v-progress-circular>
              <TemplatesList :templates="filteredTemplates" />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels model-value="mySpokes">
          <v-expansion-panel value="mySpokes" class="rounded-xl">
            <v-expansion-panel-title>
              <v-row class="d-flex justify-space-between px-4 mt-2">
                <div class="align-start mb-4 pa-2">
                  <v-row align="center">
                    <v-card-title
                      class="text-primary font-weight-bold text-h4 text-left"
                      >{{ $t("spokes.my-spokes") }}</v-card-title
                    >
                  </v-row>
                </div>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row align="center" class="pt-2">
                <v-col cols="12" md="11">
                  <v-text-field
                    :label="$t(`search`) + ` Spoke`"
                    variant="solo"
                    v-model="spokesSearchInput"
                    hide-details
                    rounded
                  >
                    <template v-slot:prepend-inner>
                      <IconComponent name="search" />
                    </template> </v-text-field
                ></v-col>
                <v-col cols="12" md="1">
                  <v-btn
                    :loading="loadingCreateSpokes"
                    icon
                    size="small"
                    color="button"
                    @click="
                      (e) => {
                        goToCreateSpoke();
                        e.stopPropagation();
                      }
                    "
                    ><v-tooltip activator="parent" location="bottom">
                      {{ $t("spokes.create-one") }}</v-tooltip
                    >
                    <IconComponent name="add" />
                  </v-btn>
                </v-col>
              </v-row>
              <SpokesList
                :spokes="filteredSpokes"
                :loadingSpokes="!spokesLoaded"
              />
              <v-pagination
                v-model="currentSpokePage"
                :disabled="spokesSearchInput.length > 0"
                :length="spokesPageCount"
                rounded="circle"
              ></v-pagination>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useSpokesStore } from "@/stores/spokes";
import { useUserStore } from "@/stores/user";
import { retrieveOrCreateSpoke } from "@/services/spoke";
import SpokesList from "@/components/SpokesList.vue";
import TemplatesList from "@/components/TemplatesList.vue";
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "SpokesPage",
  components: {
    SpokesList,
    TemplatesList,
    IconComponent,
  },
  data() {
    return {
      spokesSearchInput: "",
      draftSearchInput: "",
      itemsPerPage: 8,
      currentSpokePage: 1,
      loadingCreateSpokes: false,
    };
  },
  mounted() {
    //this.fetchUserSpokes();
  },
  computed: {
    spokesPageCount() {
      if (this.spokesLoaded) {
        return Math.ceil(this.spokes.length / this.itemsPerPage);
      } else {
        return 0;
      }
    },
    filteredSpokes() {
      if (this.spokesLoaded) {
        return this.spokes
          .filter((spoke) =>
            spoke.data.name
              .toLowerCase()
              .includes(this.spokesSearchInput.toLowerCase())
          )
          .slice(
            (this.currentSpokePage - 1) * this.itemsPerPage,
            this.currentSpokePage * this.itemsPerPage
          );
      } else {
        return [];
      }
    },
    filteredTemplates() {
      if (this.spokesLoaded) {
        return this.templates.filter((spoke) =>
          spoke.data.name
            .toLowerCase()
            .includes(this.draftSearchInput.toLowerCase())
        );
      } else {
        return [];
      }
    },
    ...mapState(useSpokesStore, ["spokes", "templates", "spokesLoaded"]),
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    async goToCreateSpoke() {
      this.loadingCreateSpokes = true;
      // use translations if a new spoke is created
      const spokeDefaults = {
        description: this.$t("spokes.defaults.description"),
        context: this.$t("spokes.defaults.context"),
        startingMessage: this.$t("spokes.defaults.first-message"),
        goal: this.$t("spokes.defaults.goal"),
        personality: this.$t("spokes.defaults.personality"),
      };
      const spokeId = await retrieveOrCreateSpoke({
        spoke: undefined,
        spokeDefaults,
      });
      this.$router.push({ name: "EditSpoke", params: { spokeId: spokeId } });
      this.loadingCreateSpokes = false;
    },
    ...mapActions(useSpokesStore, ["fetchUserSpokes"]),
  },
};
</script>

<style scoped>
.spokes-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-bottom: 20vh;
}
</style>
