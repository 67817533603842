<template>
  <v-container class="telegram-page" style="margin-bottom: 20vh">
    <v-row class="d-flex justify-space-between pa-4">
      <div class="align-start pa-2">
        <v-row align="center">
          <v-btn @click="$router.back()" icon>
            <IconComponent name="back" />
          </v-btn>
          <v-card-title class="text-primary font-weight-bold text-h4 text-left">
            Shopify
          </v-card-title>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="elevation-12 py-8 px-4">
          <v-card-title class="pb-6 text-wrap">
            {{ $t("integrationsPage.how-to-shopify") }}
          </v-card-title>
          <iframe
            src="https://www.youtube.com/embed/PQzgs7laKB8?si=JDsLYecg-ylEGgqU"
            title="Shopify tutorial"
            frameborder="0"
            class='youtube-video'
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconComponent from "@/components/IconComponent.vue";

export default {
  name: "ShopifyPage",
  components: {
    IconComponent,
  },
};
</script>

<style>

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 720px;
}</style>
