<template>
  <v-dialog :value="value" @input="$emit('update:modelValue', $event)" max-width="800px">
    <template #activator="{ on, attrs }">
      <slot v-bind="attrs" v-on="on"></slot>
    </template>

    <v-card>
      <v-card-title class="headline text-primary">{{ formTitle }}</v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="form.name" label="Output Name" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field v-model="form.description" label="Output Description" required></v-text-field>
            </v-col>
          </v-row>

          <!-- Custom Fields -->
          <div class="subtitle-1 text-primary">Custom Fields</div>
          <v-divider class="mb-4"></v-divider>
          <v-row class="mb-4">
            <v-col cols="12" class="text-right">
              <v-btn color="primary" @click="addField">
                <svg-icon type="mdi" color="primary" :path="createIcon"></svg-icon></v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(field, index) in form.fields" :key="'custom-' + index" class="mb-4">
            <v-col cols="12" sm="4">
              <v-text-field v-model="field.name" label="Field Name" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="field.type"
                :items="['string', 'integer', 'number', 'boolean']"
                label="Field Type"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" sm="3">
              <v-textarea v-model="field.description" label="Field Description" required></v-textarea>
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn color="primary" @click="deleteField(index)">
                <svg-icon type="mdi" color="primary" :path="deleteIcon"></svg-icon>
              </v-btn>
            </v-col>
          </v-row>

          

          <!-- Mandatory Fields -->
          <div class="subtitle-1 text-primary">Mandatory Fields</div>
          <v-divider class="mb-4"></v-divider>
          <v-row v-for="(field, index) in mandatoryFields" :key="'mandatory-' + index">
            <v-col cols="12" sm="4">
              <v-card-text>{{ field.label }}</v-card-text>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text>{{ field.type }}</v-card-text>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text>{{ field.description }}</v-card-text>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="$emit('close')">Cancel</v-btn>
        <v-btn color="secondary" text @click="submitForm">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiDeleteOutline, mdiPlusBoxOutline } from '@mdi/js';
export default {
  name: "OutputForm",
  components: {
      SvgIcon,
    },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      createIcon: mdiPlusBoxOutline,
      deleteIcon: mdiDeleteOutline,
      form: {
        ...this.model,
        title: this.model.title || "",
        grade: this.model.grade || "",
        fields: this.model.fields || [],
      },
    };
  },
  computed: {
  formTitle() {
    return this.form.id ? "Edit Output" : "Create Output";
  },
  mandatoryFields() {
    return [
      { key: "conversationId", label: "Conversation Id", type: "string", description: "Identifier of the conversation" },
      { key: "createdAt", label: "Created At", type: "string", description: "Time the conversation was created at" },
      { key: "finishedAt", label: "Finished At", type: "string", description: "Time the conversation finished at" },
      { key: "messageCount", label: "Message Count", type: "integer", description: "Number of messages in the conversation (2 minimum)" },
      { key: "title", label: "Title", type: "string", description: "Title summing up the conversation" },
      { key: "grade", label: "Grade", type: "number", description: "Score on assistant's performance in complying with its goal, from 1 to 10", inputType: "number", min: 1, max: 10 },
    ];
  },
},
  watch: {
    value(newVal) {
      if (newVal) {
        this.form = { ...this.model, title: this.model.title || "", grade: this.model.grade || "", fields: this.model.fields || [] };
      }
    },
    model(newModel) {
        this.form = { ...newModel, title: newModel.title || "", grade: newModel.grade || "", fields: newModel.fields || [] };
    }
  },
  methods: {
    addField() {
        this.form.fields.push({
            name: "",
            type: "",
            description: "",
        });
    },
    submitForm() {
      console.log(this.form);
      this.$emit("submit", this.form);
    },
    deleteField(index) {
        this.form.fields.splice(index, 1);
    },
  },
};
</script>

