import { auth } from "@/firebase";
import { sendAction } from "@/services/actions";

export async function addSpokeToTelegram({ spokeId, botToken }) {
  const userId = auth.currentUser.uid;
  const botId = Math.random().toString(36).substring(2, 15);
  /*
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();
    console.log("userData: ", userData)
    */
  const res = await sendAction({
    actionId: "install-telegram-bot",
    args: {
      author: userId,
      spokeId: spokeId,
      botId: botId,
      botToken: botToken,
    },
    user: userId,
  });
  console.log("saved spoke to Telegram: ", res);
  return res.payload;
}

export async function addSpokeToSlackChannel({ spokeId, workspace, channel }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "set-slack-channel",
    args: {
      author: userId,
      spokeId: spokeId,
      workspace: workspace,
      channel: channel
    },
    user: userId,
  });
  console.log("saved spoke to Slack Channel: ", res);
  return res.payload;
}

export async function setDefaultSpokeForWorkspace({ spokeId, workspace }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "set-slack-default-spoke",
    args: {
      author: userId,
      spokeId: spokeId,
      workspace: workspace,
    },
    user: userId,
  });
  console.log("set default Spoke for Workspace: ", res);
  return res.payload;
}


export async function deleteTelegramBot({ botId }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "delete-telegram-bot",
    args: {
      author: userId,
      botId: botId,
    },
    user: userId,
  });
  console.log("deleted bot from Telegram: ", res);
  return res.payload;
}

export async function deleteSlackWorkspace({ workspace }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "delete-slack-workspace",
    args: {
      author: userId,
      workspace: workspace,
    },
    user: userId,
  });
  console.log("deleted bot from slack worskpace: ", res);
  return res.payload;
}

export async function deleteSlackChannel({ workspace, channel }) {
  const userId = auth.currentUser.uid;
  const res = await sendAction({
    actionId: "delete-slack-channel",
    args: {
      author: userId,
      workspace: workspace,
      channel: channel
    },
    user: userId,
  });
  console.log("deleted bot from slack worskpace: ", res);
  return res.payload;
}

export async function listTelegramBots() {
  try {
    const userId = auth.currentUser.uid;
    const res = await sendAction({
      actionId: "list-telegram-bots",
      args: { author: userId },
      user: userId,
    });
    return res.payload.bots;
  } catch (error) {
    console.error("Error listing telegram bots:", error);
    return [];
  }
}

export async function listSlackWorkspaces() {
  try {
    const userId = auth.currentUser.uid;
    const res = await sendAction({
      actionId: "list-slack-workspaces",
      args: { author: userId },
      user: userId,
    });
    return res.payload.workspaces;
  } catch (error) {
    console.error("Error listing workspaces:", error);
    return [];
  }
}
