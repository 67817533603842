export function getPlan(planId) {
  switch (planId) {
    case "prod_Oatl5F9DqhEhcU":
      return "Gratuito";
    case "prod_OatyORy6JMjGET":
      return "Básico";
    case "prod_Oau6lAgzyT67ge":
      return "Avanzado";
    case "prod_Oi5MbunfxjsztR":
      return "Free";
    case "prod_Oi5Ksy8TgWMO02":
      return "Basic";
    case "prod_Oi5Il4O4pxKRCN":
      return "Advanced";
    default:
      return "Default";
  }
}
