<template>
  <div>
    <div v-if="loading" class="loading-screen">
      Loading...
    </div>
    <div v-else>
      <v-container class="conversation-editor">
        <v-row>
          <v-col cols="12">
            <v-card class="elevation-12 pa-4 mb-2">
              <v-row>
                <v-col cols="12">
                  <v-card-title>Create Conversation</v-card-title>
                </v-col>
                <v-col cols="12">
                  <v-form ref="form" v-model="valid" @submit.prevent="createConversation">
                    <v-select
                      :items="spokesList"
                      v-model="selectedSpoke"
                      label="Spoke Configuration"
                      required
                      item-title="data.name"
                      item-value="data"
                    ></v-select>
                    <v-text-field
                      label="Conversation ID"
                      v-model="conversationId"
                      required
                    ></v-text-field>
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="!valid"
                    >Create</v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { fetchSpokesFromFirebase } from "@/services/spoke"
import { useUserStore } from "@/stores/user";
import { createConversation } from '@/services/conversation';

export default {
  name: 'CreateConversationPage',
  data() {
    return {
      loading: true,
      valid: true,
      conversationId: '',
      selectedSpoke: '',
      spokesList: [],
    };
  },
  async created() {
    try {
      this.spokesList = await fetchSpokesFromFirebase();
      this.loading = false;
      console.log('Loaded spokes: ', this.spokesList)
    } catch (error) {
      console.error('Error loading Spokes:', error);
    }
  },
  computed: {
    ...mapState(useUserStore, ["userData", "userDataLoaded"]),
  },
  methods: {
    async createConversation() {
      console.log('createConversation', this.selectedSpoke, this.conversationId)
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        const owner = this.userData.userId
        console.log(this.selectedSpoke)
        const spokeId = this.selectedSpoke.spokeId
        const spokeUser = this.selectedSpoke.owner
        const brain = this.selectedSpoke.brain
        await createConversation({ author: owner, spokeUser, brain, spokeId, 
          forUser: owner, conversationId: this.conversationId, owner, 
          channel: "FRONTEND.USERCOMMANDS", type: "userCreated" })
        this.$router.push('/home');
      } catch (error) {
        console.error('Error creating conversation:', error);
      }
    },
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
}
.conversation-editor {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  height: 100vh;
}
</style>
