<template>
  <v-container class="integrations-page" style="margin-bottom: 20vh">
    <v-row>
      <v-col>
        <v-card-title class="text-primary font-weight-bold text-h4 mb-6">{{
          $t("integrations")
        }}</v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        md="3"
        v-for="integration in integrations"
        :key="integration.name"
      >
        <v-card
          @click="!integration.disabled && this.$router.push(integration.link)"
          :class="{
            disabled: integration.disabled,
            hoverable: !integration.disabled,
          }"
          class="elevation-12 pa-4 pa-md-8 pt-0 pt-md-2"
        >
          <v-tooltip
            v-if="integration.disabled"
            activator="parent"
            location="bottom"
            ><strong>{{
              $t("integrationsPage.coming-soon")
            }}</strong></v-tooltip
          >
          <v-tooltip v-else activator="parent" location="bottom"
            ><strong>{{ $t(integration.description) }}</strong></v-tooltip
          >
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-title
                style="white-space: normal"
                class="text-body-2 text-md-h6"
                ><strong>{{ integration.name }}</strong></v-card-title
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-img contain class="pt-0 pb-2 pt-md-2 pb-md-0" height="90" :src="integration.icon" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SlackLogo from "@/assets/logos/Slack_logo.png";
import TelegramLogo from "@/assets/logos/Telegram_logo.png";
import ShopifyLogo from "@/assets/logos/Shopify_logo.png";
import WhatsappLogo from "@/assets/logos/Whatsapp_logo.png";

export default {
  name: "IntegrationsPage",
  data() {
    return {
      integrations: [
        {
          name: "Slack",
          description: "integrationsPage.slack-description",
          link: "/integrations/slack",
          icon: SlackLogo,
          disabled: false,
        },
        {
          name: "Telegram",
          description: "integrationsPage.telegram-description",
          link: "/integrations/telegram",
          icon: TelegramLogo,
          disabled: false,
        },
        {
          name: "Shopify",
          description: "integrationsPage.shopify-description",
          link: "/integrations/shopify",
          icon: ShopifyLogo,
          disabled: false,
        },
        {
          name: "Whatsapp",
          description: "",
          link: "",
          icon: WhatsappLogo,
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.integrations-page {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  align-items: left;
  justify-content: left;
}
.disabled {
  opacity: 50%;
  cursor: initial;
}
</style>
